import React, { useState, useEffect, useMemo } from "react"
import { FlexWrapper } from "./flex-box.style"
import useContainerWidth from "./container-width"
import FlexItem from "./flex-item"
export { FlexItem }

const MIN_PADDING = 20

export const FlexBoxContext = React.createContext()

export const FlexBox = ({ children, itemWidth = 250, itemHeight = 250, maxPerRow = 4, flexHook }) => {
  const [containerRef, containerWidth] = useContainerWidth()
  const itemsPerRow = useItemsPerRow(containerWidth, itemWidth, maxPerRow)
  const height = useContainerMinHeight(children.length, itemsPerRow, itemWidth, itemHeight, containerWidth)
  const ctxValue = useMemo(
    () => ({ containerWidth, itemsPerRow, totalItems: children.length, itemWidth, itemHeight, flexHook }),
    [containerWidth, itemsPerRow, children.length, itemWidth, itemHeight, flexHook]
  )
  return (
    <FlexWrapper ref={containerRef} style={{ height }}>
      <FlexBoxContext.Provider value={ctxValue}>
        {children}
      </FlexBoxContext.Provider>
    </FlexWrapper>
  )
}

function useContainerMinHeight(totalItems, itemsPerRow, itemWidth, itemHeight, containerWidth) {
  const [height, setHeight] = useState("100vh")
  useEffect(() => {
    const rows = Math.ceil(totalItems / itemsPerRow)
    const defaultRowWidth = itemsPerRow * itemWidth
    const v_padding = (containerWidth - defaultRowWidth) / (itemsPerRow + 3)
    const newMinHeight = rows * (itemHeight + v_padding) + 2 * v_padding + 100

    // TODO padding top ...
    setHeight(`${newMinHeight}px`)
  }, [totalItems, itemsPerRow, itemWidth, itemHeight, containerWidth])
  return height
}

function useItemsPerRow(containerWidth, itemWidth, maxPerRow) {
  const [itemsPerRow, setItemsPerRow] = useState(1)
  useEffect(() => {
    const newItemsPerRow = Math.floor(
      containerWidth / (itemWidth + MIN_PADDING)
    )
    setItemsPerRow(Math.min(Math.max(newItemsPerRow, 1), maxPerRow))
  }, [containerWidth, itemWidth])
  return itemsPerRow
}
