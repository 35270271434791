import React, { useContext } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRedo } from "@fortawesome/free-solid-svg-icons"
import { LangContext } from "gatsby-source-dek-wp"
import { breakpointTablet, breakpointMobile } from "../app/globals.module.scss"

export const SlideWrapper = styled.div`
  display: block;
  position: relative;
  width: 320px;
  height: 316px;
  box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.7); /* 0.5 */
  .popup & {
    box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.25); /* 0.5 */
  }
  transform-style: preserve-3d;
  overflow: hidden;
  line-height: 1em !important;

  &.plastic {
    height: 298px;
    border-radius: 20px;
  }
  &.paper1 {
    height: 316px;
    border-radius: 3px;
  }
  &.paper2 {
    height: 318px;
    border-radius: 3px;
  }
`

export const SlideImgWrapper = styled.div`
  z-index: 1;
  width: 72%;
  height: 52%;
  position: absolute;
  left: 14%;
  top: 24%;
  .plastic & {
    height: 48%;
    top: 26%;
  }
`

/** PermanentMarker */
export const SlideTitle = styled.div`
  backface-visibility: hidden;
  font-family: "CustomTypewriter";
  z-index: 3;
  position: absolute;
  top: 0;
  height: 27%;
  left: 30px;
  width: calc(100% - 60px);
  /* left: 52px;*/
  display: flex;
  align-items: center;
  justify-content: center; /*  flex-start */
  a,
  a:visited,
  a:hover,
  a:active {
    /* text-transform: ${(p) => (p.langId === "ru" ? "uppercase" : "none")}; */
    /* font-weight: 700; */
    text-align: center; /*left; */
    letter-spacing: 1px;
    color: inherit;
    line-height: 1.2em;
    /* color: rgb(181, 9, 9) !important; */
    text-decoration: none;
    text-transform: uppercase;
    font-size: small;
    /* color: rgb(70, 70, 70) !important; */
    border: none !important;
  }
`

export const SlideTeaser = styled.div`
  font-family: "CustomTypewriter";
  backface-visibility: hidden;
  transform: translateZ(-2px) rotateY(180deg);
  z-index: 3;
  position: absolute;
  bottom: 0;
  height: 27%;
  left: 47px;
  font-size: 0.5em;
  text-transform: uppercase;
  width: calc(100% - 94px);
  color: rgb(70, 70, 70);
  /* letter-spacing: 0.1px;*/
  display: flex;
  align-items: center;
  /* word-break: break-all;*/
  .slide-wrapper:not(.flipped):hover & {
    transform: translateZ(2px);
  }
`

const FlipButtonWrapper = styled.button`
  position: absolute;
  z-index: 3;
  backface-visibility: hidden;
  color: rgb(40, 40, 40);
  bottom: 10px;
  left: 10px;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
  font-size: 0.6em;
  @media screen and (min-width: ${breakpointTablet}) {
    display: none;
  }
  ${(p) =>
    p.back &&
    `
    right: auto;
    left: 10px;
    transform: translateZ(-2px) rotateY(180deg);
  `}
`

export const SlideGripWrapper = styled.button`
  cursor: grab; /* move */
  backface-visibility: hidden;
  position: absolute;
  z-index: 4;
  color: rgb(40, 40, 40);
  top: 10px;
  left: 10px;
  opacity: 0.4;
  font-size: 0.6em;
  &:hover {
    opacity: 1;
  }
  @media screen and (max-width: ${breakpointMobile}) {
    pointer-events: auto;
    touch-action: none;
    cursor: grab;
    font-size: 0.8em;
    opacity: 0.3;
    padding-right: 0.5em;
    &:active {
      cursor: grabbing;
    }
  }
`

const ViewButtonWrapper = styled.a`
  position: absolute;
  z-index: 3;
  backface-visibility: hidden;
  color: rgb(40, 40, 40) !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* font-family: "CustomTypewriter"; */
  bottom: 10px;
  right: 10px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  font-size: 0.6em;
  text-decoration: none !important;
  &:hover,
  &:visited,
  &:active {
    text-decoration: none !important;
    color: rgb(40, 40, 40) !important;
    border: none !important;
  }
  ${(p) =>
    p.back &&
    `
    right: auto;
    left: 10px;
    transform: translateZ(-2px) rotateY(180deg);
  `}
`

const viewButtonText = {
  de: "Ansehen",
  ru: "Посмотреть",
}

export const ViewButton = ({ href }) => {
  const lang = useContext(LangContext)
  const text = viewButtonText[lang.id]
  return <ViewButtonWrapper href={href}>→ {text}</ViewButtonWrapper>
}

export const FlipButton = ({ onClick }) => (
  <>
    <FlipButtonWrapper {...{ onClick }}>
      <FontAwesomeIcon icon={faRedo} />
    </FlipButtonWrapper>
    <FlipButtonWrapper {...{ onClick }} back>
      <FontAwesomeIcon icon={faRedo} />
    </FlipButtonWrapper>
  </>
)

const SlideFrameImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  user-select: none;
  pointer-events: none;
  backface-visibility: hidden;
  .paper1 &,
  .paper2 & {
    filter: saturate(${(p) => p.saturate || 1});
    transform: ${(p) => (p.mirror ? "scaleX(-1)" : "none")};
  }
  ${(p) =>
    p.back &&
    `
      transform: translateZ(-1px) rotateY(180deg) !important;
      filter: brightness(0.8);
  `}
`

export const SlideFrame = (props) => {
  const backProps = { ...props, src: props.srcBack || props.src, back: true }
  return (
    <>
      <SlideFrameImg alt="slide-frame" {...props} />
      <SlideFrameImg alt="slide-frame back" {...backProps} />
    </>
  )
}
