import { animated } from "@react-spring/web"
import styled from "styled-components"
import { blockStyles } from "../gallery/gallery"
import * as defaultTheme from "../theme.default"

export const CarouselOuter = styled.div`
  max-width: none !important;
  overflow: hidden;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
`

export const CarouselWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:not(.size-full) {
    .carousel-item-wrapper {
      cursor: grab;
    }
    .carousel-item-wrapper:active {
      cursor: grabbing;
    }
  }

  &.show-overflow {
    overflow: visible;
  }

  &.size-normal {
    ${blockStyles}
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0;
    }
  }
  &.size-normal,
  &.size-big {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  &.size-big {
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      max-width: calc(
        100% - 2 * ${(p) => p.theme.blockMarginLeftRightTablet}
      ) !important;
      margin-left: ${(p) => p.theme.blockMarginLeftRightTablet};
      margin-right: ${(p) => p.theme.blockMarginLeftRightTablet};
    }
    @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
      max-width: calc(
        100% - 2 * ${(p) => p.theme.blockMarginLeftRight}
      ) !important;
      margin-left: ${(p) => p.theme.blockMarginLeftRight};
      margin-right: ${(p) => p.theme.blockMarginLeftRight};
    }
  }
  &.size-full {
    margin: 0 !important;
    max-width: none !important;
    padding: 2rem 0 !important;
  }
  &.bg-dark {
    background-color: ${(p) => p.theme.bgDark};
    margin-top: 2em !important;
    margin-bottom: 2em !important;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 1),
      5px 5px 30px 0px rgba(0, 0, 0, 0.6); // 20px
    .dot-btn {
      opacity: 1;
      // margin: 0 0.5rem;
    }
    .dot-btn:not(.is-active) {
      background-color: rgb(140, 140, 140);
    }
    .dot-btn.is-active {
      transform: scale(1.2);
    }
  }
`

export const HeightWrapper = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100%;

  > div {
    // x container
    position: absolute;
    width: 100%;
    height: 100%;
    touch-action: pan-y;

    > div {
      // scale container
      touch-action: pan-y;
      width: 100%;
      height: 100%;
      .show-overflow & {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

export const CarouselItem = styled.div`
  > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  user-select: none;
  img {
    user-drag: none;
  }

  .wp-block-image {
    margin: 0;
    // margin-bottom: 1em !important;
    figcaption {
      margin-top: 0.5em;
      transition: opacity 0.7s ease-in-out;
      opacity: ${(p) => (p.isCurrent ? 1 : 0)};
    }
  }
`

CarouselWrapper.defaultProps = {
  theme: {
    ...defaultTheme,
  },
}
