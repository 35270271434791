import React from "react"
import { scrollTo } from "./footnote"

interface Props {
  children: React.ReactNode
  footnoteId: number | string
}

const FootnoteLink = ({ children, footnoteId }: Props) => {
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    scrollTo(`#footnote-${footnoteId}`)
  }
  return (
    <a
      className="footnote-link"
      id={`footnote-link-${footnoteId}`}
      href={`#footnote-${footnoteId}`}
      onClick={onClick}
    >
      <sup>{children}</sup>
    </a>
  )
}

export default FootnoteLink
