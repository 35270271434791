import React, { useContext, useCallback } from "react"
import AsyncSelect from "react-select/async"
import chroma from "chroma-js"
import { LangContext } from "gatsby-source-dek-wp"
import { addTerm, removeTerm, removeAllTerms } from "../term-reducer"
import { getSuggestions } from "../elastic-search"
import { DndProvider } from "react-dnd"
import { TouchBackend } from "react-dnd-touch-backend"
import { HTML5Backend } from "react-dnd-html5-backend"
import DndLabel from "../dnd-label"
import CustomOption from "./custom-option"
import CustomValueContainer from "./custom-value-container"
import CustomInput from "./custom-input"
import { useHints } from "./hints"

import { TermDispatchContext } from "../contexts/terms"
import { TranslateContext } from "../contexts/translate"
import { useTranscribe } from "../transcribe"
import { TermSearchWrapper } from "./term-search.style"
import { ThemeContext } from "styled-components"

const LOADING_TEXT = {
  de: "Warte kurz ...",
  en: "Loading ...",
  ru: "Секундочку ...",
}

const NO_OPTIONS_TEXT = {
  de: "Keine Ergebnisse",
  en: "No results",
  ru: "Нет результатов",
}

const TermSearch = ({ data, dispatch, suggestionApi }) => {
  const lang = useContext(LangContext)
  const transcribeEnabled = useContext(TranslateContext)
  const termDispatchFromContext = useContext(TermDispatchContext)
  const termDispatch = dispatch || termDispatchFromContext
  const transcribe = useTranscribe()
  const PatchedDndLabel = useCallback(
    (props) => <DndLabel {...props} dispatch={termDispatch} />,
    [termDispatch]
  )
  const onChange = (opts, obj) => onTermSearchChange(opts, obj, termDispatch)
  const onType = (rawInput, cb) => {
    const searchLangId = lang.id === "de" && !transcribeEnabled ? "ru" : lang.id
    getSuggestions(transcribe(rawInput), cb, searchLangId, suggestionApi)
  }

  const hintComp = useHints()

  const theme = useContext(ThemeContext)

  return (
    <TermSearchWrapper className="term-search-wrapper">
      <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
        <AsyncSelect
          // autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
          className="term-search"
          classNamePrefix="rs-term-search"
          value={data}
          isMulti
          onChange={onChange}
          // cacheOptions
          loadOptions={onType}
          openMenuOnClick={false}
          placeholder="" // {PLACEHOLDER_TEXT[lang.id]}
          loadingMessage={() => LOADING_TEXT[lang.id]}
          noOptionsMessage={() => NO_OPTIONS_TEXT[lang.id]}
          styles={termSearchStyles(theme)}
          theme={(t) => ({
            ...t,
            colors: {
              ...t.colors,
              primary: theme.themeColor || theme.titleColor,
              primary25: chroma(theme.themeColor || theme.titleColor)
                .alpha(0.25)
                .css(),
            },
            borderRadius: 0,
          })}
          components={{
            MultiValue: PatchedDndLabel,
            Option: CustomOption,
            ValueContainer: CustomValueContainer,
            Input: CustomInput,
          }}
        />
      </DndProvider>
      {hintComp}
    </TermSearchWrapper>
  )
}

export default TermSearch

function onTermSearchChange(allOptions, obj, termDispatch) {
  const { action, option, removedValue } = obj
  if (!allOptions || !allOptions.length) termDispatch(removeAllTerms())
  else {
    if (action === "select-option") {
      termDispatch(addTerm(option.value))
    } else if (action === "remove-value") {
      termDispatch(removeTerm(removedValue.value))
    }
  }
}

function termSearchStyles(theme) {
  return {
    control: (styles, state) => ({
      ...styles,
      borderWidth: "2px",
      backgroundColor: "transparent",
      borderColor: theme.darkMode ? "#333" : "hsl(0,0%,40%)",
      color: state.isFocused ? "hsl(0,0%,40%)" : "hsl(0,0%,80%)",
    }),
    input: (styles) => ({
      ...styles,
      color: theme.darkMode
        ? theme.textColorLight || "rgb(235,235,235)"
        : "hsl(0,0%,20%)",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.darkMode ? theme.bgDark : "white",
    }),
    clearIndicator: (styles) => ({ ...styles, cursor: "pointer" }),
    indicatorSeparator: () => ({ display: "none " }),
    dropdownIndicator: () => ({ display: "none" }),
    multiValue: (styles, { data }) => ({
      ...styles,
      margin: 0,
      color: "white",
      backgroundColor: chroma(data.color)
        .alpha(1) // 0.3
        .css(),
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "inherit",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: /* data.color, */ chroma(data.color)
        .brighten(3) // 0.3
        .css(),
      cursor: "pointer",
      ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        color: "white",
      },
    }),
  }
}

function isTouchDevice() {
  if (typeof window === "undefined") return
  return "ontouchstart" in document.documentElement
}
