import { useEffect } from "react"
import { navigate } from "gatsby"
import { useDrag } from "@use-gesture/react"
import debounce from "debounce"

const debouncedNavigate = debounce(navigate, 1000, true)

export function useKeyNav(prev, next) {
  useEffect(() => {
    function checkKeys(e) {
      if (e.key === "ArrowLeft") navigate(`/${prev?.slug}`)
      if (e.key === "ArrowRight") navigate(`/${next?.slug}`)
    }
    window.addEventListener("keydown", checkKeys)
    return () => window.removeEventListener("keydown", checkKeys)
  }, [prev, next])
}

export function useDragNav(prev, next) {
  return useDrag(
    ({ velocity, down, direction: [xDir], movement: [mx] }) => {
      const trigger = velocity > 0.2
      if (!down || !trigger) return
      const destination = xDir < 0 ? next?.slug : prev?.slug
      if (Math.abs(mx) > 20) debouncedNavigate(`/${destination}`)
    },
    { threshold: 50 }
  )
}
