import React, { useMemo, useEffect, createContext } from "react"
import Slide from "../slide/slide"

export const SlidesContext = createContext()

export default function useSlides(contents, selected, CustomTile) {
  const slides = useMemo(() => {
    const Comp = CustomTile || Slide
    return contents
      .filter((c) => c.pageType !== "about")
      .map((c, i) => {
        const isExternal = !!c.slug.match(/\./)
        const href = isExternal
          ? `https://${c.slug}`
          : `/${c.slug}`
        return (
          <Comp
            key={c.slug}
            id={c.slug}
            imageSrc={c.tileImage || c.titleVideoPosterPreview}
            imageSrcFull={c.titleVideoPoster}
            title={c.title}
            teaser={c.teaserStartPage || ""}
            hidden={c.slug === (selected || {}).slug}
            href={href}
            category={c.category}
            unpublished={!c.published}
            i={i}
          />
        )
      })
  }, [contents, selected, CustomTile])
  useImagePreloader(contents)
  return slides
}

function useImagePreloader(contents) {
  useEffect(() => {
    function loadSlideImages() {
      // console.log("page is fully loaded")
      contents.forEach((c) => preloadImage(c.titleVideoPoster))
    }
    if (document.readyState === "complete") loadSlideImages()
    else window.addEventListener("load", loadSlideImages)
    return () => window.removeEventListener("load", loadSlideImages)
  }, [contents])
}

function preloadImage(url) {
  const img = new Image()
  img.src = url
  // console.log("PRELOADING", url)
}
