const { useEffect } = require("react")
const { debounce } = require("throttle-debounce")

/* const NodeFormData = require("form-data")
const FormData = typeof window !== "undefined" ? window.FormData : NodeFormData */

const ALIAS_API_GET = "https://www.dekoder.org/nid-by-alias"
// const ALIAS_API_POST = "https://www.dekoder.org/nid-by-alias-post"
const POPUP_API = "https://www.dekoder.org/de/popup-text-as-json"
const DELAY_BETWEEN_CHECKS = 300 // ms

class PopupAggregator {
  constructor(preloadedPopups = []) {
    this.popups = []
    this.debouncedBulkCheck = debounce(1000, this.bulkCheck.bind(this))
    this.addPreloaded(preloadedPopups)
  }

  get uncheckedPopups() {
    return this.popups.filter((p) => !p.loaded)
  }

  get checkedPopups() {
    return this.popups.filter((p) => p.loaded)
  }

  getPopupByUrl(url) {
    return this.getPopupByAlias(aliasFromUrl(url))
  }

  getPopupByAlias(alias) {
    return this.popups.find((p) => p.alias === alias)
  }

  addByUrl(url) {
    return this.addByAlias(aliasFromUrl(url))
  }

  addByAlias(alias) {
    return this.getPopupByAlias(alias) || new Popup({ alias }, this).promise
  }

  addPreloaded(preloadedPopups = []) {
    preloadedPopups.forEach((p) => {
      if (!this.getPopupByAlias(p.alias)) new Popup(p, this)
    })
  }

  async bulkCheck(debug = false) {

    async function nidsFromAliases(aliases) {
      let nids = []
      for (let i = 0; i < aliases.length; i++) {
        const alias = aliases[i]
        if (debug) console.log(">>> ALIAS CHECK:", alias)
        const newNids = await fetch(`${ALIAS_API_GET}/?alias=${alias}`)
          .then((r) => r.json())
          .then((r) => r.map((e) => ({ alias, nid: e.source.replace("node/", "") * 1 }) ))
          .catch(console.error)
        if (newNids) {
          nids = [...nids, ...newNids]
        }
        await new Promise(res => setTimeout(res, DELAY_BETWEEN_CHECKS))
      }
      return nids
    }

    if (!this.uncheckedPopups.length) return this.checkedPopups
    const aliases = this.uncheckedPopups.map((p) => p.alias)

    // GET: can fail due to URL length restrictions
    // fetch(`${ALIAS_API}/?alias=${aliases.join("|")}`)
    
    /* const form = new FormData()
    const formAliases = aliases.join("|").replace(/\//g, "\/")
    if (debug) console.log(">>> FORM ALIASES", formAliases)
    form.append("alias", formAliases)

    const nids = await fetch(`${ALIAS_API_POST}`, {
      method: "POST",
      body: form,
    })
      .then((r) => r.json())
      .then((r) => r.map((e) => e.source.replace("node/", "") * 1))
      .catch(console.error) */

    const nidObjs = await nidsFromAliases(aliases) || []
    const nids = nidObjs
      .filter(o => !isNaN(o.nid))
      .map(o => o.nid)

    const nidQuery = `${POPUP_API}/${nids.join("+")}`
    if (debug) console.log(">>> ALIAS QUERY:", nidObjs, nidQuery)
    const popups = await fetch(nidQuery)
      .then((r) => r.json())
      .catch(console.error)
    if (debug) console.log(">>> NID QUERY:", popups)

    if (debug) {
      console.log(">>> POPUP AGGREGATOR BULK CHECK DEBUG:")
      console.log(`Items: ${this.uncheckedPopups.length}`)
      console.log(`Aliases: ${aliases.join("|")}`)
      console.log(`Popups:`, popups)
      console.log(`PopupAggregator:`, this)
      console.log(`>>> DEBUG END`)
    }

    popups.forEach((p) => {
      // const alias = p.url.replace(/^(\/de\/|\/ru\/)/, "")
      const obj = nidObjs.find(o => o.nid === p.nid * 1)
      const alias = obj ? obj.alias : ""
      const popup = this.getPopupByAlias(alias)
      if (!popup) return
      console.log("-- saving fetched popup:", p.title)
      popup.setPopup(p)
    })
    return this.checkedPopups
  }
}

class Popup {
  constructor(props, aggregator) {
    this.alias = props.alias || aliasFromUrl(props.url)
    this.nid = props.nid || null
    this.type = props.type || ""
    this.title = props.title || ""
    this.text = props.text || ""
    this.loaded = props.loaded || false
    aggregator.popups.push(this)
    if (typeof window !== "undefined") {
      this.aggregator = aggregator
      if (!this.loaded) this.aggregator.debouncedBulkCheck()
    }
  }

  get promise() {
    if (typeof window === "undefined") return null
    const self = this
    return new Promise((resolve) => (self.promiseResolver = resolve))
  }

  setPopup({ type, format, title, popup, nid }) {
    this.type = getType(type, format)
    this.title = title
    this.text = popup
    this.nid = nid * 1
    this.loaded = true
    if (this.promiseResolver) this.promiseResolver(this)
  }
}

function getType(drupalType, drupalFormat) {
  return drupalType === "Artikel"
    ? "article"
    : drupalType === "Dossier"
    ? "dossier"
    : drupalType === "Gnose"
    ? drupalFormat === "Gnose"
      ? "gnose"
      : "blurb"
    : "undefined"
}

function aliasFromUrl(url = "") {
  const rx = /https:\/\/www.dekoder.org\/(de|ru)\/(.*)/
  const match = url.match(rx)
  return match ? match[2] : ""
}

const popupAggregator = new PopupAggregator()

function usePreloadedPopups(popups = "[]") {
  useEffect(() => {
    const parsedPopups = JSON.parse(popups).filter((p) => typeof p === "object")
    popupAggregator.addPreloaded(parsedPopups)
  }, [popups])
}

exports.PopupAggregator = PopupAggregator
exports.popupAggregator = popupAggregator
exports.usePreloadedPopups = usePreloadedPopups
