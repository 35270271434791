import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useMemo, useState } from "react"
import { TocWrapper, TocHeader, TocList, TocListItem } from "./toc.style"
import { TocContext, TocItem } from "./toc-reducer"
import scrollIntoView from "scroll-into-view"

interface Props {
  marked?: any
  listOnly?: boolean
  onClickHook?: () => void
  classList?: string[]
}

export const Toc = ({ marked, listOnly, onClickHook, classList = [] }: Props) => {
  const [expanded, setExpanded] = useState(
    !!listOnly || classList.includes("toc-fixed-left")
  )
  const [items] = useContext(TocContext)
  function scrollTo(item: TocItem) {
    if (!item.refEl.current) return
    scrollIntoView(
      item.refEl.current,
      { align: { top: 0, topOffset: 60 } }
      // dispatch(setCurrChapter(item.uid))
    )
    if (typeof onClickHook === "function") onClickHook()
  }
  const isNumbered = useMemo(() => {
    return items
      .filter((it) => !it.isTitle)
      .every((it) => it.text.match(/^[0-9]+\. /))
  }, [items])
  return (
    <TocWrapper
      listOnly={listOnly}
      className={`toc-wrapper ${classList.join(" ")}`}
    >
      <div>
        {!listOnly && (
          <TocHeader onClick={() => setExpanded((e) => !e)}>
            <div>Inhalt</div>
            <FontAwesomeIcon icon={expanded ? faArrowUp : faArrowDown} />
          </TocHeader>
        )}
        <TocList
          expanded={expanded}
          isNumbered={isNumbered}
          onClick={
            expanded
              ? undefined
              : (e) => {
                  setExpanded(true)
                }
          }
        >
          {items.map((it, i) => (
            <TocListItem
              className={`toc-list-item ${it.current ? "active" : ""} ${
                it.isTitle ? "is-title" : ""
              }`}
              key={i}
              isMarked={it.uid === marked || it.current}
              isNumbered={isNumbered}
            >
              <button onClick={() => scrollTo(it)}>
                {isNumbered ? stripNumber(it.text) : it.text}
              </button>
            </TocListItem>
          ))}
        </TocList>
      </div>
    </TocWrapper>
  )
}

function stripNumber(string) {
  return string.replace(/^[0-9]+\. /, "")
}
