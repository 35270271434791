import React, {
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react"
import { useUid } from "../../modules/hooks"
import { MapPointContext, getPreviousY, setPreviousY } from "./map-points"
import { add, remove, setCurrent } from "./map-points"
import { useInView } from "react-intersection-observer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { titleColorSolid } from "../app/globals.module.scss"
import scrollIntoView from "scroll-into-view"
import { BoxWrapper, MapPointWrapper, TitleWrapper } from "./map-point.style"

const SCROLL_OFFSET_TOP = 50 // 15 // in percent

const MapPointH4 = ({
  isActive,
  categoryColor,
  onClick,
  children,
  id,
  lat,
  lon,
  category,
  zoom,
}) => {
  const [, dispatch] = useContext(MapPointContext)
  const [inViewRef, inView] = useInView({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })

  useEffect(() => {
    if (!inView) return
    dispatch(setCurrent({ id, lat, lon, category, zoom }))
  }, [lat, lon, category, id, dispatch, inView, zoom])

  return (
    <TitleWrapper ref={inViewRef}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h4
          style={{
            paddingRight: ".5em",
            color: isActive ? categoryColor : "white",
          }}
        >
          <button onClick={onClick}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </button>
        </h4>
        <button onClick={onClick} style={{ textAlign: "left" }}>
          {children}
        </button>
      </div>
    </TitleWrapper>
  )
}

const MapPoint = ({ lat, lon, category, children, zoom }) => {
  const id = useUid()
  const scrollRef = useRef()
  const [{ current, routes }, dispatch] = useContext(MapPointContext)
  const [inViewRef, inView, entry] = useInView({
    threshold: 0,
    rootMargin: `-${SCROLL_OFFSET_TOP}% 0% -${100 - SCROLL_OFFSET_TOP}% 0%`,
  })
  const onClick = useCallback(() => {
    scrollIntoView(scrollRef.current, {
      align: { topOffset: window.innerHeight / 4 },
    })
    dispatch(setCurrent({ id, lat, lon, category }))
  }, [category, dispatch, id, lat, lon])

  useEffect(() => {
    dispatch(add(id, lat, lon, category, onClick, children))
    return () => dispatch(remove(id))
  }, [lat, lon, id, dispatch, category, onClick, children])

  useEffect(() => {
    if (!inView) return
    if ((current || {}).id === id) return
    // zoom 3 only when scrolling from top
    const currentY = entry.boundingClientRect.y
    const scrollingDown = currentY > getPreviousY()
    // console.log(`scrolling ${scrollingDown ? "down" : "up"}`)
    dispatch(
      setCurrent({ id, lat, lon, category, zoom: scrollingDown ? 3 : zoom })
    )
    setPreviousY(currentY)
  }, [id, lat, lon, category, dispatch, inView, entry, current, zoom])

  const isActive = (current || {}).id === id
  const categoryColor = useCategoryColor(category, routes)
  return (
    <div ref={scrollRef}>
      <MapPointWrapper ref={inViewRef}>
        {children.map((c, i) =>
          c.type === "h4" ? (
            <MapPointH4
              {...{
                id,
                lat,
                lon,
                category,
                zoom,
                // scrollRef,
                isActive,
                categoryColor,
                onClick,
              }}
              key={i}
            >
              {c}
            </MapPointH4>
          ) : (
            <BoxWrapper color={categoryColor} key={i}>
              {c}
            </BoxWrapper>
          )
        )}
      </MapPointWrapper>
    </div>
  )
}
export default MapPoint

function useCategoryColor(category, routes) {
  return useMemo(
    () =>
      (routes.find((r) => r.name === category) || {}).color || titleColorSolid,
    [category, routes]
  )
}
