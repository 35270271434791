import React from "react"
import { Wrapper, Title, Subtitle } from "./site-title.style"

const SiteTitle = ({ title, subtitle, titleFont, center}) => (
  <Wrapper className={center ? "center" : "" }>
    <Title font={titleFont}>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Wrapper>
)

export default SiteTitle
