import styled from "styled-components"

export const TermBookmarksWrapper = styled.div`
  display: flex;
  padding: 0 ${(p) => p.theme.toolSidePadding};
  align-items: flex-start;
  margin: 2em 0 0;
  // transition: .3s ease-in-out;

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    margin: 1em 0;
    padding: 0 ${(p) => p.theme.toolSidePaddingMobile};
    align-items: center;
  }
  .compressor & {
    align-items: center;
    margin: 0 !important;
    padding-top: 1em;
  }
`
