import React, {
  useState,
  useReducer,
  createContext,
  useRef,
  useMemo,
} from "react"
import { ExtraColumn } from "./extra-column"

// action types
const UPDATE_COLUMN_ITEM = "UPDATE_COLUMN_ITEM"
const REMOVE_COLUMN_ITEM = "REMOVE_COLUMN_ITEM"
const CLOSE_ALL_ITEMS = "CLOSE_ALL_ITEMS"

export const ExtraColumnDispatchContext = createContext()
export const ExtraColumnItemsContext = createContext()

export const ExtraColumnWrapper = ({ children }) => {
  const [extraColumnItems, dispatch] = useReducer(extraColumnReducer, [])
  const [minHeight, setMinHeight] = useState(0)
  const ref = useRef()
  const childs = useMemo(() => children, [children])
  // console.log(extraColumnItems, minHeight)
  return (
    <ExtraColumnDispatchContext.Provider value={dispatch}>
      <ExtraColumnItemsContext.Provider value={extraColumnItems}>
        <div
          style={{ position: "relative", minHeight: `${minHeight}px` }}
          ref={ref}
        >
          {childs}
          <ExtraColumn bodyRef={ref} setMinHeight={setMinHeight} />
        </div>
      </ExtraColumnItemsContext.Provider>
    </ExtraColumnDispatchContext.Provider>
  )
}

// action creators
export function updateColumnItem(uid, component) {
  return {
    type: UPDATE_COLUMN_ITEM,
    uid,
    component,
  }
}
export function removeColumnItem(uid) {
  return {
    type: REMOVE_COLUMN_ITEM,
    uid,
  }
}
export function closeAllItems() {
  return {
    type: CLOSE_ALL_ITEMS,
  }
}

const initialState = []

export function extraColumnReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_COLUMN_ITEM:
      if (!action.uid) return state
      return [
        ...state.filter((i) => i.props.uid !== action.uid),
        action.component,
      ]
    case REMOVE_COLUMN_ITEM:
      return [...state.filter((i) => i.props.uid !== action.uid)]
    case CLOSE_ALL_ITEMS:
      state.forEach((i) => {
        if (typeof i.props.collapse === "function") i.props.collapse()
      })
      return state
    default:
      return state
  }
}
