import React from "react"
import styled from "styled-components"
import { CarouselWrapper } from "./carousel.style"

const Wrapper = styled.div`
  // position: absolute;
  // bottom: 4rem;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0 0;
  ${CarouselWrapper}.fullwidth & {
    margin: 2rem 0 0;
  }
`

const DotButton = styled.button`
  border-radius: 50%;
  background-color: rgb(80, 80, 80);
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.25rem;
  transform: scale(0.75);
  transition: .3s ease-in-out;
  opacity: 0.4;
  &.is-active {
    background-color: ${p => p.theme.gnoseBlue};
    opacity: 0.8;
  }
`

interface DotProps {
  isActive: boolean
  onClick: () => void
  ["aria-label"]?: string
}

const Dot = (props: DotProps) => {
  const { isActive, onClick } = props
  return (
    <DotButton
      onClick={onClick}
      className={`dot-btn ${isActive ? "is-active" : ""}`}
      aria-label={props["aria-label"]}
    />
  )
}

interface DotsProps {
  children: React.ReactNode[]
  slideKey: number
  setSlideKey: (key: number) => void
}

export const Dots = ({ children = [], slideKey, setSlideKey }: DotsProps) => {
  return (
    <Wrapper>
      {children.map((_, i) => (
        <Dot
          aria-label={`slide ${i + 1}`}
          key={i}
          isActive={i === slideKey}
          onClick={() => setSlideKey(i)}
        />
      ))}
    </Wrapper>
  )
}
