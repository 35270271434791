import styled from "styled-components"
import * as defaultTheme from "../theme.default"

export const SiteLogoWrapper = styled.div`
  z-index: 101;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  // border: 1px dashed red;
  margin-left: 10px;
  height: 100%;

  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  &.mobile-hide {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      display: none;
    }
  }

  .start-page-logo-outer {
    padding: 0; // 0 15px;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0; //  10px;
    }
    // margin: 5px 0;
    position: relative;
    text-align: left;
    transition: 0.3s ease;
    // border: 1px dashed red;
    // border-left: 3px solid white;
    // filter: invert(1);
    &:first-child:not(:last-child) {
      &:after {
        display: inline;
        content: "|";
        font-size: 1em;
        line-height: 1;
        color: white;
        margin: 0 1em;
        align-self: center;
        // border: 1px dashed red;
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          margin: 0 0.7em;
        }
      }
    }

    height: 100%; // 30px
    display: flex;
    // &:not(:first-child) {
    // }
    a {
      display: flex;
      align-items: center;
      transition: 0.3s ease;
      .on-slide-projector & {
        opacity: 0;
        pointer-events: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .start-page-logo {
      // max-width: 100%;
      opacity: 1;
      // filter: invert(1);
      max-height: 55%;
    }

    .start-page-logo-slogan {
      @media screen {
        color: white !important;
      }
      margin-left: 1.4em;
      font-size: 0.5em;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      margin-bottom: -2em;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        letter-spacing: 0.2em;
        margin-left: 1.1em;
      }
      &.mobile-hide {
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    // top: 70px;
    width: 160px; // 160px;
  }

  @media print {
    .start-page-logo {
      filter: invert(1);
    }
    .start-page-logo-slogan {
      color: black !important;
    }
  }
`

SiteLogoWrapper.defaultProps = {
  theme: {
    ...defaultTheme,
  },
}
