import React, { useContext, useMemo } from "react"
import { FlexItemWrapper } from "./flex-box.style"
import { FlexBoxContext } from "./flex-box"

const FlexItem = ({ children, pos }) => {
  const transform = useTransform(pos)
  return <FlexItemWrapper style={{ transform }}>{children}</FlexItemWrapper>
}

export default FlexItem

function useTransform(pos) {
  const ctx = useContext(FlexBoxContext)
  const transform = useMemo(() => {
    return getTransform(pos, ctx)
  }, [pos, ctx])
  return transform
}

function getTransform(i, ctx) {
  const { itemsPerRow, totalItems, containerWidth } = ctx
  const { itemWidth, itemHeight, flexHook } = ctx
  const lastRowStartsAtI = totalItems - (totalItems % itemsPerRow)
  const isLastRow = i >= lastRowStartsAtI

  const itemsInCurrentRow = isLastRow ? totalItems % itemsPerRow : itemsPerRow

  const defaultRowWidth = itemsPerRow * itemWidth
  const rowWidth = itemsInCurrentRow * itemWidth

  const h_padding = Math.max((containerWidth - rowWidth) / (itemsInCurrentRow + 3), 0)
  const v_padding = Math.max((containerWidth - defaultRowWidth) / (itemsPerRow + 3), 0)

  const rowNo = Math.floor(i / itemsPerRow)
  const rowPos = i % itemsPerRow

  // P | P | ITEM | P | ITEM | P | ITEM | P | P 
  let x = 2 * h_padding + rowPos * (itemWidth + h_padding)
  let y = rowNo * (itemHeight + v_padding)

  if (typeof flexHook === "function") {
    [x, y] = flexHook({ ...ctx, x, y, i }) || [x, y]
  } 

  return `translateX(${x}px) translateY(${y}px)`
}
