import styled, { css } from "styled-components"
import * as defaultTheme from "../theme.default"

// TODO refactor
export const blockStyles = css`
  max-width: ${(p) => p.theme.blockMaxWidth};
  margin: 0 ${(p) => p.theme.blockMarginLeftRight}
    ${(p) => p.theme.blockMarginBottom};

  .article-wrapper.category-centered & {
    max-width: ${(p) => p.theme.blockMaxWidthCentered};
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    max-width: ${(p) => p.theme.blockMaxWidthTablet};
    margin-left: ${(p) => p.theme.blockMarginLeftRightTablet};
    margin-right: ${(p) => p.theme.blockMarginLeftRightTablet};

    .article-wrapper.category-centered & {
      max-width: ${(p) => p.theme.blockMaxWidthCenteredTablet};
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding: 0 15px;
    margin: 0;
    margin-bottom: ${(p) => p.theme.blockMarginBottom};
    max-width: none !important;
  }

  @media print {
    padding: 0 15px ${(p) => p.theme.blockMarginBottom};
    margin: 0;
    max-width: none !important;
  }
`

export const Gallery = styled.figure`
  margin: 3rem 0 !important;
  padding: 2rem 0;
  min-height: 100vh;
  display: flex;
  align-items: center;

  ul {
    display: flex;
    // justify-content: center
    margin: 0;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0;
    }
    list-style: none;

    li.blocks-gallery-item {
      display: block;
      figure {
        text-align: center;
        display: table;
        // border: 1px dashed red;
        margin: 1rem 2rem;
        img {
          display: block;
          max-width: 50vw;
          height: auto;
          max-height: 75vh;
        }
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          margin: 1rem;
          img {
            max-width: 65vw;
          }
        }
        figcaption {
          display: table-caption;
          caption-side: bottom;
          text-align: center;
          // border: 1px dashed red;

          margin-top: 1rem;
          font-size: ${(p) => p.theme.fontSizeSuperSmall};
          color: rgb(180, 180, 180);
          a,
          a:hover,
          a:visited,
          a:active {
            color: rgb(180, 180, 180) !important;
          }
          font-family: "Roboto Condensed";
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
      // max-width: 50vw;
    }
  }

  &:not(.no-scroll) {
    max-width: none !important;
    ul {
      padding: 0 5vw 0 5vw;
      overflow-x: auto;
      scroll-snap-type: x proximity;
      scroll-behavior: smooth;
      li {
        figure {
          img {
            width: 100%;
            @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
              width: auto;
            }
          }
        }
      }
    }
  }
  &.no-scroll {
    max-width: 100% !important;
    ul {
      flex-wrap: nowrap;
      padding: 0;
      li {
        width: 100%;
        figure {
          img {
            width: 100%;
          }
          // CAPTION TOP
          figcaption {
            // caption-side: top;
            // margin-bottom: .5em;
            font-size: 2rem;
            color: white;
            z-index: 10;
            font-weight: 700;
            // margin-top: -1.5em;
            letter-spacing: 0.2em;
          }
        }
      }
    }
  }

  > figcaption.blocks-gallery-caption {
    ${blockStyles}
    font-size: ${(p) => p.theme.fontSizeSuperSmall};
    padding-left: 0;
    margin-top: 0.5em;
    color: rgb(180, 180, 180);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    a {
      // color: rgb(180, 180, 180) !important;
      // border: none !important;
      &:hover {
        background: none !important;
        // border-bottom: 0.2em solid ${(p) => p.theme.articleRed} !important;
      }
    }
  }
  &.bg-dark {
    padding: 4rem 0;
    background-color: ${(p) => p.theme.bgDark}; // #202020; // #101010;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);
    figcaption {
      color: rgb(240, 240, 240);
    }
    li.blocks-gallery-item {
      img {
        box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.8);
      }
    }
  }
`

Gallery.defaultProps = {
  theme: {
    ...defaultTheme,
  },
}
