import styled from "styled-components"

export const SocialBarWrapper = styled.div`
  text-align: center;
  margin-top: 3em !important;
  margin-bottom: 3em !important;
  clear: left;

  .no-social-bar & {
    display: none;
  }

  .ph-share-bar-widget {
    padding: 10px;
    font-size: 1rem;
    &:hover {
      text-decoration: none;
      border-bottom: none !important;
    }
  }

  a {
    color: inherit !important;
    &:hover {
      color: ${p => p.theme.articleRed};
    }
  }
`