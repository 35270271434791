import { useReducer, useMemo, useEffect, useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"

const ADD_BOOKMARK = "addBookmark"
const ADD_EXAMPLE = "addExample"
const REMOVE_BOOKMARK = "removeBookmark"
const RESET_ALL = "resetAll"
const RESET_EXAMPLES = "resetExamples"

export const addBookmark = (termBundle, langId) => ({
  type: ADD_BOOKMARK,
  termBundle,
  langId,
})
export const addExample = (termBundle, langId) => ({
  type: ADD_EXAMPLE,
  termBundle,
  langId,
})
export const removeBookmark = (termBundle, langId) => ({
  type: REMOVE_BOOKMARK,
  termBundle,
  langId,
})
// export const resetAll = initialState => ({ type: RESET_BOOKMARKS, initialState })
export const resetExamples = () => ({ type: RESET_EXAMPLES })

const defaultState = {
  bookmarks: [],
  examples: [],
}

const STORAGE_KEY = "term_bookmarks"

function bookmarksReducer(state, action) {
  const { bookmarks, examples } = state
  const { termBundle, langId } = action
  switch (action.type) {
    case ADD_BOOKMARK:
      // console.log("ADD BOOKMARK", action)
      if (!termBundle || !termBundle.length) return state
      const termObj1 = { termBundle, langId }
      return {
        ...state,
        bookmarks: [
          termObj1,
          ...bookmarks.filter((t) => t.termBundle !== termBundle),
        ],
      }
    case ADD_EXAMPLE:
      // console.log("ADD EXAMPLE", action)
      const patchedTb = termBundle.map((t) => ({ ...t, nonRemovable: true }))
      const termObj2 = { termBundle: patchedTb, langId, isExample: true }
      return {
        ...state,
        examples: [
          termObj2,
          ...examples.filter((t) => t.termBundle !== termBundle),
        ],
      }
    case REMOVE_BOOKMARK:
      return {
        ...state,
        bookmarks: bookmarks.filter((t) => t.termBundle !== termBundle),
      }
    case RESET_ALL: //
      // console.log("RESET", action)
      return action.initialState
    case RESET_EXAMPLES:
      // console.log("RESET", action)
      return {
        ...state,
        examples: [],
      }
    default:
      return state
  }
}

export function useBookmarksReducer() {
  const lang = useContext(LangContext)
  const storageKey = getStorageKey(lang.id)
  const initialState = useMemo(() => getInitialState(storageKey), [storageKey])
  const [bookmarksState, bookmarksDispatch] = useReducer(
    bookmarksReducer,
    initialState
  )
  const oderedBookmarks = useMemo(() => {
    const { bookmarks, examples } = bookmarksState
    return [
      ...bookmarks.filter((b) => b.langId === lang.id).map((b) => b.termBundle),
      ...examples.filter((e) => e.langId === lang.id).map((e) => e.termBundle),
    ]
  }, [bookmarksState, lang.id])
  useEffect(() => {
    // flush on lang change
    // bookmarksDispatch(resetBookmarks(initialState))
  }, [initialState])
  useEffect(() => {
    // save in local storage
    const parsedBookmarks = JSON.stringify(bookmarksState.bookmarks)
    window.localStorage.setItem(storageKey, parsedBookmarks)
  }, [bookmarksState, storageKey])
  return [oderedBookmarks, bookmarksDispatch]
}

function getInitialState(storageKey) {
  if (typeof window === "undefined") return defaultState
  const bookmarks = JSON.parse(window.localStorage.getItem(storageKey)) || []
  return {
    bookmarks,
    examples: [],
  }
}

function getStorageKey(langId = "de") {
  return `${STORAGE_KEY}_${langId}`
}
