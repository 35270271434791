import React from "react"
import Card from "./card"
import { useCategories } from "../category-filter/categories"
import { FlexBox, FlexItem } from "../flex-box"
import { CardWrapper, CardDeckWrapper, CardDeckInner } from "./card-deck.style"
import { useInViewClass } from "../../modules/hooks"
import { useZIndexes, ZWrapper } from "./z-indexes"
import useInitPoses from "./init-poses"
import { isMobile } from "../../modules/utils"

// Inspired by https://codesandbox.io/s/fduch
// see also: https://github.com/orbiting/republik-frontend/blob/master/components/Marketing/Employees.js

const CardDeck = React.forwardRef(({ children = [], cards = [], backgroundImage, tileSize = {}, maxPerRow, flexHook }, ref) => {
  const inViewRef = useInViewClass("on-deck", { threshold: 0 })
  const [categoryFilter, filteredCards] = useCategories(cards)
  const [zIndexes, setTopIndex] = useZIndexes(cards)
  const mobile = isMobile()
  const initPoses = useInitPoses(cards, mobile)

  const { width, height } = tileSize

  return (
    <CardDeckWrapper ref={ref} backgroundImage={backgroundImage}>
      {children}
      {categoryFilter}
      <CardDeckInner ref={inViewRef}>
        <FlexBox itemWidth={width} itemHeight={height} maxPerRow={maxPerRow} flexHook={flexHook} >
          {filteredCards.map((card, i) => {
            // console.log(card.props)
            const { id, pos, filtered, hidden } = card.props
            const transform = `${initPoses[i]} scale(${filtered ? 0.3 : 1})`
            const onSelect = () => {
              if (zIndexes[zIndexes.length - 1] !== i) {
                setTopIndex(i)
              }
            }
            return (
              <ZWrapper key={`-${id || i}`} zIndex={zIndexes.indexOf(i) + 2}>
                <FlexItem pos={pos}>
                  <CardWrapper style={{ transform }} {...{ hidden, filtered }}>
                    <Card id={i} onSelect={onSelect}>
                      {card}
                    </Card>
                  </CardWrapper>
                </FlexItem>
              </ZWrapper>
            )
          })}
        </FlexBox>
      </CardDeckInner>
    </CardDeckWrapper>
  )
})

export default CardDeck

// setTopIndex(i)
