import styled from "styled-components"
import chroma from "chroma-js"

const DEFAULT_COLOR = "#444444"

const ColorDot = styled.div`
  width: ${(p) => (p.bar ? "1.6em" : "1.2em")};
  margin-left: ${(p) => (p.bar ? "-7px" : "auto")};
  height: 1.2em;
  border-radius: 50%;
  background-color: ${(p) => chroma(p.color || DEFAULT_COLOR).alpha(0.8).hex()};
`

export default ColorDot
