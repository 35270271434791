import styled, { css } from "styled-components"

const desktopCompression = 0.62
const tabletCompression = 0.5
const mobileCompression = 0.8

const desktopWidth = "900px"
const tabletWidth = "750px"

const widthCss = css`
  width: ${desktopWidth};
  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    width: ${tabletWidth};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    width: 100%;
  }
`

const fixedCss = css`
  position: fixed;
  top: 0;
  left: auto;
  &.expanded {
    transform: scale(1) translate3d(0, calc(50vh - 50%), 0);
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    transform-origin: center bottom;
    &.expanded {
      transform: scale(1)
        translate3d(-${(p) => p.theme.sidePaddingMobile}, calc(50vh - 50%), 0);
    }
  }
  body:not(.active-extra-column-items) & {
    &.collapsed {
      transform: scale(${desktopCompression})
        translate3d(71%, calc(50vh - 50%), 0);
      @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
        transform: scale(${tabletCompression})
          translate3d(80%, calc(50vh - 50%), 0);
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        transform: scale(${mobileCompression})
          translate3d(-${(p) => p.theme.sidePaddingMobile}, calc(100vh - 80%), 0);
      }
    }
  }
  body.active-extra-column-items &,
  body.on-gallery & {
    &.collapsed {
      transform: scale(${desktopCompression})
        translate3d(165%, calc(50vh - 50%), 0);
      @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
        transform: scale(${tabletCompression})
          translate3d(175%, calc(50vh - 50%), 0);
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        transform: scale(${mobileCompression})
          translate3d(-${(p) => p.theme.sidePaddingMobile}, calc(100vh - 80%), 0);
      }
    }
  }

  z-index: 19;
`

const relativeCss = css`
  position: relative; // absolute
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface WrapperProps {
  hideFull?: boolean
}

export const CompressorWrapper = styled.div<WrapperProps>`
  display: block;
  position: relative;
  // padding: 0 0 56.25% 0; // 56.25%
  overflow: ${(p) => (p.hideFull ? "hidden" : "show")};
  max-height: ${(p) => (p.hideFull ? "0 !important" : "none")};
  min-height: ${(p) => (p.hideFull ? "0 !important" : "none")};
  margin-top: 3em;
  // margin-bottom: 3em;
`

export const CompressorInner = styled.div<{ onClick?: () => any }>`
  transition:
    transform 0.38s ease-in-out,
    opacity 0.3s ease-in-out; // 4 .38s
  // transform-origin: bottom-right;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  &.fixed {
    ${fixedCss}
  }
  &.relative {
    ${relativeCss}
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  ${widthCss}
`

export const CompressorOuter = styled.div<{
  ref: React.RefObject<HTMLDivElement>
}>`
  ${widthCss}
  margin: 0 auto;
`

interface OverlayProps {
  active: boolean
  onClick?: () => any
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: background-color 0.3s ease;
  pointer-events: ${(p) => (p.active ? "auto" : "none")};
`

export const HoverOverlay = styled.button<{ active: boolean }>`
  z-index: 3;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: ${(p) => (p.active ? "all" : "none")};
  .expand-icon {
    color: white;
    font-size: ${(p) => p.theme.fontSizeH1};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: 0.3s ease;
    transform-origin: center;
  }
  &:hover {
    opacity: ${(p) => (p.active ? 1 : 0)};
    .expand-icon {
      transform: translateX(-50%) translateY(-50%) scale(1.2);
    }
  }
`
