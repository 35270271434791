import React, { createContext, useReducer } from "react"

export interface TocItem {
  uid: string
  [key: string]: any
}

export const TocContext = createContext<[items: TocItem[], r: React.Reducer<any, any>]>([[], () => {}])

// action types
const ADD_TOC_ITEM = "ADD_TOC_ITEM"
const REMOVE_TOC_ITEM = "REMOVE_TOC_ITEM"
const SET_CURR_CHAPTER = "SET_CURR_CHAPTER"
const SET_PREV_CHAPTER = "SET_PREV_CHAPTER"

// action creators
export function addTocItem(uid: string, object: Record<any, any>) {
  return {
    type: ADD_TOC_ITEM,
    uid,
    object,
  }
}
export function removeTocItem(uid: string) {
  return {
    type: REMOVE_TOC_ITEM,
    uid,
  }
}
export function setCurrChapter(uid: string) {
  return {
    type: SET_CURR_CHAPTER,
    uid,
  }
}
export function setPrevChapter(uid: string) {
  return {
    type: SET_PREV_CHAPTER,
    uid,
  }
}

const initialState: TocItem[] = []

interface Action {
  type: string
  uid: string
  object?: Record<any, any>
}

export function tocReducer(state = initialState, action: Action) {
  switch (action.type) {
    case ADD_TOC_ITEM:
      /* auto expand 1st toc on desktop
      if (
        !document.body.classList.contains("with-map") &&
        !isMobile() &&
        !state.length
      )
        action.object.expand() */
      return [...state.filter((i) => i.uid !== action.uid), action.object]
    case REMOVE_TOC_ITEM:
      return [...state.filter((i) => i.uid !== action.uid)]
    case SET_CURR_CHAPTER:
      return [
        ...state.map((i) => ({
          ...i,
          current: i.uid === action.uid ? true : false,
        })),
      ]
    case SET_PREV_CHAPTER:
      return [
        ...state.map((it, i, items) => ({
          ...it,
          current: items[i + 1]?.uid === action.uid ? true : false,
        })),
      ]
    default:
      return state
  }
}

export function withToc(Comp: React.ComponentType) {
  return (props: Record<any, any>) => {
    const tocRed = useReducer(tocReducer, [])
    return (
      <TocContext.Provider value={tocRed}>
        <Comp {...props} />
      </TocContext.Provider>
    )
  }
}
