import React, { useState, useContext, useCallback, Dispatch, SetStateAction } from "react"

export const ConsentContext = React.createContext<[ConsentObj, Dispatch<SetStateAction<ConsentObj>>]>([{}, () => null])

interface ConsentObj {
  [host: string]: boolean
}

export function withConsent(Comp) {
  return (props) => {
    // TODO: preserve state in localStorage ? 
    const consentState = useState<ConsentObj>({})
    return (
      <ConsentContext.Provider value={consentState}>
        <Comp {...props} />
      </ConsentContext.Provider>
    )
  }
}

export function useConsent(hostName: string) {
  const [consentObj, setConsentObj] = useContext(ConsentContext)
  const consent = consentObj[hostName] || false
  const setConsent = useCallback((arg) => {
    setConsentObj(co => {
      const hostConsent = co[hostName]
      const newHostConsent = typeof arg === "function" ? arg(hostConsent) : arg
      return {
        ...co,
        [hostName]: newHostConsent
      }
    })
  }, [hostName, setConsentObj])
  return [consent, setConsent] as const
}
