import React, { useContext, useState } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import styled from "styled-components"
import { MethodsContext, MethodsLink } from "./methods-text"

const Wrapper = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.termFreqToolMaxWidth} !important;
  font-size: ${(p) => p.theme.fontSizeSmall};
  line-height: 1.7em;
  span:not(:last-child)::after {
    display: inline;
    margin: 0 0.5em;
    content: "|";
    font-weight: 700;
    color: inherit;
    @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
      margin: 0 0.5em;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
    margin: 0.5em auto 3em !important;
    padding: 0 ${(p) => p.theme.toolSidePadding};
  }
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    margin: 0.5em auto 3em !important;
  }
`

type TranslationObj = Record<string, string>

const SOURCE_TEXT: TranslationObj = {
  de: "Quelle",
  en: "Source",
  ru: "Источник",
}

const METHODS_LINK_TEXT: TranslationObj = {
  de: "Wie wir die Daten aufbereitet haben",
  en: "How we analysed the data",
  ru: "Как мы обрабатывали данные",
}

const DOWNLOAD_TEXT: TranslationObj = {
  de: "Download",
  en: "Download",
  ru: "Скачать",
}

const LAST_UPDATE_TEXT: TranslationObj = {
  de: "Stand",
  en: "Last update",
  ru: "Обновлено",
}

interface Props {
  sourceName?: string
  sourceUrl?: string
  downloadUrl?: string
  lastUpdate?: string
  hasMethods?: boolean
  methodsText?: React.ReactNode
}

export const TermFrequencyToolMeta = ({
  sourceName,
  sourceUrl,
  downloadUrl,
  lastUpdate,
  hasMethods,
  methodsText,
}: Props) => {
  const lang = useContext(LangContext)
  const methodsState = useState(false)
  return (
    <MethodsContext.Provider value={methodsState} >
      <Wrapper>
        {!!sourceName && (
          <span>
            {SOURCE_TEXT[lang.id] || SOURCE_TEXT["en"]}:{" "}
            {sourceUrl ? (
              <a href={sourceUrl} target="_blank" rel="noreferrer">
                {sourceName}
              </a>
            ) : (
              sourceName
            )}
          </span>
        )}
        {!!hasMethods && (
          <span>
            <MethodsLink>
              {METHODS_LINK_TEXT[lang.id] || METHODS_LINK_TEXT["en"]}
            </MethodsLink>
          </span>
        )}
        {!!downloadUrl && (
          <span>
            <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
              {DOWNLOAD_TEXT[lang.id] || DOWNLOAD_TEXT["en"]}
            </a>
          </span>
        )}
        {!!lastUpdate && (
          <span>
            {LAST_UPDATE_TEXT[lang.id] || LAST_UPDATE_TEXT["en"]}: {lastUpdate}
          </span>
        )}
      </Wrapper>
      {methodsText}
    </MethodsContext.Provider>
  )
}
