import React, { useContext } from "react"
import { CatFiltWrapper, CatFiltButton } from "./category-filter.style"
import ColorDot from "./color-dot"
import { LangContext } from "gatsby-source-dek-wp"

const CategoryFilter = ({ categories = [], onCategoryClick }) => {
  const lang = useContext(LangContext)
  return (
    <CatFiltWrapper>
      {categories
        .filter((c) => !c.hide)
        .map((c) => (
          <CatFiltButton
            key={c.key}
            active={c.selected}
            onClick={() => onCategoryClick(c.key)}
          >
            <div>
              <ColorDot color={c.color} />
            </div>
            <div>{c.label ? c.label[lang.id] : "–"}</div>
          </CatFiltButton>
        ))}
    </CatFiltWrapper>
  )
}

export default CategoryFilter
