import {
  breakpointMobile,
  breakpointTablet,
} from "../components/app/globals.module.scss"

const BREAKPOINT_MOBILE = parseInt((breakpointMobile || "").replace("px", ""))
const BREAKPOINT_TABLET = parseInt((breakpointTablet || "").replace("px", ""))

export function isMobile() {
  if (typeof window === "undefined") return true
  return window.innerWidth <= BREAKPOINT_MOBILE
}

export function isTablet() {
  if (typeof window === "undefined") return true
  return window.innerWidth <= BREAKPOINT_TABLET
}

export function isTouchDevice() {
  return "ontouchstart" in document.documentElement
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export function getFormatedDate(dateString) {
  const date = new Date(dateString)
  let dd = date.getDate()
  let mm = date.getMonth() + 1
  let yyyy = date.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }
  return dd + "." + mm + "." + yyyy
}
