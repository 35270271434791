import React, { createContext } from "react"
import { CardDiv } from "./card.style"
import useFlip from "./flip"
import useDragMove from "./drag-move"
import { isMobile } from "../../modules/utils"

export const MovingContext = createContext()
export const DragBindContext = createContext()

const Card = ({ children, onSelect = () => {}, initialRot }) => {
  const [bind, style, set, moving] = useDragMove(onSelect, initialRot)
  const [flip, child] = useFlip(set, children)

  const cardBind = bind() // isMobile() ? { onClick: onSelect } : bind()

  return (
    <MovingContext.Provider value={moving}>
      <DragBindContext.Provider value={bind}>
        <CardDiv {...cardBind} style={style} /* onDoubleClick={flip} */>
          {child}
        </CardDiv>
      </DragBindContext.Provider>
    </MovingContext.Provider>
  )
}
export default Card
