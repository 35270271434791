import React  from "react"
import FullscreenWrapper from "../../../../shared/src/components/fullscreen-wrapper/fullscreen-wrapper"
import { Wrapper, Title } from "./lead-img.style"

const LeadImg = ({ slide = {}, langId, backArrow }) => {
  const { image, title } = slide // subtitle
  return (
    <FullscreenWrapper headerHeight={50}>
      <Wrapper image={image}>
        <Title>{title}</Title>
      </Wrapper>
    </FullscreenWrapper>
  )
}

export default LeadImg
