exports.categoryIds = [5059, 5093]

exports.siteMetaInfo = {
  de: {
    title: "„OST“",
    subtitle: "Sowjetische Zwangsarbeiter im Deutschen Reich",
    description: "Sowjetische Zwangsarbeiter im Deutschen Reich",
    imageUrl: "https://specials.dekoder.org/content/uploads/2021/06/zwangsarbeit_social_de.png",
    slug: "/de"
  },
  ru: {
    title: "«Остарбайтеры»",
    subtitle: "Рабский труд в гитлеровской Германии",
    description: "Рабский труд в гитлеровской Германии",
    imageUrl: "https://specials.dekoder.org/content/uploads/2021/06/zwangsarbeit_social_de.png",
    slug: "/ru"
  }
}

exports.socialImgComp = require.resolve("./src/components/social-image/social-image.js")

exports.siteMetadata = {
  title: `„OST“`,
  description: `Sowjetische Zwangsarbeiter im Deutschen Reich`,
  siteUrl: `https://ost.dekoder.org`,
  author: `dekoder.org`
}

exports.pathPrefix = ""
exports.faviconPath = `../shared/src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = "UA-66707787-6"