import React from "react"
import { LangContext, ALL_LANGS } from "gatsby-source-dek-wp"

const LayoutStandalone = ({ pageContext, children }) => {
  const { langId } = pageContext
  const lang = ALL_LANGS.find((l) => l.id === langId)
  return <LangContext.Provider value={lang}>{children}</LangContext.Provider>
}

export default LayoutStandalone
