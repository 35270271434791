import styled from "styled-components"

export const Wrapper = styled.aside`
  margin-top: 3em !important;
  margin-bottom: 3em !important;
  .title-centered & {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .special-relations-label {
    color: #777;
    font-weight: 300;
    text-transform: uppercase;
    margin-right: 0.5em;
  }
  ul.special-relations-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    li {
      display: inline;
      background-color: ${(p) => p.theme.dossierYellow};
      color: black;
      margin-right: 0.5em;
      a,
      a:hover,
      a:active,
      a:visited {
        padding: 0.1em 0.25em;
        color: black !important;
        border: none !important;
      }
    }
  }
`
