import React, { useContext } from "react"
// import ChartSSR from "../chart/chart-ssr"
import { LangContext } from "gatsby-source-dek-wp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faTelegramPlane,
} from "@fortawesome/free-brands-svg-icons"
import { ShareBarWrapper } from "./share-bar.style"
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

// const SHARE_APP_URL = "http://localhost:8087"
const SHARE_APP_URL = "https://share.dekoder.org"
// const SHARE_APP_URL = "https://share.dekoder.tech"
const REDIRECT_BASE_URL = "https://share.dekoder.org"
// const REDIRECT_BASE_URL = "https://share.dekoder.tech"

const FB_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u="
const TW_SHARE_URL = "https://twitter.com/intent/tweet?text="
const TG_SHARE_URL = "https://telegram.me/share/url?url="

const SHARE_STR = {
  de: "diese Ansicht teilen",
  en: "share this view",
  ru: "поделиться этим графиком",
}

const ShareBar = ({ data, shareProjectName, yearRange }) => {
  const lang = useContext(LangContext)
  // const [showPreview, setShowPreview] = useState(false)
  const onButtonClick = async (shareBaseUrl) => {
    // console.log({ data, shareProjectName, yearRange})

    const win = window.open("about:blank")

    const imageId = await fetch(`${SHARE_APP_URL}/${shareProjectName}/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data, lang, yearRange }),
      mode: "cors",
    })
      .then((r) => r.text())
      .catch((e) => console.log(e))
    // console.log(imageId)

    const redirectUrl = `${REDIRECT_BASE_URL}/${shareProjectName}/?img=${imageId}&terms=${encodeURIComponent(
      data.map((d) => d.value).join(",")
    )}&langId=${lang.id}`
    const shareUrl = `${shareBaseUrl}${encodeURIComponent(redirectUrl)}`

    // window.open(shareUrl)
    win.location = shareUrl
  }
  return (
    <ShareBarWrapper className="share-bar">
      <div className="share-bar-buttons">
        <button onClick={() => onButtonClick(TW_SHARE_URL)}>
          <FontAwesomeIcon icon={faTwitter} />
        </button>
        <button onClick={() => onButtonClick(TG_SHARE_URL)}>
          <FontAwesomeIcon icon={faTelegramPlane} />
        </button>
        <button onClick={() => onButtonClick(FB_SHARE_URL)}>
          <FontAwesomeIcon icon={faFacebookF} />
        </button>
      </div>
      <span>← {SHARE_STR[lang.id]}</span>
    </ShareBarWrapper>
  )
}

export default ShareBar
