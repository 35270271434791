import React, { useMemo, useEffect } from "react"
import { Wrapper } from "./embed.style"
import { Script } from "gatsby"
import { Toggle } from "./toggle"
import { useConsent } from "./consent"

const PRIVACY_POLICY_URL = {
  de: "https://www.dekoder.org/de/datenschutzerklaerung",
}

interface Props {
  children: any
  url: string
  scriptUrl?: string
  text?: string
  processEmbeds?: () => void
  caption?: React.ReactNode // <figcaption>...</figcaption>
}

export function Embed({
  children,
  url = "",
  scriptUrl = "",
  text = "",
  processEmbeds,
  caption,
  ...props
}: Props) {
  let hostname = ""
  try {
    const parsedUrl = new URL(url || scriptUrl)
    hostname = parsedUrl.hostname.replace(/^www\./, "")
  }
  catch(e) {
    console.error("Embed: wrong URL provided", e, { url, scriptUrl, props, text })
  }
  const [consent, setConsent] = useConsent(hostname)
  useEffect(() => {
    // TODO: only once per hostname
    if (consent && typeof processEmbeds === "function") processEmbeds()
  }, [consent, processEmbeds])
  return (
    <Wrapper className="embed-wrapper" active={consent}>
      <div className="embed-consent-wrapper">
        {!!text && !consent && <div className="embed-text">{text}</div>}
        <div>
          <Toggle active={consent} onClick={() => setConsent((c) => !c)} />{" "}
          <div>
            <div>
              <a href={url} rel="noreferrer" target="_blank">
                externe Inhalte
              </a>{" "}
              von <strong>{hostname}</strong> zeigen
            </div>
            {!consent && (
              <div>
                <small>
                  dabei werden Daten an Dritte übertragen (→{" "}
                  <a href={PRIVACY_POLICY_URL["de"]} target="_blank">
                    mehr zum Datenschutz
                  </a>
                  )
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="embed-content-wrapper">
        {children}
      </div>
      {caption}
      {consent && <Script async src={scriptUrl} />}
    </Wrapper>
  )
}


