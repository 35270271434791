import React, { useContext, useMemo } from "react"
import { QuizContext } from "./quiz"
import { OptionButton } from "./quiz-option.style"
import { QuizItemContext } from "./quiz-item"
import { transformString } from "../../modules/transformer"

const QuizOption = ({ text, correct }) => {
  const onOptionClick = useContext(QuizContext)
  const { id, selection = {} } = useContext(QuizItemContext)
  const isSelected = selection.value === text
  const onClick = () => onOptionClick(id, text, correct)
  const optionText = useMemo(() => transformString(text), [text])
  return (
    <OptionButton selected={isSelected} correct={correct} onClick={onClick}>
      {optionText}
    </OptionButton>
  )
}

export default QuizOption
