import { useEffect } from "react"

export function useTermUrlEffect(enabled, termState) {
  useEffect(() => {
    if (!enabled) return
    const terms = termState.terms.map((t) => t.term).join(",")
    const newURI = encodeURIComponent(terms)
    window.history.replaceState(
      "",
      "",
      `${window.location.pathname}${window.location.search}${newURI ? `#q=${newURI}` : ""}`
    )
  }, [enabled, termState])
}
