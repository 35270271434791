import React from "react"
import { TileWrapper } from "./tile-wrapper"
import { Tile } from "./tile"

export const SpecialTiles = ({
  contents = [],
  localPrefix = "https://specials.dekoder.org",
}) => {
  return (
    <TileWrapper>
      {contents
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .map((c, i) => (
          <Tile
            key={c.slug || i}
            content={c}
            localPrefix={localPrefix}
            title={c.title}
          />
        ))}
    </TileWrapper>
  )
}
