import React, { createContext, useReducer } from "react"

export const MapPointContext = createContext([])

let previousY = 0 // to calcuclate intersection direction
export const getPreviousY = () => previousY
export const setPreviousY = (y) => (previousY = y)

const defaultState = { mapPoints: [], current: null, routes: [] }

export default function withMapPoints(Comp) {
  return (props) => {
    const mapRed = useReducer(mapPointReducer, defaultState)
    return (
      <MapPointContext.Provider value={mapRed}>
        <Comp {...props} />
      </MapPointContext.Provider>
    )
  }
}

function mapPointReducer(state, action) {
  const { mapPoints } = state //
  const {
    type,
    id,
    lat,
    lon,
    category,
    onClick,
    routes,
    current,
    children,
  } = action
  switch (type) {
    case "add":
      return {
        ...state,
        mapPoints: [
          ...mapPoints,
          { id, lat, lon, category, onClick, children },
        ],
      }
    case "remove":
      return {
        ...state,
        mapPoints: [...mapPoints.filter((a) => a.id !== id)],
      }
    case "setCurrent":
      return {
        ...state,
        current,
      }
    case "setRoutes":
      return {
        ...state,
        routes,
      }
    default:
      throw new Error()
  }
}

export function add(id, lat, lon, category = "", onClick, children) {
  return { type: "add", id, lat, lon, category, onClick, children }
}

export function remove(id) {
  return { type: "remove", id }
}

export function setCurrent(current) {
  return { type: "setCurrent", current }
}

export function setRoutes(routes) {
  return { type: "setRoutes", routes }
}
