import React, { useState, useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark } from "@fortawesome/free-regular-svg-icons"
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from "@mui/material"
import { addBookmark } from "./bookmarks-reducer"
import { BookmarkButtonWrapper } from "./bookmark-button.style"

import { TermStateContext } from "../contexts/terms"
import { BookmarksContext } from "../contexts/bookmarks"

const REMEMBER_STR = {
  de: "Suche merken",
  en: "remember terms",
  ru: "запомнить запрос",
}

const BookmarkButton = ({ solid, withText }) => {
  const lang = useContext(LangContext)
  const termState = useContext(TermStateContext)
  const [, bookmarkDispatch] = useContext(BookmarksContext)
  const saveCurrentTerms = () => {
    const terms = termState.terms
    bookmarkDispatch(addBookmark(terms, lang.id))
  }
  const [hover, setHover] = useState(false)
  return (
    <BookmarkButtonWrapper
      className={`bookmark-button-wrapper lang-${lang.id}`}
      onClick={saveCurrentTerms}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      {!!withText && <span>{REMEMBER_STR[lang.id]} →</span>}
      <Tooltip title={REMEMBER_STR[lang.id]} arrow>
        <div className="bookmark-button-inner">
          <FontAwesomeIcon
            icon={hover || solid ? faBookmarkSolid : faBookmark}
          />
        </div>
      </Tooltip>
    </BookmarkButtonWrapper>
  )
}

export default BookmarkButton
