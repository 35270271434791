import React, { useContext, useEffect } from "react"
import { useResize, useTopAdjust, useMinHeight } from "./extra-column-hooks"
import "./extra-column.scss"
import {
  closeAllItems,
  ExtraColumnDispatchContext,
  ExtraColumnItemsContext,
} from "./extra-column-reducer"

const SPLIT_VIEW_MARGIN_TOP = 30

export const ExtraColumn = ({ bodyRef, setMinHeight }) => {
  const items = useContext(ExtraColumnItemsContext)
  const dispatch = useContext(ExtraColumnDispatchContext)
  let refCollection = []
  const windowHeight = useResize()
  const extraColumnHeight = useTopAdjust(refCollection, items, windowHeight)
  useMinHeight(extraColumnHeight, setMinHeight)
  const hasItems = !!items.filter((i) => i.props.expanded && i.props.refEl)
    .length
  useBodyClass(hasItems ? "active-extra-column-items" : "")
  if (!bodyRef.current) return null
  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      className={`extra-column${hasItems ? " has-items" : ""}`}
      onClick={() => dispatch(closeAllItems())}
    >
      {items
        .filter(
          (item) =>
            (item.props.refEl && item.props.uid === "toc") ||
            (item.props.expanded && item.props.refEl)
        )
        .map((item, i, arr) => {
          // console.log(arr);
          const topOffset = item.props.refEl
            ? item.props.options.fromSplitview
              ? // within SplitView: place popup below parent block
                getOffsetBottom(
                  findParentBySelector(item.props.refEl.current, "p,h3"),
                  bodyRef.current
                ) + SPLIT_VIEW_MARGIN_TOP
              : // standard placement
                getOffsetTop(item.props.refEl.current, bodyRef.current)
            : 0 // getOffsetTop(pageBody.current);
          // console.log({topOffset, split: item.props.options.fromSplitview})
          return (
            <div
              key={`extra_column_${item.props.uid}`}
              orderkey={`${item.props.linkKey}${item.props.uid}`}
              ref={(el) => refCollection.push(el)}
              position={topOffset}
              className={`extra-column-item-outer ${
                item.props.extraClass || ""
              }`}
              style={{ top: `${topOffset}px` }}
            >
              {item}
            </div>
          )
        })}
    </div>
  )
}

export function findParentBySelector(elm, selector) {
  const all = Array.from(document.querySelectorAll(selector))
  let cur = elm.parentNode
  while (cur && !all.includes(cur)) {
    cur = cur.parentNode
  }
  // console.log("FP", { cur, elm, selector })
  return cur //will return null if not found
}

export const getOffsetTop = (element, referenceEl) => {
  return getElementOffset(element, referenceEl, "top")
}

export const getOffsetBottom = (element, referenceEl) => {
  return getElementOffset(element, referenceEl, "bottom")
}

function getElementOffset(element, referenceEl = document.body, side) {
  if (typeof document === "undefined") return
  if (!element) return
  const referenceRect = referenceEl.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  return Math.round(elemRect[side] - referenceRect.top)
}

export function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}
