import {
  headerHeight,
  blockMaxWidth,
  blockMaxWidthTablet,
  blockMaxWidthCentered,
  blockMaxWidthCenteredTablet,
  blockMarginLeftRight,
  blockMarginLeftRightTablet,
  blockMarginBottom,
  sidePaddingMobile,
  breakpointTablet,
  breakpointMobile,
  fontSizeH0,
  fontSizeH1,
  fontSizeH2,
  fontSizeH3,
  fontSizeH4,
  fontSizeH5,
  fontSizeSmall,
  fontSizeSuperSmall,
  titleColorSolid,
  titleColorText,
  titleColorRgb,
  articleRed,
  articleRedRgb,
  gnoseBlue,
  dossierYellow,
  bgDark,
} from "./globals.module.scss"

export const theme = {
  headerHeight,
  blockMaxWidth,
  blockMaxWidthTablet,
  blockMaxWidthCentered,
  blockMaxWidthCenteredTablet,
  blockMarginLeftRight,
  blockMarginLeftRightTablet,
  blockMarginBottom,
  sidePaddingMobile,
  breakpointTablet,
  breakpointMobile,
  fontSizeH0,
  fontSizeH1,
  fontSizeH2,
  fontSizeH3,
  fontSizeH4,
  fontSizeH5,
  fontSizeSmall,
  fontSizeSuperSmall,
  titleColorSolid,
  titleColorText,
  titleColorRgb,
  articleRed,
  articleRedRgb,
  gnoseBlue,
  dossierYellow,
  bgDark
}
