import { articleRed } from "../shared/src/components/app/globals.module.scss"
import dekoderLogoWhite from "../shared/src/images/logos/dekoder-white.svg"
import dekoderLogoBlack from "../shared/src/images/logos/dekoder-black.svg"
import evzLogoDe from "./src/images/logos/Logo_EVZ_EL_RGB_DE_blau.svg"
import evzLogoRu from "./src/images/logos/Logo_EVZ_EL_RGB_RU_blau.svg"

import bg from "./src/images/wooden-background.jpg"

import PhotoTile from "./src/components/photo-tile"
import LeadImg from "./src/components/lead-img"

export const CustomTile = PhotoTile
export const CustomLead = LeadImg

export const tileSize = {
  width: 400,
  height: 300
}

export const maxPerRow = 3

export const flexHook = ({ x, y, i, itemsPerRow }) => {
  // shift 1st and 3rd tile up on desktop
  return (itemsPerRow === 3 && (i === 0 || i === 2))
    ? [x, y - 70]
    : [x, y]
}

export const backgroundImage = bg
export const bgNotFixed = true

export const socialImgDefault = {
  de: "https://specials.dekoder.org/content/uploads/2021/06/zwangsarbeit_social_de.png",
  ru: "https://specials.dekoder.org/content/uploads/2021/06/ostarbeitery_social_ru.png"
}

export { siteMetaInfo, siteMetadata, restApi, categoryIds } from "./meta-data-node"

export const siteLogos = [
  { 
    src: dekoderLogoWhite, 
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
    },
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]"
    }
  }
]

export const categories = [
  {
    id: 0,
    key: "article",
    color: articleRed,
    selected: true,
    label: {
      de: "Geschichten",
      ru: "Истории"
    }
  },
  {
    id: 1,
    key: "gnose",
    color: articleRed, // gnoseBlue,
    selected: true,
    label: {
      de: "Hintergründe",
      ru: "Контекст"
    }
  },
  {
    id: 2,
    key: "special",
    color: articleRed, // specialContentColor,
    selected: true,
    label: {
      de: "Spezial",
      ru: "Спец"
    }
  },
  {
    id: 3,
    key: "about",
    color: "black",
    selected: true,
    hide: true,
    label: {
      de: "Über das Projekt",
      ru: "О проекте"
    }
  },
  {
    id: 4,
    key: "default",
    color: "black",
    selected: true,
    label: {
      de: "default",
      ru: "default"
    }
  }
]

export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию"
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.dekoder.org/",
      en: "https://specials.dekoder.org/en",
      ru: "https://www.dekoder.org/ru"
    },
    logo: dekoderLogoBlack,
    scale: 0.6,
  },

  {
    name: {
      de: "Stiftung „Erinnerung, Verantwortung und Zukunft“ (EVZ)",
      ru: "Фонд „Память, ответственность и будущее“"
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.stiftung-evz.de",
      en: "https://www.stiftung-evz.d",
      ru: "https://www.stiftung-evz.de/rus/nachalo.html"
    },
    logo: {
      de: evzLogoDe,
      ru: evzLogoRu
    },
    scale: 1,
  },
]

export const mediaPartners = [
]
