import styled from "styled-components"
import chroma from "chroma-js"

const ALPHA = 0.7

const red = chroma("#af504c").alpha(ALPHA).hex()
const green = chroma("#4CAF50").alpha(ALPHA).hex()

export const OptionButton = styled.button`
  text-align: left;
  margin-bottom: 1em;
  width: 100%;
  padding-left: 0.5em;
  display: flex;
  align-items: center;
  &::before {
    display: block;
    content: "";
    width: 1em;
    height: 1em;
    flex: 0 0 1em;
    border: 1px solid gray;
    border-radius: 50%;
    margin-right: 1em;
    background-color: ${(p) =>
      p.selected ? (p.correct ? green : red) : "transparent"};
  }
  &:hover::before {
    background-color: ${(p) =>
      p.selected ? (p.correct ? green : red) : "rgba(240, 240, 240, 1)"};
  }
`
