import styled from "styled-components"
import * as defaultTheme from "../theme.default"

/* const languageMenuRightMobile = "0px"
const languageMenuRight = "60px"
const languageMenuWidth = "150px"
const languageMenuWidthmobile = "95px"
const languageButtonHeight = "30px"*/

export const LangSwitcherWrapper = styled.div`
  height: ${p => p.theme.headerHeight};
  z-index: 100;
  background-color: transparent;
  @media print {
    display: none;
  }
  cursor: pointer;
  pointer-events: auto;
  transition: 0.5s ease;
  color: var(--header-font-color);

  display: flex;
  justify-content: center;
  align-items: center;
  body.on-slide-projector:not(.menu-open) & {
    display: none;
  }

  .language-option {
    color: white !important;
    // color: var(--header-font-color);
    text-transform: uppercase;
    letter-spacing: 4px;
    padding-right: 15px;
    @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
      letter-spacing: 3px;
      padding-right: 9px;
    }
    &:not(.selected) {
      font-size: ${p => p.theme.fontSizeSmall};
      font-weight: 300;
    }
    &.selected {
      font-weight: 700;
      font-size: ${p => p.theme.fontSizeSmall}; // -standard
    }
  }
  a, a:visited, a:active, a:hover {
    text-decoration: none;
  }
`

LangSwitcherWrapper.defaultProps = {
  theme: {
    ...defaultTheme
  }
}