import React, { useEffect, useRef, useMemo, useContext } from "react"
import { v4 as uuidv4 } from "uuid"
import ExtraColumnItem from "./extra-column-item"
import {
  updateColumnItem,
  removeColumnItem,
  ExtraColumnDispatchContext,
} from "./extra-column-reducer"

import "./extra-column-portal.scss"

export const ExtraColumnPortal = (props) => {
  const { children, refEl, options = {} } = props
  const inlineRef = useRef()
  useUpdateOnChange(props, inlineRef)

  if (refEl && !options.showInline) return null

  return (
    <div ref={inlineRef} className={`extra-column-item-ref`}>
      <div className="extra-column-item-ref-inner">{children}</div>
    </div>
  )
}

function useUpdateOnChange(props, inlineRef) {
  const uid = useUid()
  const dispatch = useContext(ExtraColumnDispatchContext)
  useEffect(() => {
    const { expanded = true, refEl } = props
    // console.log('extra-column update');
    const comp = (
      <ExtraColumnItem
        {...props}
        options={props.options || {}}
        uid={uid}
        refEl={refEl || inlineRef}
        expanded={expanded}
      />
    )
    dispatch(updateColumnItem(uid, comp))
    return () => dispatch(removeColumnItem(uid))
  }, [props, inlineRef, uid, dispatch])
}

export const useUid = () => {
  return useMemo(uuidv4, [])
}
