import React, { useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import * as styles from "./remote-control.style"
import { LangContext } from "gatsby-source-dek-wp"

const { RemoteWrapper, InfraRed, SlideBtn, SelectBtn, BrandLogo } = styles

const SlideProjectorRemote = ({ prev, next, onSelect, metaData }) => {
  const { siteLogos } = metaData
  const lang = useContext(LangContext)
  const [hidden, setHidden] = useState(false)
  useEffect(() => {
    setTimeout(() => setHidden(true), 2000)
  }, [])
  return (
    <RemoteWrapper
      hide={hidden}
      onMouseLeave={() => setHidden(true)}
      onMouseEnter={() => setHidden(false)}
    >
      <InfraRed />
      <Link to={`/${prev?.slug}`}>
        <SlideBtn left>
          <FontAwesomeIcon icon={faCaretLeft} />
        </SlideBtn>
      </Link>
      <Link to={`/${next?.slug}`}>
        <SlideBtn right>
          <FontAwesomeIcon icon={faCaretRight} />
        </SlideBtn>
      </Link>
      <SelectBtn onClick={onSelect} />
      <BrandLogo>
        {siteLogos.map((l, i) => (
          <a key={i} href={l.url[lang.id]} target="_blank" rel="noreferrer">
            <img src={l.src} alt={l.slogan[lang.id]} />
          </a>
        ))}
      </BrandLogo>
    </RemoteWrapper>
  )
}

export default SlideProjectorRemote
