import styled from "styled-components"
import { breakpointMobile, breakpointTablet } from "../../../../shared/src/components/app/globals.module.scss"

const borderWidth = 0

export const TileWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 400px;
  height: 300px;
  @media screen and (max-width: ${breakpointMobile}) {
    width: 320px;
    height: 240px;
  }
  &.small {
    margin: 0 auto;
    width: 320px;
    height: 240px;
    a:hover {
      border: none !important;
    }
    @media screen and (max-width: ${breakpointTablet}) {
      width: 240px;
      height: 180px;
    }
  }
  box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.8);
  background: white;
  border: ${borderWidth}px solid white;
  transform-style: preserve-3d;
  // backface-visibility: hidden;
  background: url(${(p) => p.image}), rgba(0, 0, 0, 0.2);
  background-blend-mode: darken;
  background-size: cover;
  background-position: 50% 0;
  &::after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    transform-style: preserve-3d;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.6);
    background-image: radial-gradient(
      rgba(0, 0, 0, 0.05) 70%,
      rgba(0, 0, 0, 0.3)
    );
  }
`

export const TitleWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  // transform: translateX(-50%);
  // max-width: 90%;
  width: 30%;
  text-align: left;

  backface-visibility: hidden;
  // border: 1px dashed gray;
  a, a:active, a:hover, a:visited {
    color: white;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 0.9em;
    // background: black;
    // box-shadow: 5px 0 0 black, -5px 0 0 black;
  }
`

export const TileBackside = styled.div`
  transform: translateZ(-2px) rotateY(180deg);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background: white;
  transform-style: preserve-3d;
  backface-visibility: hidden;
`
