import styled, { css } from "styled-components"
import {
  blockMaxWidth,
  blockMaxWidthTablet,
  blockMarginLeftRight,
  blockMarginLeftRightTablet,
  blockMarginBottom,
  breakpointTablet,
  breakpointMobile,
  fontSizeH2,
  fontSizeH5,
  fontSizeSmall,
  fontSizeSuperSmall,
  titleColorSolid,
  bgDark,
} from "../app/globals.module.scss"

export const ArticleWrapper = styled.div`
  background: white;
  color: black;
  min-height: 100%;
  padding-bottom: 1em;
`

export const ArticleBody = styled.div`
  position: relative;
  padding-top: 2em;
`

export const blockStyles = css`
  max-width: ${blockMaxWidth};
  margin: 0 ${blockMarginLeftRight} ${blockMarginBottom};

  @media screen and (max-width: ${breakpointTablet}) {
    max-width: ${blockMaxWidthTablet};
    margin: 0 ${blockMarginLeftRightTablet} ${blockMarginBottom};
  }

  @media screen and (max-width: ${breakpointMobile}) {
    padding: 0 15px ${blockMarginBottom};
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  @media print {
    padding: 0 15px ${blockMarginBottom};
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
`

export const ArticleBodyInner = styled.div`
  & > * {
    ${blockStyles}
  }

  h4 {
    // :not(.subheading)
    margin-top: 3em !important;
    margin-bottom: 2em !important;
    // text-transform: uppercase;
    &.has-text-align-center {
      text-align: center;
      margin-top: 3em !important;
      margin-bottom: 3em !important;
      text-transform: none;
    }
  }

  sup {
    font-size: ${fontSizeSuperSmall};
  }

  figure.alignleft {
    float: left;
    margin: 0.4rem 2rem 1rem 0;
    @media screen and (max-width: ${breakpointMobile}) {
      float: none;
      margin: 0.4rem 1rem 1rem 15px;
    }
  }

  figure.wp-block-gallery {
    &.is-layout-flex.bigsize {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 1em;
      .wp-block-image {
        width: calc(50% - (0.5em));
        margin: 0;
      }
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        column-gap: 0.5em;
        .wp-block-image {
          width: calc(50% - (0.25em));
        }
      }
      figcaption {
        margin-top: 1em !important;
        flex-basis: 100%;
      }
      &.is-cropped img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
    &:not(.is-layout-flex.bigsize) {
      margin: 2rem 0 !important;
      max-width: none !important;
      padding: 2rem 0;
    }
    ul {
      display: flex;
      // justify-content: center
      margin: 0;
      padding: 0 10vw 0 10vw;
      @media screen and (max-width: ${breakpointMobile}) {
        padding: 0;
      }
      overflow-x: auto;
      scroll-snap-type: x proximity;
      scroll-behavior: smooth;
      list-style: none;

      li.blocks-gallery-item {
        figure {
          text-align: center;
          display: table;
          // border: 1px dashed red;
          margin: 2rem;
          img {
            display: block;
            max-width: 50vw;
            height: auto;
            max-height: 90vh;
            width: auto;
          }
          @media screen and (max-width: ${breakpointMobile}) {
            margin: 1rem;
            img {
              max-width: 65vw;
            }
          }
          figcaption {
            display: table-caption;
            caption-side: bottom;
            text-align: center;
            // border: 1px dashed red;

            margin-top: 1rem;
            font-size: ${fontSizeSuperSmall};
            color: rgb(180, 180, 180);
            a,
            a:hover,
            a:visited,
            a:active {
              color: rgb(180, 180, 180) !important;
            }
            font-family: "Roboto Condensed";
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }
        }
        // max-width: 50vw;
      }
    }
    &.bg-dark {
      padding: 4rem 0;
      margin: 5rem 0 !important;
      background-color: ${bgDark}; // #202020; // #101010;
      box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);
      figcaption {
        color: rgb(240, 240, 240);
      }
      li.blocks-gallery-item {
        img {
          box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  .wp-block-pullquote {
    margin: 5rem 0 !important;
    max-width: none !important;
    padding: 8rem 0;
    background-color: rgb(240, 240, 240);
    @media screen and (max-width: ${breakpointMobile}) {
      padding: 8rem 15px;
    }

    blockquote {
      max-width: 660px;
      margin: 0 auto;
      font-weight: 400;
      line-height: 1.8em;
      position: relative;
      // transition: all .3s ease-in-out;
      @media screen and (min-width: ${breakpointTablet}) {
        body.has-pullquote-popup & {
          ${blockStyles}
        }
      }
      &:before {
        font-family: "Lora", serif;
        font-size: 4rem; // ${fontSizeH2};
        font-weight: 700;
        line-height: 0.1;
        content: "„";
        position: absolute;
        display: block;
        left: -5rem;
        top: -0.5rem;
        color: ${titleColorSolid};

        @media screen and (max-width: ${breakpointMobile}) {
          top: -4rem;
          left: 0;
        }
      }
      p {
        font-style: italic;
        strong {
          font-size: ${fontSizeH5};
          display: block;
          margin: 3em 0;
          font-weight: 700;
          // line-height: 1.8em;
          @media screen and (min-width: ${breakpointTablet}) {
            margin-left: -2em;
          }
        }
      }
      cite {
        font-style: normal; // italic;
        font-family: "Lora";
        font-size: ${fontSizeSmall};

        display: block;
        max-width: 70%;
        text-align: right;
        margin: 2rem 0 0 auto;
        line-height: 1.5em;

        a,
        a:hover,
        a:visited,
        a:active {
          color: black !important;
        }

        /* font-style: normal;
        font-family: "Roboto Condensed";
        font-size: ${fontSizeSuperSmall};

        // color: rgb(180, 180, 180);
        letter-spacing: 0.5px;
        text-transform: uppercase;*/
      }
    }
    &.bg-dark {
      // & {
      box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);
      background-color: ${bgDark};
      blockquote {
        color: rgb(240, 240, 240);
      }
      cite {
        a,
        a:hover,
        a:visited,
        a:active {
          color: rgb(240, 240, 240) !important;
        }
      }
    }
  }

  .flex-container {
    .wp-block-group__inner-container {
      display: flex;
      align-items: center;
    }
    &.flex-wrap .wp-block-group__inner-container {
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        flex-wrap: wrap;
      }
    }
  }
`
