import React from "react"
import "./menu-button.scss"

const MenuButton = ({ menuOpen, onClick }) => (
  <button
    className={
      "menu-button hamburger hamburger--collapse" +
      (menuOpen ? " is-active" : "")
    }
    onClick={onClick}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
)

export default MenuButton
