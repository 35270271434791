import React, { useMemo, cloneElement } from "react"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import { v4 as uuidv4 } from "uuid"

import {
  subheadingTransformer,
  introTransformer,
  footnoteTransformer,
  embedTransformer
} from "gatsby-source-dek-wp"
import faqTransformer from "../components/faq/faq.transformer"
import {
  linkTransformer,
  annotationTransformer
} from "gatsby-plugin-popup-preloader"
import quizTransformer from "../components/quiz/quiz.transformer"
import mapTransformer from "../components/map/map.transformer"
import mapPointTransformer from "../components/map/map-point.transformer"

let transformers = [
  faqTransformer,
  linkTransformer,
  quizTransformer,
  introTransformer,
  annotationTransformer,
  subheadingTransformer,
  mapTransformer,
  mapPointTransformer,
  footnoteTransformer,
  embedTransformer
]

const options = {
  replace: transform,
}

export function transform(node) {
  // node, i
  if (node.name === "style") return <></> // null
  // if (node.type === "script") return <></>
  if (!["tag", "script"].includes(node.type)) return
  const classList = nodeClassList(node)
  const { style } = attributesToProps(node.attribs)
  const children = getChildren(node)

  for (let x = 0; x < transformers.length; x++) {
    const t = transformers[x]
    const comp = t(node, 0, { classList, style, children })
    if (comp) return cloneElement(comp, { ...comp.props, key: comp.fixedKey || uuidv4() })
  }
}

export function transformString(contentString = "") {
  return parse(contentString || "", options)
}

export function useTransformer(contentString) {
  const transformedContent = useMemo(
    () => transformString(contentString),
    [contentString]
  )
  return transformedContent
}

function getChildren(node) {
  const c = domToReact(node.children, options)
  return React.Children.toArray(c).filter(isNotNewLine)
}

function nodeClassList(node) {
  if (!node.attribs || !node.attribs.class) return []
  return node.attribs.class.split(" ")
}

function isNotNewLine(child) {
  if (typeof child === "string" && child.trim() === "") return false
  else return true
}