import styled from "styled-components"
import * as defaultTheme from "../theme.default"

export const TileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2em 2em;

  @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
    padding: 0 0 2em;
  }
  
  flex-wrap: wrap;
  width: 100%;
  max-width: 1900px;
  // border: 1px dashed green;
  margin-left: auto;
  margin-right: auto;

  @media print {
    display: none;
  }
`

TileWrapper.defaultProps = {
  theme: {
    ...defaultTheme
  }
}
