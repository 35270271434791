import React from "react"
import Link from "./link"

export function linkTransformer(node, i, { style, children }) {
  if (node.name === "a") {
    const fromPullquote = hasAncestorWithClass(node, "wp-block-pullquote")
    const fromSplitview = hasAncestorWithClass(node, "split-view")
    return (
      <Link
        linkKey={i}
        href={node.attribs.href}
        target={node.attribs.target}
        id={node.attribs.id}
        classes={node.attribs.class}
        style={style}
        children={children}
        annotation={node.attribs["data-annotation"]}
        options={{
          fromPullquote,
          fromSplitview,
        }}
      />
    )
  }
}

function hasAncestorWithClass(node, className) {
  // eslint-disable-next-line no-cond-assign
  while (node = node.parentNode) {
    if ((node?.attribs?.class || "").split(" ").includes(className)) return true
  }
  return false
}
