import React, { useState, useMemo } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/free-solid-svg-icons"

import { partiesCount, partiesObj2Array } from "../party-tooltip/parties-info"

const FILTER_PARTIES = [
  { name: "ЕДИНАЯ РОССИЯ" },
  { name: "КПРФ" },
  { name: "ЛДПР" },
  { name: "СПРАВЕДЛИВАЯ РОССИЯ" },
]

const Wrapper = styled.div`
  font-size: ${(p) => p.theme.fontSizeSmall};
  padding: 0 ${(p) => p.theme.toolSidePadding};
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    display: none;
    padding: 0 ${(p) => p.theme.toolSidePaddingMobile};
  }
  display: none; // flex;
  justify-content: space-around;
  margin-bottom: .5em;;
  border: 1px dashed rgba(0, 0, 255, 0.2);
`

const Button = styled.button`
  border-bottom: 3px solid;
  border-color: ${p => p.isSelected ? "black" : "transparent"};
`

export const PartyFilter = ({ onClick, selected }) => {
  return (
    <Wrapper>
      <button onClick={() => onClick(null)}>
        <FontAwesomeIcon icon={faFilter} />
      </button>
      {FILTER_PARTIES.map((p, i) => (
        <Button
          key={i}
          isSelected={p.name === selected}
          onClick={() => onClick(p.name)}
        >
          {p.name}
        </Button>
      ))}
    </Wrapper>
  )
}

// TODO: what to do with chaning base rates (i.e. LDPR gets more seats -> )

export const usePartyFilter = (data) => {
  const [selected, setSelected] = useState()
  const onClick = (name) => setSelected((s) => (s === name ? null : name))
  const comp = <PartyFilter onClick={onClick} selected={selected} />
  const filteredData = useMemo(() => {
    return !selected
      ? data 
      : data.map(d => ({
        ...d,
        data: d.data.map(p => { 
          const parties = partiesObj2Array(p.parties)
          const totalCount = partiesCount(parties)
          const filteredParties = parties.filter(p => p.name === selected)
          const filteredCount = partiesCount(filteredParties)
          const percent = filteredCount / totalCount
          return {
            ...p,
            y: percent * p.y || 0
          }
      })
      }))
  }, [selected, data])
  return [filteredData, comp]
}
