import styled, { createGlobalStyle } from "styled-components"
import { breakpointMobile, breakpointTablet } from "./globals.module.scss"

export const MainWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.8);
  /* transition: transform .3s ease-in-out;
  transform: ${p => p.menuExpanded ? "translateY(60vh)" : "translateY(0)"};*/ 
`

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`
