import React, { useState, useEffect, useContext } from "react"
import { LangContext } from "gatsby-source-dek-wp"

const HINTS = {
  de: [
    "Suchen Sie hier nach beliebigen Schlagwörtern",
    "Um Suchwörter zu kombinieren, ziehen Sie diese aufeinander",
    "Unten finden Sie verschiedene Beispiele zum Ausprobieren",
  ],
  en: ["", "", ""],
  ru: ["", "", ""],
}
export function useHints() {
  const lang = useContext(LangContext)

  const [hintIndex, setHintIndex] = useState(0)
  useEffect(() => {
    const HINT_TIMEOUT = 5000
    let x = setInterval(
      () => setHintIndex((i) => (i + 1) % HINTS[lang.id].length),
      HINT_TIMEOUT
    )
    return () => {
      clearInterval(x)
      setHintIndex(0)
    }
  }, [lang.id])

  const hintComp = <>
  {(HINTS[lang.id] || []).map((hint, i) => {
    return (
      <span key={i} className={`hint${i === hintIndex ? " active" : ""}`}>
        {hint}
      </span>
    )
  })}</>

  return hintComp
}