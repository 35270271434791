import React, { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "gatsby-source-dek-wp"
import { transcribe } from "transkribator"

import { TranslateContext } from "../contexts/translate"

import partyDict from "./duma_parties.json" // TODO: dynamic

const Wrapper = styled.div`
  /* position: fixed;
  top: 100%;
  left: 0;
  // border: 1px dashed red;
  z-index: 20;*/
  margin-top: 0.5em;
  width: 100%;
  font-size: 0.6em;
  // display: table;
  // background-color: rgba(255, 255, 255, 0.5);
  /*> div { // bar
    display: table-row;
    width: 100%;
    > div {
      display: table-cell;
      padding: 0.5em;
      // border: 1px dashed red;
      &:last-child {
        text-align: right;
      }
    }
  }*/
`

interface BarProps {
  barWidth: string
  barColor: string
}

const Bar = styled.div<BarProps>`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // border: 1px dashed red;
  &:after {
    display: block;
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(p) => p.barWidth};
    background-color: ${(p) => p.barColor};
  }
  > div {
    z-index: 2;
    // border: 1px dashed green;
    padding: 0.5em;
  }
`

const OTHERS = {
  de: "andere",
  ru: "другие"
}

const UNKNOWN = {
  de: "unbekannnt",
  ru: "неизвестно"
}

type PartiesObj = Record<string, number>

interface Props {
  partiesObj: PartiesObj
  barColor?: string
  maxParties?: number
}

export const PartiesInfo = ({ partiesObj = {}, barColor, maxParties = 3 }: Props) => {
  const parties = partiesObj2Array(partiesObj)
    .sort((a, b) => b.count - a.count)
    .reduce((acc, curr, i) => {
      const others = acc.find((p) => p.name === OTHERS)
      return i < maxParties
        ? [...acc, curr]
        : i === maxParties
        ? [...acc, { name: OTHERS, count: curr.count }]
        : [
            ...acc.filter((p) => p !== others),
            { ...others, count: others.count + curr.count },
          ]
    }, [])
  const totalCount = partiesCount(parties)
  const transcribeEnabled = useContext(TranslateContext)
  const lang = useContext(LangContext)
  return (
    <Wrapper>
      {parties.map((p, i) => {
        const percent = Math.round((p.count / totalCount) * 100)
        if (p.name === "Unknown") p.name = UNKNOWN
        const name = typeof p.name === "object"
          ? p.name[lang.id]
          : p.name
        return (
          <Bar key={i} barWidth={`${percent}%`} barColor={barColor}>
            <div>
              {transcribeEnabled ? transcribe(name, { to: lang.id }) : name}
            </div>
            <div>{p.count}</div>
          </Bar>
        )
      })}
    </Wrapper>
  )
}

export function partiesObj2Array(partiesObj: PartiesObj) {
  return Object.keys(partiesObj).map((key) => ({
    name: partyDict[key] || "Unknown",
    count: partiesObj[key],
  }))
}

export function partiesCount(partiesArray) {
  return partiesArray.reduce((acc, p) => acc + p.count, 0)
}

// <div>{/* p.count */ percent}{"\u00A0"}%</div>
