import styled, { css } from "styled-components"
import {
  blockMaxWidth,
  blockMaxWidthTablet,
  blockMarginLeftRight,
  blockMarginLeftRightTablet,
  blockMarginBottom,
  breakpointTablet,
  breakpointMobile,
  fontSizeH4,
  fontSizeH5,
} from "../app/globals.module.scss"

export const MapPointWrapper = styled.div`
  background-color: transparent !important;
`
export const boxStyles = css`
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.4);
  background-color: white;
  padding: 1em;
  pointer-events: auto;
`

export const blockStyles = css`
  // margins
  pointer-events: none;
  max-width: ${blockMaxWidth};
  margin: 0 ${blockMarginLeftRight} ${blockMarginBottom};

  @media screen and (max-width: ${breakpointTablet}) {
    max-width: ${blockMaxWidthTablet};
    margin: 0 ${blockMarginLeftRightTablet} ${blockMarginBottom};
  }

  @media screen and (max-width: ${breakpointMobile}) {
    padding: 0 10px ${blockMarginBottom};
    margin: 0;
    max-width: none;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  padding: 1em;
  h4 {
    margin: 0 !important;
    font-size: ${fontSizeH4};
    text-transform: uppercase;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.8);

    @media screen and (max-width: ${breakpointMobile}) {
      font-size: ${fontSizeH5};
    }
  }
  // ${boxStyles}
  background: transparent;
  color: white;
  margin-bottom: 4em;
`

export const BoxWrapper = styled.div`
  // padding: 1em;
  min-height: 90vh;
  padding-bottom: 90vh;

  > p {
    margin: 0;
    background-color: white;
    ${boxStyles}
  }

  > .profile-container {
    > div {
      border-left: 3px solid ${(p) => p.color || "transparent"};
    }
  }

  & > figure {
    padding: 1em !important;
    background-color: white;
    margin-bottom: 90vh;
  }

  background-color: transparent !important;
`
