import React, { useEffect, useRef } from "react"
// import useAutoHide from "./auto-hide"
import { HeaderWrapper } from "./header.style"
import { SpecialTiles } from "../special-tiles/special-tiles"

interface HeaderProps {
  children: React.ReactNode
  expanded?: boolean
  contents?: any // TODO
  withSpecialsPreview?: boolean
  onExpand?: () => void
  className?: string
}

export const Header = ({ children, expanded, contents, withSpecialsPreview = true, onExpand, className = "" }: HeaderProps) => {
  const hideHeader = false // useAutoHide()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const naviRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (typeof onExpand === "function") onExpand()
    if (expanded) return
    if (!wrapperRef.current) return 
    // naviRef.current.scrollIntoView({ behavior: "smooth" })
    wrapperRef.current.scrollTop = 0
  }, [expanded, onExpand])

  return (
    <HeaderWrapper
      className={`header ${hideHeader ? "hidden" : ""} ${
        expanded ? "expanded" : ""
      } ${className}`}
      ref={wrapperRef}
    >
      <div ref={naviRef} className="header-navi">{children}</div>
      {withSpecialsPreview && <div className="specials-preview">
        <SpecialTiles contents={contents} />
      </div>}
    </HeaderWrapper>
  )
}
