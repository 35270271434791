import styled from "styled-components"

export const Banderole = styled.div`
  z-index: 3;
  position: absolute;
  padding: 5px 3.5em;
  font-size: ${(p) => p.theme.fontSizeSuperSmall};
  top: 0;
  right: 0;
  transform: rotate(45deg) translateX(30%);
  background-color: ${(p) => p.theme.articleRed};
  text-align: center;
  text-transform: uppercase;
  opacity: 0.7;

  a, a:hover, a:active, a:visited {
    color: white !important;
  }

  &.left {
    right: auto;
    left: 0;
    transform: rotate(-45deg) translateX(30%);
  }
`
