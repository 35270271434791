import styled from "styled-components"

const Wrapper = styled.div`
  /* position: absolute;
  left: 10px;
  top: 10px; */
  margin-left: 10px;
  opacity: 0.9;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  pointer-events: auto;

  .on-slide-projector & {
    opacity: 0.4;
  }

  ${p => p.hide && `opacity: 0; pointer-events: none;`}

  a {
    &,
    &:active,
    &:hover,
    &:visited {
      color: white !important;
      text-decoration: none !important;
      border: none !important;
    }
  }

  &:hover {
    opacity: 1;
  }
`
export default Wrapper
