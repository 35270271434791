import * as React from "react"
import { Embed } from "./embed"
import { useMobileState } from "../site-logo/site-logo"


export function FacebookResponsive(props) {
  const isMobile = useMobileState()
  const { src = "" } = props
  const url = isMobile ? src.replace(/width\=[0-9]*$/, "width=300") : src
  return (
    <Embed url={url}>
      <iframe {...props} src={url} />
    </Embed>
  )
}