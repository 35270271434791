// extracted by mini-css-extract-plugin
export var alphaLight = ".4";
export var alphaMinimal = ".05";
export var alphaUltraLight = ".1";
export var articleRed = "#c93324";
export var articleRedRgb = "#c93324";
export var bgDark = "#151518";
export var blockMarginBottom = "1em";
export var blockMarginLeftRight = "calc((100% - 1080px)/2.5)";
export var blockMarginLeftRightTablet = "calc((100% - 800px)/2.5)";
export var blockMaxWidth = "660px";
export var blockMaxWidthCentered = "720px";
export var blockMaxWidthCenteredTablet = "650px";
export var blockMaxWidthTablet = "500px";
export var breakpointDesktopBig = "1600px";
export var breakpointMobile = "1000px";
export var breakpointTablet = "1350px";
export var dossierYellow = "#ffdb29";
export var fontSizeH0 = "3.815em";
export var fontSizeH1 = "3.052em";
export var fontSizeH2 = "2.441em";
export var fontSizeH3 = "1.953em";
export var fontSizeH4 = "1.563em";
export var fontSizeH5 = "1.25em";
export var fontSizeSmall = ".8em";
export var fontSizeSuperSmall = ".64em";
export var gnoseBlue = "#129fc9";
export var headerHeight = "50px";
export var sidePaddingMobile = "15px";
export var slideProjectorBorderRadius = "50px";
export var slideProjectorBorderRadiusMobile = "25px";
export var specialContentColor = "#46c924";
export var titleColor = "rgba(201,51,36,.7)";
export var titleColorLight = "rgba(201,51,36,.4)";
export var titleColorRgb = "#c93324";
export var titleColorSoild = "#c93324";
export var titleColorSolid = "#c93324";
export var titleColorText = "#fff";
export var titleColorUltraLight = "rgba(201,51,36,.1)";