import React, { useEffect, useState, useRef, useContext } from "react"
import { ExtraColumnPortal } from "../extra-column/extra-column-portal"
import { popupAggregator } from "../../popup-aggregator"
import { DekoderPopup, AnnotationPopup } from "./popup"
import PlusMinusButton from "./plus-minus-button"
import "./link.scss"
import { ContentsContext } from "gatsby-source-dek-wp"
import scrollIntoView from "scroll-into-view"

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

function useDekoderPopup(type, href) {
  const existingPopup = popupAggregator.getPopupByUrl(href)
  const initialState = { ...existingPopup } || {}
  const [popup, setPopup] = useState(initialState)

  useEffect(() => {
    if (type !== "dekoder-link") return
    let isMounted = true
    const getPopupData = async () => {
      await delay(200)
      const { type, text, title } = await popupAggregator.addByUrl(href)
      if (!isMounted) return
      setPopup({ type, text, title, url: href })
    }
    getPopupData()
    return () => (isMounted = false)
  }, [href, type])

  return popup
}

const LinkWithPopup = (props) => {
  const {
    type,
    children,
    href,
    linkKey,
    annotation,
    content = {},
    target,
    options,
  } = props
  const { fromPullquote, fromSplitview } = options
  const [expanded, setExpanded] = useState(false)
  const [hover, setHover] = useState(false)
  const ref = useRef()
  const dekPopupProps = useDekoderPopup(type, href)

  const popupComp =
    type === "annotation" ? (
      <AnnotationPopup
        annotation={annotation}
        annotationId={href.replace("annotation:", "")}
      />
    ) : type === "internal-link" ? (
      <DekoderPopup
        title={content.title}
        text={`<p>${content.description}</p>`}
        url={href}
        type="internal-link"
        target={target}
      />
    ) : (
      <DekoderPopup {...dekPopupProps} />
    )

  const dekoderPopupNotYetLoaded =
    type === "dekoder-link" && !dekPopupProps.text && !dekPopupProps.title

  function onClick(e) {
    if (dekoderPopupNotYetLoaded) return
    e.preventDefault()
    setExpanded((exp) => !exp)
  }

  const url =
    dekPopupProps && dekPopupProps.type !== "blurb"
      ? dekPopupProps.url || href
      : "#"

  return (
    <>
      <a
        ref={ref}
        href={url}
        className={getStateClasses(dekPopupProps.type || type, expanded, hover)}
        onClick={onClick}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        target="_blank"
        rel="noreferrer"
      >
        <span>{children}</span>
        {!dekoderPopupNotYetLoaded && (
          <>
            {"\u00A0"}
            <PlusMinusButton expanded={expanded} />
          </>
        )}
      </a>
      {expanded && (
        <ExtraColumnPortal
          refEl={ref}
          linkKey={linkKey}
          type={`popup ${dekPopupProps.type || type}`}
          expanded={expanded}
          hover={hover}
          collapse={() => setExpanded(false)}
          onMouseOver={() => setHover(true)}
          onFocus={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onBlur={() => setHover(false)}
          options={{ fromPullquote, fromSplitview }}
        >
          {popupComp}
        </ExtraColumnPortal>
      )}
    </>
  )

  function getStateClasses(type, expanded, hover) {
    let result = "link has-popup"
    if (type) result += ` ${type}`
    if (expanded) result += ` active`
    if (hover) result += ` hover`
    return result
  }
}

const Link = (props) => {
  const { children, href = "", classes, annotation, linkPrefix, style } = props
  const isInternal = href.match(/^\//i)
  const isAnchor = href.match(/^#/i)
  const contents = useContext(ContentsContext)
  const internalContent = (contents || []).find((c) => `/${c.slug}` === href.split("#")[0])

  if (href.match(/^annotation:/) || annotation)
    return (
      <LinkWithPopup type="annotation" {...props}>
        {children}
      </LinkWithPopup>
    )
  else if (isDekoderLink(href))
    return (
      <LinkWithPopup type="dekoder-link" {...props}>
        {children}
      </LinkWithPopup>
    )
  else if (isInternal) {
    // internal link
    return (
      <LinkWithPopup type="internal-link" {...props} content={internalContent}>
        {children}
      </LinkWithPopup>
    )
  } else {
    const onAnchorClick = (e) => {
      e.preventDefault()
      const el = document.querySelector(href)
      scrollIntoView(el, { align: { top: 0, topOffset: 60 } })
      const newPath = `${window.location.pathname}${href}`
      window.history.pushState({}, "", newPath)
    }
    return (
      <a
        href={`${linkPrefix || ""}${href}`}
        id={props.id}
        className={classes}
        style={style}
        target={isAnchor ? "_self" : "_blank"}
        rel="noopener noreferrer"
        onClick={isAnchor ? onAnchorClick : undefined}
      >
        {children}
      </a>
    )
  }
}

export default Link

export const isDekoderLink = (url) => {
  const re1 = /^https:\/\/www\.dekoder\.org\/(de|ru)\/(.*?)/g
  // const re2 = /^https:\/\/[\w]+.dekoder\.org/g // specials
  if (url.match(re1)) return true // || url.match(re2))
}
