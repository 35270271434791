import React, { useContext, useEffect } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { ChartTooltip } from "./tooltip.style" // CtxBtn
import { transcribe } from "transkribator"
import chroma from "chroma-js"
import { PartiesInfo } from "../party-tooltip/parties-info"

// import { ContextPreview } from "../../context-preview/context-preview.js"
import { TranslateContext } from "../contexts/translate"

export const ChartTooltipContext = React.createContext([undefined, () => {}])

const COUNT_STR = {
  de: "Vorkommnisse",
  en: "hits",
  re: "hits",
}

const FREQ_STR = {
  de: "pro 100.000 Wörter",
  en: "per 100,000 words",
  ru: "на 100 000 слов",
}

const Tooltip = ({ points, extended = false, setShowContexts, hasClick }) => {
  const lang = useContext(LangContext)
  const transcribeEnabled = useContext(TranslateContext)

  const [, setTooltipState] = useContext(ChartTooltipContext) || [undefined, () => {}]
  useEffect(() => {
    setTooltipState(points) // [points, x]
    return () => setTooltipState([])
  }, [points, setTooltipState])

  if (!points.length) return null
  const { x, interval } = points[0].data

  const date = interval === "month"
    ? `${new Date(x).getFullYear()}.${new Date(x).getMonth() + 1}`
    : `${new Date(x).getFullYear()}`

  return (
    <>
      <ChartTooltip>
        <div className="year">{date}</div>
        <div className="table">
          {points
            .sort((a, b) => b.data.y - a.data.y)
            .map((point) => {
              const { serieId, serieColor } = point
              const { term, y, count, parties } = point.data
              const termName = term.split("+").join(" + ")
              const bgColor = chroma(serieColor || "gray")
                .alpha(0.25)
                .css()

              const barColor = chroma(serieColor || "gray")
                .alpha(0.45)
                .css()
              return (
                <div
                  style={{ backgroundColor: bgColor, padding: ".5em" }}
                  key={serieId}
                >
                  <div className="series">
                    <div className="color">
                      <div style={{ backgroundColor: serieColor }} />
                    </div>
                    <div className="name">
                      {transcribeEnabled
                        ? transcribe(termName, { to: lang.id })
                        : termName}
                    </div>
                  </div>
                  <div className="count">
                    <div />
                    <div>
                      <div className="values">
                        <span>{count.toLocaleString(lang.locale)}</span>
                        <span>{COUNT_STR[lang.id]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="freq">
                    <div />
                    <div>
                      <div className="values">
                        <span>
                          {(Math.round(y * 100) / 100).toLocaleString(
                            lang.locale
                          )}
                        </span>
                        <span>{FREQ_STR[lang.id]}</span>
                      </div>
                    </div>
                  </div>
                  <PartiesInfo
                    partiesObj={parties}
                    barColor={barColor}
                    maxParties={extended ? 8 : 4}
                  />
                  {hasClick && <small>Click for more information ...</small>}
                </div>
              )
            })}
        </div>
      </ChartTooltip>
    </>
  )
}

export default Tooltip

/* 

                    <CtxBtn onClick={() => setShowContexts(point)}>
                      Show contexts ...
                    </CtxBtn>
                    */