import React from "react"
import scratches from "./scratch-overlay.png"
import { Wrapper, SlideImg, Scratches, SepiaFilter } from "./slide-image.style"

const scratchesUrl =
  typeof scratches === "string"
    ? scratches
    : "https://specials.dekoder.org/content/uploads/2020/07/scratch-overlay.png" // as backup for SSR

const SlideImage = ({ image, title, projected }) => {
  return (
    <Wrapper mini={!projected}>
      <SlideImg projected={projected} src={image} alt={title} />
      {projected && <Scratches src={scratchesUrl} alt="overlay" />}
      <SepiaFilter fuzzy={projected} />
    </Wrapper>
  )
}

export default SlideImage
