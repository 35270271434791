import { useState, useEffect } from "react"

// const YEARS = [...Array(21).keys()].map((x) => x + 2000) // 2000-2020
const YEARS = [...Array(30).keys()].map((x) => x + 1994) // 1994-2022

const DEFAULT_FREQ_OBJ = {
  x: 0, // year
  y: 0, // freq
  count: 0,
  term: "",
  parties: {},
  contexts: {},
}

export const ERROR_MSG: Record<string, string> = {
  de: "Verbindungsfehler: Bitte versuchen Sie es später nochmal",
  en: "Connection error: Please try again later",
  ru: "Ошибка соединения: попробуйте чуть позже",
}

async function getFromSuggestionApi(query: string, langId = "de", suggestionApi: string) {
  // console.log(query, langId, suggestionApi)
  const result = await fetch(`${suggestionApi}/${langId}/${query}`)
    // .then((r) => r.text().then(rslt => console.log(rslt, query, langId, suggestionApi)))
    .then((r) => r.json())
    .catch((e) => console.log(e))
  return result
}

async function getFromTermApi(term: string, langId: string, termApi: string) {
  const result = await fetch(`${termApi}/${langId}/${term.toLowerCase()}`)
    .then((r) => r.json())
    .catch((e) => console.log(`Could not find any values for ${term}`))
  return result
}

export async function getSuggestions(
  inputValue: string,
  callback: (options: any[]) => void,
  langId: string,
  suggestionApi: string
) {
  const suggestionData = await getFromSuggestionApi(
    inputValue,
    langId,
    suggestionApi
  )
  if (!suggestionData) {
    callback([{ type: "error", value: ERROR_MSG[langId], totalCount: 0 }])
    return
  }
  const sortedOptions = suggestionData.map(elasticDataToOption)
  callback(sortedOptions)
}

export function useTermsQuery(termState = {}, termApi: string) {
  const [elasticData, setElasticData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    // const tqStr = termState.terms.map(t => t.term).join(",")
    // if (prevTqStr === tqStr) return
    // prevTqStr = tqStr
    // console.log("NEW TERMS QUERY", termState.terms)
    const loadTermData = async () => {
      setLoading(true)
      // console.log("BEFORE LOADING", termState)
      const termData = await loadMultiple(
        termState.terms,
        termState.langId,
        termApi
      )
      // console.log("AFTER LOADING", termData)
      if (termState.terms.length > 1 && termData.every((t) => !t)) {
        // some
        setError(true)
        return // 1 not found => навальный example
      }
      setError(false)
      setLoading(false)
      setElasticData(termData)
    }
    loadTermData()
  }, [termState.terms, termState.langId, termApi])
  const data = elasticData.map(elasticDataToOption)
  return [data, loading, error]
}

let termCache = {}

async function getData(termObj, langId: string, termApi: string) {
  const terms = termObj.term.split("+")
  let termNames = []
  let termNamesDe = []
  let termNamesEn = []
  let totalResult = {
    term: { input: "" },
    total_count: 0,
    frequencies: YEARS.map((year) => ({ ...DEFAULT_FREQ_OBJ, x: `${year}-01-01` })),
    color: termObj.color,
  }
  for (let i = 0; i < terms.length; i++) {
    const term = terms[i]
    // if (!termCache[term]) console.log(`CHECK ES FOR ${term}`)
    const res = termCache[term] || (await getFromTermApi(term, langId, termApi))

    if (!res)
      totalResult = {
        // empty
        ...totalResult,
        term: { input: terms.join("+") },
      }
    if (res) {
      termCache[term] = res
      termNames = [...termNames, res.term.input]
      if (res.term_translation_de) {
        termNamesDe = [...termNamesDe, res.term_translation_de.input]
      }
      if (res.term_translation_en) {
        termNamesEn = [...termNamesEn, res.term_translation_en.input]
      }
      const freqs = yearsFrequencyMapping(res.frequencies)
      const normalizedFrequencies = normalizeFreqs(freqs)
      const mergedFrequencies = mergeFrequencies(
        totalResult.frequencies,
        normalizedFrequencies,
        termNames.join("+")
      )
      const sortedFrequencies = sortFreqs(mergedFrequencies)
      // console.log({ freqs, normalizedFrequencies, mergedFrequencies })
      totalResult = {
        ...totalResult,
        term: { input: termNames.join("+") },
        term_translation_de: { input: termNamesDe.join("+") },
        term_translation_en: { input: termNamesEn.join("+") },
        total_count: totalResult.total_count + res.total_count,
        frequencies: sortedFrequencies,
      }
    }
  }
  return totalResult
}

function getInterval(frequencies) {
  if (!frequencies?.length) return "year"
  const intervalByDateParts = {
    1: "year",
    2: "month",
    3: "day"
  }
  const partsCount = String(frequencies[0].x).split(".").length
  const result = intervalByDateParts[partsCount] || "year"
  return result
}

function normalizeDate(str) { // ensure format: YYYY-mm-dd
  const [year, month = "01", day = "01"] = String(str).split(".")
  const dateStr = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
  return dateStr
}

function normalizeFreqs(frequencies) {
  const interval = getInterval(frequencies)
  return frequencies.map((freq) => ({ ...freq, interval, x: normalizeDate(freq.x)}))
}

function sortFreqs(frequencies) {
  return frequencies
    .sort((a, b) => new Date(a.x) - new Date(b.x))
}

async function loadMultiple(termObjs, langId, termApi) {
  return Promise.all(
    termObjs.map(async (termObj) => getData(termObj, langId, termApi))
  )
}

function elasticDataToOption(d, i) {
  return {
    // this is important for react-select
    label: `${d.term.input}`,
    value: d.term.input,
    translationDe: d.term_translation_de ? d.term_translation_de.input : "",
    translationEn: d.term_translation_en ? d.term_translation_en.input : "",
    totalCount: d.total_count,
    color: d.color,
    // this is important for chart:
    id: String(i), // d.term.input,
    data: d.frequencies, // ? yearsFrequencyMapping(d) : undefined
  }
}

function yearsFrequencyMapping(d) {
  const currYears = Object.keys(d)
  // TODO: months vs years ...
  const years = currYears.length > YEARS.length ? currYears : YEARS // <- ugly!
  return years.map((year) => ({
    x: year,
    y: d[year] ? d[year].freq : 0,
    count: d[year] ? d[year].count : 0,
    parties: d[year]?.party_id_count_dict || {},
    contexts: parseCounter(d[year]?.id_count_dict) || {},
  }))
}

function parseCounter(str = "{}") {
  const cleanedStr = str
    .replace(/^Counter\(/, "")
    .replace(/\)$/, "")
    .replace(/'/g, '"')
  // console.log(cleanedStr)
  const obj = JSON.parse(cleanedStr) || {}
  return obj
}

function mergeFrequencies(a, b, term) {
  const years = b.map((o) => o.x) || YEARS
  // console.log("mergeF", { a, b, term, years })
  const interval = b?.[0].interval || "year"
  return years.map((year) => ({
    x: year,
    y:
      (a.find((d) => d.x === year)?.y || 0) +
      (b.find((e) => e.x === year)?.y || 0),
    count:
      (a.find((d) => d.x === year)?.count || 0) +
      (b.find((e) => e.x === year)?.count || 0),
    term,
    interval,
    parties: mergeCounterObjs(
      a.find((d) => d.x === year)?.parties,
      b.find((e) => e.x === year)?.parties
    ),
    contexts: mergeCounterObjs(
      a.find((d) => d.x === year)?.contexts,
      b.find((e) => e.x === year)?.contexts
    ),
  }))
}

function mergeCounterObjs(a = {}, b = {}) {
  let initialVal = { ...a }
  return Object.keys(b).reduce((acc, key) => {
    return {
      ...acc,
      [key]: (acc[key] || 0) + b[key],
    }
  }, initialVal)
}
