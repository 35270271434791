import React, { useState, useEffect } from "react"
import { SiteLogoWrapper } from "./site-logo.style"
import { LangDef } from "../../hooks/langs"
import { is } from "@react-three/fiber/dist/declarations/src/core/utils"

const BP_MOBILE = 600

interface LogoDef {
  src: string
  url: string
  scale?: number
  srcMobile?: string
  filter?: string
  slogan?: string
}

interface SiteLogoProps {
  logos: LogoDef[]
  onClick?: () => void
  hideOnMobile?: boolean
  langId?: LangDef["id"]
  href?: string
}

export const SiteLogo = ({
  logos = [],
  onClick,
  hideOnMobile,
  langId = "de",
  href,
}: SiteLogoProps) => {
  const onLogoClick = (e) => {
    if (typeof onClick === "function") {
      e.preventDefault()
      onClick()
    }
  }
  const isMobile = useMobileState()
  const mobScale = isMobile ? 0.9 : 1
  return (
    <SiteLogoWrapper
      className={`start-page-logo-container ${
        hideOnMobile ? "mobile-hide" : ""
      }`}
    >
      {logos.map((logo, i) => {
        const logoUrl = logo.url[langId] || logo.url || href || ""
        const isInternal = logoUrl.match(/^\//) && logo.url !== ""
        return (
          <div
            key={i}
            className="start-page-logo-outer"
            // style={{ width: `${(logo.scale || 1) * 100}%` }}
          >
            <a
              href={`${logo.url[langId]}` || href}
              onClick={onLogoClick}
              target={isInternal ? "" : "_blank"}
              rel="noreferrer"
            >
              <img
                src={isMobile && logo.srcMobile ? logo.srcMobile : logo.src}
                className="start-page-logo"
                alt={"logo"}
                style={{
                  maxHeight: `${(logo.scale || 1) * mobScale * 32.5}px`,
                  filter: logo.filter,
                }}
              />
              {!!logo.slogan && (
                <span
                  className={`start-page-logo-slogan ${
                    logos.length > 1 && isMobile ? "mobile-hide" : ""
                  }`}
                >
                  {logo.slogan[langId]}
                </span>
              )}
            </a>
          </div>
        )
      })}
    </SiteLogoWrapper>
  )
}

export function useMobileState() {
  const [isMobile, setIsMobile] = useState(true)
  useEffect(() => {
    function checkMobile() {
      setIsMobile(window.innerWidth < BP_MOBILE)
    }
    checkMobile()
    window.addEventListener("resize", checkMobile)
    return () => window.removeEventListener("resize", checkMobile)
  }, [])
  return isMobile
}
