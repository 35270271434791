import styled from "styled-components"

const FullscreenWrapper = styled.div`
  width: 100%;
  height: ${p => p.headerHeight ? `calc(100vh - ${p.headerHeight}px)` : "100vh"};
  position: relative;

  // margin-top: ${p => p.headerHeight ? `${p.headerHeight}px` : "0"};

  @media print {
    height: auto;
  }
`

export default FullscreenWrapper
