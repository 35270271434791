import React, { useContext } from "react"
import TermSetter from "../term-setter"
import { removeBookmark } from "./bookmarks-reducer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark } from "@fortawesome/free-regular-svg-icons"
import { LangContext } from "gatsby-source-dek-wp"
import { TermBookmarksWrapper } from "./term-bookmarks.style"
// import BookmarkButton from "./bookmark-button"

import { BookmarksContext } from "../contexts/bookmarks"

const EXAMPLES_STR = {
  de: "Beispiele",
  en: "Examples",
  ru: "Примеры",
}
const TermBookmarks = () => {
  const lang = useContext(LangContext)
  const [bookmarkState, bookmarkDispatch] = useContext(BookmarksContext)
  const removeSavedTerm = (term) => {
    bookmarkDispatch(removeBookmark(term))
  }
  // <BookmarkButton />
  return (
    <TermBookmarksWrapper className="term-bookmarks">
      <TermSetter
        terms={bookmarkState}
        type="bookmarks"
        removeSavedTerm={removeSavedTerm}
        introStr={
          <>
            <FontAwesomeIcon icon={faBookmark} /> / {EXAMPLES_STR[lang.id]} →
          </>
        }
      />
    </TermBookmarksWrapper>
  )
}

export default TermBookmarks
