import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-regular-svg-icons"

const PlusMinusButton = ({ expanded }) => (
  <FontAwesomeIcon
    icon={expanded ? faMinusSquare : faPlusSquare}
    style={{
      opacity: 0.8,
      color: "inherit",
    }}
  />
)

export default PlusMinusButton
