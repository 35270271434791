import React, { useState, useEffect, createContext } from "react"

export const AuthContext = createContext(false)

// const LOCAL_STORAGE_KEY = "auth"

function shouldAuth(locationSearch = "") {
  // check url for #preview=true
  return !!locationSearch.match(/preview=true/)
}

declare global {
  interface Window {
    authMe: () => void
    unauthMe: () => void
  }
}

export function useAuth(locationSearch = "") {
  const [isAuthenticated, setIsAuthenticated] = useState(!!process.env.GATSBY_IS_PREVIEW) //  || shouldAuth(locationSearch))
  useEffect(() => {
    const storedAuth = false // window.localStorage.getItem(LOCAL_STORAGE_KEY) === "true"
    if (storedAuth) setIsAuthenticated(storedAuth)
    window.authMe = () => setIsAuthenticated(true)
    window.unauthMe = () => setIsAuthenticated(false)
  }, [])
  useEffect(() => {
    if (shouldAuth(locationSearch)) setIsAuthenticated(true)
  }, [locationSearch])
  useEffect(() => {
    // console.log("AUTH STATE", isAuthenticated)
    // window.localStorage.setItem(LOCAL_STORAGE_KEY, isAuthenticated)
  }, [isAuthenticated])
  return !!isAuthenticated
}


export function withAuth(Comp: React.ComponentType<any>) {
  return (props: Record<any, any>) => {
    const isAuthed = useAuth(props.location?.search)
    return (
      <AuthContext.Provider value={isAuthed}>
        <Comp {...props} />
      </AuthContext.Provider>
    )
  }
}
