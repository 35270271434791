import { useEffect, useState } from "react"
import { debounce } from "throttle-debounce"

export function useMinHeight(extraColumnHeight, setMinHeight) {
  useEffect(() => {
    setMinHeight(extraColumnHeight)
  }, [extraColumnHeight, setMinHeight])
}

export function useResize() {
  const [windowHeight, setWindowHeight] = useState()
  useEffect(() => {
    const onWindowResize = () => setWindowHeight(window.innerHeight)
    const debouncedOnWindowResize = debounce(200, onWindowResize)
    window.addEventListener("resize", debouncedOnWindowResize)
    return () => {
      window.removeEventListener("resize", debouncedOnWindowResize)
    }
  }, [])
  return windowHeight
}

export function useTopAdjust(refCollection, items, windowHeightState) {
  const [extraColumnHeight, setExtraColumnHeight] = useState(0)

  useEffect(() => {
    // adjust top offset to prevent overlapping
    const MARGIN = 20
    const UPSHIFT = 0.3 // transformY(-30%)
    const sortedRefs = refCollection.slice().sort((a, b) => {
      const posA = parseFloat(a.getAttribute("position")),
        posB = parseFloat(b.getAttribute("position")),
        keyA = a.getAttribute("orderkey"),
        keyB = b.getAttribute("orderkey")
      if (posA - posB !== 0) return posA - posB
      else {
        if (keyA > keyB) return -1
        else return 0
      }
    })

    sortedRefs.forEach((el, i, arr) => {
      const elTop = parseFloat(el.getAttribute("position"))
      const elHeight = el.scrollHeight
      // let newTop = !el.classList.contains('no-top-shift') ?
      let newTop = !el.children[0].classList.contains("no-top-shift")
        ? elTop - elHeight * UPSHIFT
        : elTop
      if (i > 0) {
        const prevEl = arr[i - 1]
        const prevElTop = prevEl.newTop // getOffsetTop(prevEl);
        const prevElHeight = prevEl.scrollHeight
        const minTopOffset = prevElTop + prevElHeight + MARGIN
        newTop = Math.max(minTopOffset, newTop)
      }
      el.style.top = `${newTop}px`
      el.newTop = newTop
      // if last element: set extra column height
      if (i === arr.length - 1) el.totalHeight = newTop + elHeight
    })

    const newMinHeight = sortedRefs.reduce((acc, curr) => {
      return curr.totalHeight || acc
    }, 0)
    setExtraColumnHeight(newMinHeight)
  }, [items, refCollection, windowHeightState])
  return extraColumnHeight
}
