import styled from "styled-components"

export const CustomOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  .term {
    .term-russian {
      font-size: ${(p) => p.theme.fontSizeSmall};
      color: gray;
      &::before {
        content: " (";
      }
      &::after {
        content: ") ";
      }
    }
  }
  .total-count {
    color: gray;
    justify-self: flex-end;
    text-align: right;
  }
  &.error {
    justify-content: center;
    .term {
      text-transform: none;
      color: red; // $title-color-solid;
    }
    .total-count {
      display: none;
    }
  }
`
