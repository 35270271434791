import React from "react"
import { Embed } from "./embed"
import innerText from "react-innertext"
import { FacebookResponsive } from "./facebook"
import { parse } from "json5"

interface TransfomerOptions {
  classList: string[]
  children: React.ReactNode
  style: Record<string, string>
}

declare global {
  interface Window {
    instgrm: any
  }
}

export function embedTransformer(
  node,
  i,
  { classList, children }: TransfomerOptions,
) {
  if (classList.includes("instagram-media")) {
    const props = {
      ...node.attribs,
      style: undefined,
      className: node.attribs.class,
    }
    delete props.class
    const url = props["data-instgrm-permalink"]
    const scriptUrl = "https://www.instagram.com/embed.js"
    function processEmbeds() {
      if (typeof window?.instgrm?.Embeds?.process !== "function") return
      // https://dev.to/ljcdev/embedding-an-instagram-post-in-your-website-3666
      window.instgrm.Embeds.process()
    }
    const [_children, caption] = separateFigcaptions(children)
    return (
      <Embed
        url={url}
        scriptUrl={scriptUrl}
        {...props}
        processEmbeds={processEmbeds}
        caption={caption}
      >
        <blockquote {...props}>{_children}</blockquote>
      </Embed>
    )
  } else if (classList.includes("wp-block-embed-twitter")) {
    //const inner = innerText(<>{children}</>) || ""
    const childs = (
      React.Children.toArray(children?.[0]?.props?.children) || []
    ).filter((c) => React.isValidElement(c)) as React.ReactElement[]
    const blockquote = childs.find((c) => c?.type === "blockquote")
    const blockChilds = blockquote?.props?.children || []
    const url = blockChilds.find((c) => c?.props?.href)?.props?.href
    const p = blockChilds.find((c) => c?.type === "p")
    const tweetText = p ? innerText(p) : ""
    const text =
      tweetText.length > 100 ? `${tweetText.slice(0, 100)}...` : tweetText
    const scriptUrl = "https://platform.twitter.com/widgets.js"
    return (
      <Embed scriptUrl={scriptUrl} text={text} url={url}>
        {children}
      </Embed>
    )
  } else if (
    node.name === "script" &&
    node.attribs?.src &&
    node.attribs?.["data-telegram-post"]
  ) {
    const [_, figcaption] = separateFigcaptions(children)
    const _children = <script {...node.attribs} />
    const url = `https://t.me/${node.attribs["data-telegram-post"]}`
    return (
      <Embed scriptUrl={node.attribs.src} url={url} caption={figcaption}>
        <blockquote>{_children}</blockquote>
      </Embed>
    )
  } else if (
    node.name === "iframe" &&
    node.attribs?.src &&
    node.attribs?.src.match(/https?:\/\/www\.facebook\.com\//)
  ) {
    const props = { ...node.attribs, style: {} }
    return <FacebookResponsive {...props} />
  } else if (node.name === "blockquote" && classList.includes("tiktok-embed")) {
    const props = { ...node.attribs, style: {} }
    return (
      <Embed
        url={node.attribs.cite}
        scriptUrl="https://www.tiktok.com/embed.js"
      >
        <blockquote {...props}>{children}</blockquote>
      </Embed>
    )
  } else if (
    node.type === "script" &&
    classList.includes("podigee-podcast-player")
  ) {
    const attribs = { ...node.attribs }
    delete attribs.class
    return (
      <Embed
        url={node.attribs["data-configuration"]}
        scriptUrl={node.attribs.src}
      >
        <div className={classList.join(" ")} {...attribs} />
      </Embed>
    )
  }
}

function separateFigcaptions(
  _children: React.ReactNode,
): [React.ReactNode[], React.ReactNode | undefined] {
  const childArr = React.Children.toArray(_children).filter((c) => React.isValidElement(c)) as React.ReactElement[]
  const figcaption = childArr.find((c) => c?.type === "figcaption")
  const children = childArr.filter((c) => c?.type !== "figcaption")
  return [children, figcaption]
}
