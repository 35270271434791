import styled from "styled-components"
import { breakpointMobile } from "../../../../shared/src/components/app/globals.module.scss"

export const Wrapper = styled.div`
  background: black;
  color: white;
  width: 100%;
  height: 100%;
  background: url(${p => p.image});
  background-blend-mode: darken;;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  &::after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0,  0.3));
    box-shadow: inset 0 0 150px rgba(0, 0, 0, 0.6);
    pointer-events: none;
  }
`

export const Title = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 6rem;
  line-height: 0.9em;
  width: 30%; // 30%
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  z-index: 2;
  @media screen and (max-width: ${breakpointMobile}) {
    font-size: 2.5rem;
  }
  .menu-open & {
    display: none;
  }
`
