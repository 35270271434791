import React from "react"
import { Subheading } from "./subheading"

export function subheadingTransformer(node, i, { children, classList }) {
  if (["h2", "h3"].includes(node.name) && !classList.includes("no-toc"))
    return (
      <Subheading key={i} type={node.name} id={node.attribs.id} classList={classList}>
        {children}
      </Subheading>
    )
}
