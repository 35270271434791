import styled from "styled-components"

export const Wrapper = styled.div`
  div {
    .source-logo-label {
      display: block;
      font-size: ${p => p.theme.fontSizeSuperSmall};
      text-transform: uppercase;
      font-family: "Roboto Condensed";
      color: #777;
      font-weight: 300;
      margin-bottom: 0.5em;
    }
    a,
    a:hover {
      display: block;
      border: none !important;
      img {
        max-width: 150px;
      }
    }
    a:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
  @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
    float: right;
    padding: 0 1em;
  }
  @media screen and (min-width: ${p => p.theme.breakpointMobile}) {
    position: relative;
    overflow: visible;
    height: 0px;
    max-width: none !important;
    margin: 0 !important;

    div {
      position: absolute;
      left: 1rem;
    }
  }
  @media screen and (min-width: ${p => p.theme.breakpointTablet}) {
    div {
      left: 3rem;
      a, a:hover {
        img {
          max-width: 200px;
        }
      }
    }
  }
`
