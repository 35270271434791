import React from "react"
import { Link } from "gatsby"

export const Linker = (props) => {
  const { to = "", target, children, ...cleanedProps } = props
  const isInternal = to.match(/^\//) && target !== "_blank"
  return isInternal ? (
    <Link to={to} {...cleanedProps}>
      {children}
    </Link>
  ) : (
    <a href={to} target="_blank" rel="noreferrer" {...cleanedProps}>
      {children}
    </a>
  )
}
