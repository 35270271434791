import React, { useContext } from "react"
import { components } from "react-select"
import { useDrag, useDrop } from "react-dnd"
import { mergeTerms } from "../term-reducer"
import Tooltip from "@mui/material/Tooltip"
import { LangContext } from "gatsby-source-dek-wp"
import { DndLabelWrapper } from "./dnd-label.style"

import { useTranscribe } from "../transcribe"
import { TranslateContext } from "../contexts/translate"
import { useIsMobile } from "../utils"

const TERM_SEARCH_DND = "termSearchDnd"

const MultiValueDnd = (props) => {
  const lang = useContext(LangContext)
  const transcribe = useTranscribe()
  const translateEnabled = useContext(TranslateContext)
  const termDispatch = props.dispatch
  const term = props.data.value
  const [, dragRef] = useDrag(() => ({
    type: TERM_SEARCH_DND,
    item: { id: term },
  }))
  const isMobile = useIsMobile()
  const [{ canDrop, isOver, dragItem }, dropRef] = useDrop(() => ({
    accept: TERM_SEARCH_DND,
    drop: (item) => {
      if (item.id === term) return // no self-drop
      // alert(`DROPPED ${item.id} ON ${term}`)
      termDispatch(mergeTerms(term, item.id))
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dragItem: monitor.getItem(),
    }),
  }))
  const activeHover = isOver && canDrop && term !== dragItem.id
  const innerProps = { onMouseDown: (e) => e.stopPropagation() }

  const LangId = `${lang.id[0].toUpperCase()}${lang.id.slice(1)}`

  const showValue = translateEnabled
    ? props.data[`translation${LangId}`] || transcribe(props.data.value, { to: "de"}) // .label
    : props.data.value

  return (
    <Tooltip
      title={`${props.data.value.toUpperCase()} (${props.data.totalCount.toLocaleString(
        lang.locale
      )})`}
      arrow
    >
      <DndLabelWrapper
        ref={dropRef}
        className={`dnd-label${activeHover ? " drop-hover" : ""}`}
      >
        <div ref={isMobile ? null : dragRef} className="dnd-label-inner">
          <components.MultiValue {...props} innerProps={innerProps}>
            {showValue}
            <span className="label-total-count">
              {" "}
              ({props.data.totalCount.toLocaleString(lang.locale)})
            </span>
          </components.MultiValue>
        </div>
      </DndLabelWrapper>
    </Tooltip>
  )
}

export default MultiValueDnd
