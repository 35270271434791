import React from "react"
import Quiz from "./quiz"
import QuizItem from "./quiz-item"
import QuizOption from "./quiz-option"
import { QuizFeedbackTemplate } from "./quiz-feedback"

function quizTransformer(node, i, { classList, children }) {
  if (classList.includes("wp-block-dekoder-custom-blocks-quiz-wrapper")) {
    const items = children.filter((c) => c.type === QuizItem)
    const feedbacks = children.filter((c) => c.type === QuizFeedbackTemplate)
    return <Quiz {...{ items, feedbacks }} />
  } else if (classList.includes("wp-block-dekoder-custom-blocks-quiz-option")) {
    return (
      <QuizOption
        correct={node.attribs.correct === "true"}
        text={node.attribs.option}
      />
    )
  } else if (classList.includes("wp-block-dekoder-custom-blocks-quiz-item")) {
    const question = children.find(
      (c) => c.props.className === "quiz-item-question"
    )
    const options = children.filter((c) => c.type === QuizOption)
    const img = children.find((c) => c.type === "figure")
    const explanation = children.find((c) => c.type === "div")
    return (
      <QuizItem
        question={question}
        options={options}
        img={img}
        explanation={explanation}
      />
    )
  } else if (
    classList.includes("wp-block-dekoder-custom-blocks-quiz-feedback")
  ) {
    const minCorrect = node.attribs.mincorrect * 1
    const feedback = node.attribs.feedback
    const figComp = children.find((c) => c.type === "figure")
    const img = figComp ? figComp.props.children[0].props.src : ""
    return <QuizFeedbackTemplate {...{ minCorrect, feedback, img }} />
  }
}

export default quizTransformer
