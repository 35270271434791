import React, { useContext } from "react"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { Wrapper } from "./switch.style"
import { ThemeContext } from "styled-components"

const TranslateSwitch = ({ onChange, enabled, label }) => {
  const labelRight = false
  const { themeColor } = useContext(ThemeContext) || "#129fc9"
  return (
    <Wrapper className="switch" themeColor={themeColor}>
      <FormControlLabel
        className="switch-label"
        control={
          <Switch
            checked={enabled}
            onChange={onChange}
            // onTouchStart={(e) => console.log(e)}
          />
        }
        label={labelRight ? `← ${label}` : `${label} →`}
        labelPlacement={labelRight ? "end" : "start"}
      />
    </Wrapper>
  )
}

export default TranslateSwitch
