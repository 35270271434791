import { useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import { v4 as uuidv4 } from "uuid"

export function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}

export function useInViewClass(className, options = {}) {
  const [ref, inView] = useInView(options)
  useBodyClass(inView ? className : "")
  return ref
}

export function useUid() {
  return useMemo(uuidv4, [])
}
