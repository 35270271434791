import styled from "styled-components"

export const tfToolBgColor = "rgb(244, 244, 244)"

export const TfToolWrapper = styled.div`
  position: relative;

  &.primary {
    width: 100%;
    position: relative;
    background-color: ${tfToolBgColor};
    padding: 0;
    padding: ${p => p.theme.toolSidePadding} 0;
    // > * { border: 1px dashed green; }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 5px;
    }

    .compressor.collapsed:not(.relative) & {
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        .term-frequency-tool-upper {
          display: none;
        }
        .term-search-wrapper {
          margin-top: 0;
        }
        .term-bookmarks {
          display: none;
        }
      }
    }
  }

  .dark-mode & {
    background-color: ${(p) => p.theme.bodyColor }; // bgDark // bodyColor
    color: ${(p) => p.theme.textColorLight || "white"};
  }

  .term-frequency-tool-upper {
    display: flex;
    padding: 0 ${(p) => p.theme.toolSidePadding};
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0 ${(p) => p.theme.toolSidePaddingMobile};
    }
    span:not(.MuiSwitch-thumb) {
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      text-transform: uppercase;
      color: inherit; // rgb(40, 40, 40);
    }
  }

  .term-frequency-tool-lower {
    display: flex;
    padding: 0 ${(p) => p.theme.toolSidePadding};
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0 ${(p) => p.theme.toolSidePaddingMobile};
      margin-top: 1.4em;
    }
    position: relative;
    margin-bottom: 1em;
    .compressor.fixed & {
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        margin-top: 0;
      }
    }
    .compressor.fixed:not(.expanded) & {
      margin-bottom: 0;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        max-height: 0;
      }
    }

    .translation-credit {
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;
      display: none;

      .translation-active & {
        display: block;
        opacity: 1;
        pointer-events: inherit;
      }

      & > span {
        @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
          &:first-child::after {
            content: " | ";
            margin: 0 0.2em;
          }
        }
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          display: block;
        }
      }

      .compressor.fixed:not(.expanded) & {
        display: none;
        max-height: 0px;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
    }

    .error-message {
      color: red; //  $title-color-solid;
      margin-bottom: 1em;
    }

    .legend-y {
      text-align: right;
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      max-height: none;
      & > span {
        &:first-child {
          margin-right: 0.2em;
        }
        .translation-active & {
          @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
            display: block;
            &:first-child {
              margin-right: 0;
            }
          }
        }
      }
      .compressor.fixed:not(.expanded) & {
        max-height: 0px;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
    }
  }
`
