import styled from "styled-components"

export const ChartWrapper = styled.div`
  position: relative;
  // padding-top: 56.25%; // 16.9
  padding-top: 50%;

  .chart-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
