import React, { createContext, useCallback, useRef, useState } from "react"
import "./app.scss"
import useMenu from "../menu"
import CardDeck from "../card-deck"
import Article from "../article"
import useSlides, { SlidesContext } from "./slides"
import {
  useAllContents,
  useContents,
  usePrevNext,
  useSpecialTeaser,
  ContentsContext,
  useLangs,
  LangContext,
  withAuth,
  SiteLogo,
  LangSwitcher,
  Header,
  useThemeColor,
  Seo
} from "gatsby-source-dek-wp"
import Footer from "../footer/footer"
import DonationEncourager from "donation-encourager"
import useDonationEncourager from "./donation-encourager"
import SiteTitle from "../site-title/site-title"
import BackArrow from "../back-arrow/back-arrow"
import { BgWrapper } from "../bg-wrapper/bg-wrapper"
import { ThemeProvider } from "styled-components"
import { theme } from "./theme.style"
import { GlobalStyle, MainWrapper } from "./app.style"
import { useTransformer } from "../../modules/transformer"
import Credit from "../bg-wrapper/credit"

export const MetaContext = createContext()

const App = ({ data, pageContext, metaData, children }) => {
  const { slug, langId } = pageContext
  const {
    siteLogos,
    categories,
    backgroundImage,
    bgNotFixed,
    bgBlendColor,
    bgPosition,
    bgCredit,
    titleFont,
    categoryIds
  } = metaData
  const allConts = useAllContents(data, false, categoryIds)
  const [lang, allLangs] = useLangs(langId, allConts)
  const specialTeaser = useSpecialTeaser(data, lang.id)
  const { CustomTile, tileSize, maxPerRow, flexHook } = metaData
  const [contents, selected] = useContents(allConts, lang.id, slug, categories)
  const [prev, next] = usePrevNext(contents, selected)
  const slides = useSlides(contents, selected, CustomTile)
  const { title, subtitle } = metaData.siteMetaInfo[lang.id]
  const titleComp = <SiteTitle {...{ title, subtitle, titleFont }} center={true} />
  const [menu, menuButton, setMenuOpen] = useMenu(contents, selected, titleComp)
  const onHeaderExpand = useCallback(() => setMenuOpen(false), [setMenuOpen])
  const deckRef = useRef()
  const articleProps = { pageContext, prev, next, deckRef, selected }
  useDonationEncourager(langId)

  const [expanded, setExpanded] = useState(false)

  const credit = useTransformer(bgCredit ? bgCredit[lang.id] : "")

  const themeColorComp = useThemeColor(selected?.themeColor, theme)


  return (
    <MetaContext.Provider value={metaData}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {themeColorComp}
        <ContentsContext.Provider value={contents}>
          <LangContext.Provider value={lang}>
            <SlidesContext.Provider value={slides}>
              <Seo selected={pageContext} metaData={metaData} />
              <Header 
                expanded={expanded} 
                onExpand={onHeaderExpand} 
                contents={specialTeaser}
              >
                {!!selected && (
                  <BackArrow href={`/${lang.id}`} show={!!selected} />
                )}
                {!selected && (
                  <SiteLogo
                    logos={siteLogos}
                    onClick={() => setExpanded((e) => !e)}
                    // hideOnMobile={true}
                  />
                )}
                <div style={{ display: "flex" }}>
                  <LangSwitcher allLangs={allLangs} selected={selected} />
                  {menuButton}
                </div>
              </Header>
              {menu}
              <MainWrapper
                onClick={expanded ? () => setExpanded(false) : null}
                menuExpanded={expanded}
              >
                {children}
                {!!selected && (
                  <Article {...articleProps} metaData={metaData} />
                )}
                <BgWrapper
                  backgroundImage={backgroundImage}
                  bgNotFixed={bgNotFixed}
                  bgBlendColor={bgBlendColor}
                  bgPosition={bgPosition}
                >
                  {!!credit && <Credit>{credit}</Credit>}
                  <CardDeck
                    ref={deckRef}
                    cards={slides}
                    tileSize={tileSize}
                    maxPerRow={maxPerRow}
                    flexHook={flexHook}
                  >
                    {titleComp}
                  </CardDeck>
                </BgWrapper>
              </MainWrapper>
              <Footer metaData={metaData} />
            </SlidesContext.Provider>
          </LangContext.Provider>
        </ContentsContext.Provider>
      </ThemeProvider>
    </MetaContext.Provider>
  )
}

export default withAuth(App)

// <DonationEncourager />
