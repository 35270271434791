import styled from "styled-components"
import { breakpointMobile, breakpointTablet } from "../app/globals.module.scss"

export const CardDeckWrapper = styled.div`
  // overflow: hidden;
  width: 100%;


  @media print {
    display: none;
  }

  max-width: 1400px;
  margin: 0 auto;
`

export const CardDeckInner = styled.div`
  user-select: none;
  // border: 1px dashed green;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding-top: 3em;
  @media screen and (max-width: ${breakpointMobile}) {
    padding-top: 2em;
  }
  body.menu-open & {
    opacity: 0;
  }
`

export const CardWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 200px;
  // height: 200px;
  pointer-events: none;
  /* border: 1px solid red; */
  transition: 0.7s ease-in-out;
  opacity: ${(p) => (p.hidden || p.filtered ? 0 : 1)};

  // border: 1px dashed red;
`
