import styled from "styled-components"

export const ShareBarWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      display: none;
    }
  }
  .share-bar-buttons {
    button {
      margin-right: 0.1em;
      width: 2em;
      height: 2em;
      svg {
        color: inherit;
        transition: 0.3s ease;
      }
      &:hover {
        svg {
          color: ${(p) => p.theme.themeColor || "rgb(245, 0, 87)"};
        }
      }
    }
  }
`
