import React from "react"
import Footnote from "./footnote"
import FootnoteLink from "./footnote-link"
import { Transformer } from "../../types"
import innerText from "react-innertext"
import { scrollTo } from "./footnote"

export const footnoteTransformer: Transformer = (
  node,
  i,
  { classList, children },
) => {
  if (classList.includes("wp-block-dekoder-custom-blocks-footnote")) {
    const id = parseInt(node?.attribs?.id)
    return <Footnote footnoteId={id}>{children}</Footnote>
  } else if (classList.includes("wp-block-footnotes")) {
    return (
      <ol className="wp-block-footnotes">
        {React.Children.toArray(children).map((c, i) => (
          <Footnote footnoteId={c.props.id} number={i + 1}>
            <p>{patchFnLink(c.props.children)}</p>
          </Footnote>
        ))}
      </ol>
    )
  } else if (node.name === "sup") {
    const child = React.Children.toArray(children)[0]
    const id =
      typeof child === "number"
        ? child
        : (child?.props?.href || "").replace("#", "")
    const inner = innerText(child)
    return <FootnoteLink footnoteId={id}>{inner}</FootnoteLink>
  }
}

function patchFnLink(children) {
  return children.map((c) => {
    if (/^#[a-z0-9-_]+\-link/.test(c?.props?.href || "")) {
      const patchedHref = c.props.href.replace("-link", "").replace("#", "#footnote-link-")
      return <button className="goto-footnote-link" onClick={() => scrollTo(patchedHref)}>{c.props.children}</button>
    } else return c
  })
}
