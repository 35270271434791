import { useContext, useEffect } from "react"
import { add, remove, AnnotationsContext } from "./annotations"

const Annotation = ({ id, children }) => {
  const dispatch = useContext(AnnotationsContext)[1]
  useEffect(() => {
    dispatch(add(id, children))
    return () => dispatch(remove(id))
  }, [id, children, dispatch])
  return null
}

export default Annotation
