import React, { useContext } from "react"
import "./slide-projector.scss"
import { useInViewClass } from "../../modules/hooks"
import { LangContext } from "gatsby-source-dek-wp"
import { useKeyNav, useDragNav } from "./navigation"
import useSlideChange from "./slide-change"
import SlideImage from "../slide-image/slide-image"
import SlideProjectorRemote from "../remote-control/remote-control"
import FullscreenWrapper from "../fullscreen-wrapper"

const SlideProjector = (props) => {
  const {
    slide = {},
    prev,
    next,
    noSound,
    onSelect,
    metaData,
  } = props
  const lang = useContext(LangContext)
  const ref = useInViewClass("on-slide-projector", { threshold: 0 })
  useKeyNav(prev, next)
  const bind = useDragNav(prev, next)
  const [currSlide, visibility, onLeave] = useSlideChange(slide, noSound)
  const { title, subtitle, image } = currSlide
  const { shown, focussed } = visibility
  /* const onArrowBackClick = () => {
    onLeave()
    setTimeout(() => navigate(`/${lang.id}`), 700)
  } */
  return (
    <FullscreenWrapper>
      <div className={`slide-projector`} style={{marginTop: "-50px"}}>
        <div className={`slide-projector-image-outer`} ref={ref}>
          <div
            {...bind()}
            className={`slide-projector-image-inner
            ${shown ? "shown" : "hidden"} 
            ${focussed ? "focussed" : "blurred"}`}
          >
            <SlideImage image={image} title={title} projected />
          </div>
          <div className="slide-projector-title-wrapper">
            <div className={`slide-projector-title`}>{title}</div>
            <div className={`slide-projector-subtitle`}>{subtitle}</div>
          </div>
        </div>
        <SlideProjectorRemote {...{ prev, next, onSelect, metaData }} />
      </div>
    </FullscreenWrapper>
  )
}

export default SlideProjector
