import React, { useContext } from "react"
import { Link } from "gatsby"
import SlideImage from "../slide-image/slide-image"
import {
  ViewButton,
  FlipButton,
  SlideFrame,
  SlideTitle,
  SlideTeaser,
} from "./slide.style"
import { SlideWrapper, SlideImgWrapper, SlideGripWrapper } from "./slide.style"
import "./fonts.scss"
import { LangContext } from "gatsby-source-dek-wp"
import chroma from "chroma-js"
import Banderole from "./banderole"

import slideFramePaper1 from "./slide-frame-paper1.png"
import slideFramePaper2 from "./slide-frame-paper2.png"
import slideFramePlastic from "./slide-frame-plastic.png"
import { DragBindContext, MovingContext } from "../card-deck/card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import { replaceUmlauts } from "../../modules/replace-umlauts"

const slideFrames = [
  {
    type: "paper1",
    src: slideFramePaper1,
    saturate: 0.6,
    mirror: true,
  },
  {
    type: "paper2",
    src: slideFramePaper2,
    srcBack: slideFramePaper1,
    saturate: 0.6,
  },
  {
    type: "paper1",
    src: slideFramePaper1,
    saturate: 0.5,
  },
  {
    type: "paper2",
    src: slideFramePaper2,
    srcBack: slideFramePaper1,
    mirror: true,
    saturate: 0.5,
  },
  {
    type: "plastic",
    src: slideFramePlastic,
  },
]

const Slide = ({
  imageSrc,
  title,
  teaser,
  flip,
  href,
  category = {},
  flipped,
  unpublished,
}) => {
  const lang = useContext(LangContext)
  const moving = useContext(MovingContext)
  const bind = useContext(DragBindContext) || (() => ({}))
  const color = chroma(category.color || "black")
    .darken()
    .hex()
  const sf = slideFrames[4] // i %
  const displayTitle = replaceUmlauts(title)
  const displayTeaser = replaceUmlauts(teaser)
  const onClick = (e) => {
    // console.log("CLICKED. moving?", moving)
    if (moving) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  return (
    <SlideWrapper
      className={`slide-wrapper ${sf.type} ${flipped ? "flipped" : ""}`}
    >
      {!!unpublished && <Banderole>unpublished</Banderole>}
      <SlideGripWrapper {...bind()}>
        <FontAwesomeIcon icon={faGripVertical} />
      </SlideGripWrapper>
      <SlideImgWrapper>
        <Link to={href} onClick={onClick}>
          <SlideImage image={imageSrc} title={title} />
        </Link>
      </SlideImgWrapper>
      <SlideFrame {...sf} />
      <SlideTitle langId={lang.id} style={{ color }}>
        <Link to={href} onClick={onClick}>
          {displayTitle}
        </Link>
      </SlideTitle>
      <ViewButton href={href} onClick={onClick} />
      <FlipButton onClick={flip} />
      <SlideTeaser>{displayTeaser}</SlideTeaser>
    </SlideWrapper>
  )
}

export default Slide
