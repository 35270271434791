import styled from "styled-components"
import { fontSizeSuperSmall } from "../app/globals.module.scss"

const Credit = styled.div`
  position: fixed; // absolute;
  display: none;
  body.on-deck & {
    display: block;
  }
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  color: rgb(220, 220, 220);
  font-size: ${fontSizeSuperSmall};
  padding: 0.1em 0.5em;
  a,
  a:visited {
    color: rgb(220, 220, 220) !important;
  }
`

export default Credit
