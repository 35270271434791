import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faTwitter,
  faGetPocket,
} from "@fortawesome/free-brands-svg-icons"
import { SocialBarWrapper } from "./social-bar.style"

export const SocialBar = () => {
  const [displayUrl, setDisplayUrl] = useState("")
  useEffect(() => {
    if (typeof window === "undefined") return
    setDisplayUrl(window.location.href)
  }, [])
  return (
    <SocialBarWrapper className="ph-social-bar">
      <a
        className="ph-share-bar-widget ph-share-bar-widget-facebook"
        href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(displayUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a
        className="ph-share-bar-widget ph-share-bar-widget-twitter"
        href={`http://twitter.com/intent/tweet?text=${encodeURIComponent(displayUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        className="ph-share-bar-widget ph-share-bar-widget-get-pocket"
        href={`https://getpocket.com/save?url=${encodeURIComponent(displayUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGetPocket} />
      </a>
      <a
        className="ph-share-bar-widget ph-share-bar-widget-email"
        href={`mailto:?subject=Toller%20Artikel%20auf%20dekoder.org&body=Dieser%20Artikel%20interessiert%20dich%20bestimmt%3A%0D%0A${displayUrl}`}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </SocialBarWrapper>
  )
}
