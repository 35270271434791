import { useState, useEffect, useMemo, useCallback, cloneElement } from "react"

export default function useFlip(set, children) {
  const [flipped, setFlipped] = useState(false)
  useEffect(() => {
    set({ yRot: flipped ? 180 : 0 })
  }, [flipped, set])
  const flip = useCallback(() => setFlipped((f) => !f), [])
  const child = useMemo(() => {
    if (!children) return
    return cloneElement(children, { flip, flipped })
  }, [children, flip, flipped])
  return [flip, child]
}
