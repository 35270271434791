import { useContext } from "react"
import { TranslateContext } from "./contexts/translate"
import { transcribe } from "transkribator"
import { LangContext } from "gatsby-source-dek-wp"

export const useTranscribe = () => {
  const translateEnabled = useContext(TranslateContext)
  const lang = useContext(LangContext)
  return translateEnabled
    ? (word: string) => transcribe(word, { to: lang.id })
    : (word: string) => word
}
