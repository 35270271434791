import React, { useContext } from "react"
import styled from "styled-components"
import { AnnotationsContext } from "../annotation/annotations"
import { useTransformer } from "../../../../src/modules/transformer" // TODO !!
import { Linker, LangContext } from "gatsby-source-dek-wp"

const LINK_TEXT_INTERNAL = {
  de: "Lesen ...", 
  en: "Read ...",
  ru: "Читать ..."
}

const LINK_TEXT_DEK = {
  de: "Mehr dazu auf dekoder.org", 
  en: "Read more at dekoder.org",
  ru: "Читать на dekoder.org"
}

function readMoreLinkText(langId, type = "dekoder" /* "internal-link" */) {
  return type === "internal-link"
    ? LINK_TEXT_INTERNAL[langId] || LINK_TEXT_INTERNAL["de"]
    : LINK_TEXT_DEK[langId] || LINK_TEXT_DEK["de"]
}

const PopupWrapper = styled.div`
  border-left: none !important;
  .dekoder-read-more-link {
    font-weight: 700;
  }
  > p {
    margin: 0;
  }
  .wp-block-image {
    margin: 0 !important;
    padding: 0 !important;
  }
`

export const DekoderPopup = ({ type, title, text, url, target = "" }) => {
  const popupText = useTransformer(text)
  const lang = useContext(LangContext)
  return (
    <PopupWrapper>
      {!!title && type !== "blurb" && <><strong>{title}</strong><br/></>}
      {!!popupText && popupText}
      {!!url && type !== "blurb" && <>{" "}→ <Linker
        to={url}
        className={`link dekoder-read-more-link ${type}`}
      >
        {readMoreLinkText(lang.id, type)}
      </Linker></>}
    </PopupWrapper>
  )
}

export const AnnotationPopup = ({ annotationId, annotation }) => {
  const annotations = useContext(AnnotationsContext)[0]
  if (annotation) return <PopupWrapper>{annotation}</PopupWrapper>
  if (!annotationId) return null
  const annotationItem = annotations.find((a) => a.id === annotationId)
  if (!annotationItem) return null
  const content = annotationItem.content
  return <PopupWrapper>{content}</PopupWrapper>
}
