import React from "react"
import { components } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { CustomValueContainerWrapper } from "./custom-value-container.style"

const CustomValueContainer = (props) => {
  return (
    <CustomValueContainerWrapper className="custom-value-container">
      <FontAwesomeIcon icon={faSearch} />
      <components.ValueContainer
        {...props}
        className="custom-value-container-inner"
      />
    </CustomValueContainerWrapper>
  )
}

export default CustomValueContainer
