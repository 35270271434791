import { useEffect } from "react"

export function useBodyClass(className: string) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}
