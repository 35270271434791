import styled from "styled-components"

export const CustomValueContainerWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  > svg {
    margin: 0 -0.1em 0 0.6em;
    font-size: ${(p) => p.theme.fontSizeSmall};
    color: inherit; //  hsl(0,0%,40%);

    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      margin: 0 0.5em;
    }
  }

  .custom-value-container-inner {
    overflow: visible;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      // overflow: auto;
      flex-wrap: nowrap;
      padding: 0;
    }
  }
`
