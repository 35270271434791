import styled from "styled-components"

export const EmbedWrapper = styled.div`
  > div > div {
    position: relative;
  }
  &.wp-embed-aspect-16-9 > div > div {
    padding-bottom: 56.25%;
  }
  &.wp-embed-aspect-4-3 > div > div {
    padding-bottom: 75%; /* 4:3 */
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
 > div > figcaption {
    margin-top: 0.5em;
    font-weight: 300;
    font-size: ${(p) => p.theme.fontSizeSmall};
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 0 1em;
    }
  }
  &.bg-dark, &.bg-light {
    max-width: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 3rem 0;
    > div {
      max-width: 720px; // TODO: theme.
      margin: 0 auto;
      > div {
        position: relative;
        box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.6);
      }
      > figcaption {
        margin-top: 2em;
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding: 1em;
    }
  }
  &.bg-dark {
    background-color: ${(p) => p.theme.bgDark};
    > div > figcaption {
      color: rgb(240, 240, 240);
    }
  }
  &.bg-light {
    background-color: rgba(240, 240, 240, 1);
  }
`
