import styled from "styled-components"
import { tfToolBgColor } from "../term-freq-tool.style"

export const TermSetterWrapper = styled.span`
  position: relative;
  display: block;
  .scroll-button {
    display: none;
  }
  &.inline {
    display: inline; // -block;
  }
  &.bookmarks {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;

    // max-width: 850px !important;
    max-width: 100%;

    .term-setter-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      overflow-x: scroll;
      overflow-y: hidden;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      // justify-content: center;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        flex-wrap: nowrap;
      }
      .compressor.fixed & {
        flex-wrap: nowrap;
      }
    }
    .term-setter-intro-text {
      text-transform: uppercase;
      letter-spacing: 2px;
      // font-size: $font-size-small;
      font-size: ${(p) => p.theme.fontSizeSuperSmall};
      margin-right: 1em;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        // margin-left: .4em;
        display: none;
      }
      /* .compressor & {
        display: none;
      }*/
      svg {
        margin-right: 0.2em;
      }
    }
    .scroll-button {
      display: block;
      position: sticky;
      background-color: ${(p) =>
        p.theme.darkMode ? p.theme.bodyColor : tfToolBgColor};
      height: 100%;
      padding: 2px 2px;
      transition: 0.2s ease-out;
      box-shadow:
        0px -5px 0 ${(p) => (p.theme.darkMode ? p.theme.bodyColor : tfToolBgColor)},
        0px 5px 0
          ${(p) => (p.theme.darkMode ? p.theme.bodyColor : tfToolBgColor)};
      @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
        display: none;
        .compressor.fixed & {
          display: block;
        }
      }
      &.left {
        left: 0;
        padding-right: 5px;
        &::after {
          width: 20px;
          display: block;
          content: "";
          position: absolute;
          top: -5px;
          left: 100%;
          height: calc(100% + 10px);
          background-image: linear-gradient(
            to right,
            ${(p) => (p.theme.darkMode ? p.theme.bodyColor : "white")},
            rgba(255, 255, 255, 0)
          );
        }
      }
      &.right {
        right: 0;
        padding-left: 5px;
        &::before {
          width: 20px;
          display: block;
          content: "";
          position: absolute;
          top: -5px;
          left: -20px;
          height: calc(100% + 10px);
          background-image: linear-gradient(
            to left,
            ${(p) => (p.theme.darkMode ? p.theme.bodyColor : "white")},
            rgba(255, 255, 255, 0)
          );
        }
      }
    }
    &.scroll-start .scroll-button.left {
      opacity: 0.1;
      pointer-events: none;
    }

    &.scroll-end .scroll-button.right {
      opacity: 0.1;
      pointer-events: none;
    }
    &.scroll-no-scroll .scroll-button {
      opacity: 0;
      pointer-events: none;
    }
  }
  &.trigger {
    padding-left: 90px;
    margin-top: 2em;
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      margin-top: 3em;
    }
    margin-bottom: 1em;
    > .term-setter-buttons {
      width: 180px;
      // overflow: hidden;
      position: absolute;
      top: 0;
      left: -100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      cursor: pointer;
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
      .term-setter-buttons {
        width: 160px;
        left: -75px;
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      padding-left: ${(p) => p.theme.sidePaddingMobile};
      .term-setter-buttons {
        position: relative;
        width: 100%;
        left: auto;
        justify-content: flex-start;
      }
    }
  }
`
