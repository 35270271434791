import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import Wrapper from "./back-arrow.style"

const BackArrow = ({ href, onClick, show = true }) => {
  const onArrowClick =
    typeof onClick === "function"
      ? (e) => {
          e.preventDefault()
          onClick()
        }
      : null
  return (
    <Wrapper hide={!show} >
      <Link to={href} onClick={onArrowClick}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Link>
    </Wrapper>
  )
}

export default BackArrow
