import styled from "styled-components"

export const TermSearchWrapper = styled.div`
  padding: 0 ${(p) => p.theme.toolSidePadding};
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    padding: 0 ${(p) => p.theme.toolSidePaddingMobile};
  }
  position: relative;
  margin-bottom: 1em;
  margin-top: 0.75em;

  > span.hint {
    // hint
    /* position: absolute;

    z-index: 2;
    top: -8px;
    left: -.5em;
    transform: translateY(-50%);*/
    font-size: ${(p) => p.theme.fontSizeSmall};
    color: hsl(0, 0%, 70%);
    // @media screen and (max-width: $breakpoint-mobile) {
    position: absolute;
    top: calc(100% + 7px);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - 10px);
    text-align: center;
    font-size: ${(p) => p.theme.fontSizeSuperSmall};
    // }

    opacity: 0;
    white-space: nowrap;
    transition: opacity 1s ease;

    &.active {
      opacity: 1;
    }

    /* &.hint-trans-enter-done {
      opacity: 1;
    }
    &.hint-trans-exit-done {
      opacity: 0;
    } */

    .compressor.fixed.collapsed & {
      display: none;
    }
  }

  .term-search {
    width: 100%;
  }

  .rs-term-search__indicators {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      display: none !important;
    }
  }
`
