import React, { Fragment, useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import MenuButton from "./menu-button"
import { useBodyClass } from "../../modules/hooks"
import { LangContext } from "gatsby-source-dek-wp"
import "./menu.scss"

const MenuSection = ({ pages, allSubPages, selected = {} }) => (
  <>
    {pages.map((p, i) => {
      const subPages = allSubPages.filter(
        (sp) =>
          String(sp.id).split(".").length &&
          String(sp.id).split(".")[0] === String(p.id)
      )
      const isSelected = selected.slug === p.slug
      const teaser = p.teaserStartPage
      return (
        <Fragment key={p.slug || i}>
          <li
            className={`menu-item${p.comingSoon ? " unpublished" : ""} ${
              isSelected ? "selected" : ""
            }`}
          >
            <Link to={`/${p.slug}`}>
              <div className="menu-item-title">{p.menuTitle || p.title}</div>
              {!!teaser && <div className="menu-item-teaser">{teaser}</div>}
            </Link>
          </li>
          {!!subPages.length && (
            <span className="sub-items">
              {subPages.map((sp, j) => (
                <li
                  key={sp.slug || `${i}-${j}`}
                  className={`menu-item sub-item${
                    sp.comingSoon ? " unpublished" : ""
                  }${selected.slug === sp.slug ? " selected" : ""}`}
                >
                  <Link to={`/${sp.slug}`}>
                    <div>{sp.menuTitle || sp.title}</div>
                  </Link>
                </li>
              ))}
            </span>
          )}
        </Fragment>
      )
    })}
  </>
)

const Menu = ({ contents, selected, titleComp }) => {
  const lang = useContext(LangContext)
  const pages = contents.filter(c => c.pageType !== "about")
  const abouts = contents.filter(c => c.pageType === "about")
  return (
    <div className="menu">
      <div className="menu-outer">
        <div className="menu-inner">
          <Link to={`/${lang.id}`}>
            <div className="menu-site-title">
              {titleComp}
            </div>
          </Link>
          <ul className="menu-list">
            {[pages, abouts].map((list, i) => (
              <MenuSection
                key={i}
                pages={list}
                allSubPages={[]}
                selected={selected}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

function useMenu(contents, selected, titleComp) {
  const [menuOpen, setMenuOpen] = useState(false)
  useBodyClass(menuOpen ? "menu-open" : "")
  useEffect(() => {
    setMenuOpen(false)
  }, [selected])
  const menuComponent = (
    <Menu
      contents={contents}
      selected={selected}
      titleComp={titleComp}
    />
  )
  const menuButtonComponent = (
    <MenuButton menuOpen={menuOpen} onClick={() => setMenuOpen((o) => !o)} />
  )
  return [menuComponent, menuButtonComponent, setMenuOpen]
}

export default useMenu
