import React, { useState, useEffect, createContext } from "react"

export const WebpContext = createContext(false)
export const NO_WEBP = "original=true"

export function withWebpCheck(Comp) {
  return (props) => {
    const noWebpSupport = useNoWebpSupport()
    return (
      <WebpContext.Provider value={noWebpSupport}>
        <Comp {...props} />
      </WebpContext.Provider>
    )
  }
}

function useNoWebpSupport() {
  const [noWebpSupport, setNoWebpSupport] = useState(false)
  useEffect(() => {
    checkWebp()
    async function checkWebp() {
      const result = await canUseWebp()
      if (!result) setNoWebpSupport(true)
    }
  }, [])
  return noWebpSupport
}

function canUseWebp() {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src =
      "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAQAAAAfQ//73v/+BiOh/AAA="
    img.onload = (ev) => resolve(true)
    img.onerror = (ev) => resolve(false)
  })
}