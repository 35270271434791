import { useState, useEffect } from "react"
import debounce from "debounce"

export default function useContainerWidth() {
  const [containerWidth, setContainerWidth] = useState(1000)
  const [refEl, setRefEl] = useState(null)

  useEffect(() => {
    function resize() {
      if (!refEl) return
      setContainerWidth(refEl.offsetWidth)
      /* const style = window.getComputedStyle(refEl, null)
      const width = style.getPropertyValue("width").replace("px", "") * 1
      console.log("NEW WIDTH", width)
      setContainerWidth(width)*/ 
    }
    resize()
    const debouncedResize = debounce(resize, 200)
    window.addEventListener("resize", debouncedResize)
    window.addEventListener("orientationchange", debouncedResize)
    return () => {
      window.removeEventListener("resize", debouncedResize)
      window.removeEventListener("orientationchange", debouncedResize)
    }
  }, [refEl])
  return [setRefEl, containerWidth]
}
