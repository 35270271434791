import { useMemo } from "react"

export function useGroupData(data = [], interval, active) {
  return useMemo(() => {
    // console.log({ data, interval })
    if (interval === "month" || !active) return data
    return data.map(s => ({
      ...s,
      data: groupSeriesByYear(s.data)
    })
    )
  }, [data, interval, active])
}

function groupSeriesByYear(data) {
  return data.reduce(( acc, curr) => {
    const [year] = curr.x.split(".") // , month
    const obj = { ...curr, x: year * 1 }
    const existing = acc.find(p => p.x * 1 === year * 1)
    return existing
      ? [...acc.filter(p => p !== existing), mergePoints(obj, existing)]
      : [...acc, obj]
  }, [])
}

function mergePoints(a, b) {
  return {
    ...a,
    x: a.x,
    y: a.y + b.y,
    count: a.count + b.count,
    parties: {...a.parties, ...b.parties},
    contexts: {...a.contexts, ...b.contexts}
  }
}