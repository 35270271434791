import styled from "styled-components"
import { breakpointMobile, breakpointTablet } from "../app/globals.module.scss"

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  > * {
    z-index: 2;
  }

  &:before {
    content: "";
    display: block;
    position: ${(p) => (p.bgNotFixed ? "absolute" : "fixed")};
    @media screen and (max-width: ${breakpointTablet}) {
      position: fixed;
    }
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${(p) => p.backgroundImage}), ${p => p.bgBlendColor || "rgba(0, 0, 0, 0.5)"};
    background-blend-mode: darken;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: ${p => p.bgPosition || "50% 0"}; // 50% 0
    // background-attachment: ${(p) => (p.bgNotFixed ? "scroll" : "fixed")};
  }
`
