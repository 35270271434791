import styled from "styled-components"

const lineColor = "rgb(200, 200, 200)"

export const TocHeader = styled.button`
  font-size: ${(p) => p.theme.fontSizeSmall};
  font-weight: 800;
  padding: 0.5em 0;

  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: flex;
  justify-content: flex-start;
  > * {
    margin-right: 1em;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    width: 100%;
  }
`

interface ItemProps {
  isNumbered: boolean
  isMarked: boolean
}

export const TocListItem = styled.li<ItemProps>`
  display: block;
  margin: 0;
  &:not(:last-child):not(.is-title) {
    border-bottom: 1px dashed ${lineColor};
  }
  counter-increment: custom-counter;
  font-size: ${(p) => p.theme.fontSizeSmall};
  &.is-title {
    display: none;
  }
  .extra-column-item & {
    &.is-title {
      display: block;
      margin-bottom: 1em;
      font-size: 1rem;
      button {
        font-weight: 800;
      }
    }
  }
  button {
    width: 100%;
    text-align: left;
    font-weight: ${(p) => (p.isMarked ? 800 : 400)};
    text-transform: uppercase;
    display: flex;
    &:before {
      display: ${(p) => (p.isNumbered ? "block" : "none")};
      content: counter(custom-counter) ". ";
      padding-right: 0.5em;
      text-align: right;
    }
  }
`

interface TocListProps {
  expanded: boolean
  onClick?: () => void
}

export const TocList = styled.ul<TocListProps>`
  margin: 0;
  padding: 0;
  transition: max-height 0.4s ease-in;
  overflow: hidden;
  width: 100%;
  max-height: ${(p) => (p.expanded ? "600px" : "105px")};
  @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
    max-height: ${(p) => (p.expanded ? "600px" : "0px")};
  }
  cursor: ${(p) => (p.expanded ? "auto" : "pointer")};
  > * {
    pointer-events: ${(p) => (p.expanded ? "auto" : "none")};
  }
  position: relative;

  counter-reset: custom-counter;

  &:after {
    display: block;
    opacity: ${(p) => (p.expanded ? 0 : 1)};
    pointer-events: ${(p) => (p.expanded ? "none" : "auto")};
    transition: oppacity 0.4s ease-in;
    content: "";
    position: absolute;
    height: 50px;
    width: 100%;
    bottom: 0;
    // background-image: linear-gradient(to top, #fff, hsla(0, 0%, 100%, 0));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    .dark-mode & {
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${p => p.theme.bgDark});
    }
  }
`

interface TocWrapperProps {
  listOnly?: boolean
}

export const TocWrapper = styled.div<TocWrapperProps>`
  border-top: 1px solid ${lineColor};
  border-bottom: 1px solid ${lineColor};
  @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
    > div {
      display: flex;
    }
  }
  ${(p) => (p.listOnly ? "border: none !important;" : "")}
  padding-bottom: 0 !important;

  li {
    padding: ${(p) => (p.listOnly ? 0 : "0.5em 0")};
  }

  &.toc-fixed-left {
    @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
      max-width: calc(
        (100vw - ${(p) => p.theme.blockMaxWidthCentered}) / 2
      ) !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      // height: 1px !important;
      overflow: show;
      left: 0;
      top: 50px;
      position: sticky;
      border: none;
      // border: 1px dashed red;
      height: calc(100vh - 50px);
      // margin-bottom: calc(-100vh + 50px);
      & + * {
        margin-top: calc(-100vh + 50px) !important;
      }

      > div {
        // border: 1px dashed green;
        max-width: 265px;
        margin: 0 auto;
        ${TocList} {
          max-height: none;
        }
        ${TocHeader} {
          display: none;
        }
        ${TocListItem} {
          border-bottom: none !important;
          margin: 0.5em 0;
          button {
            text-transform: none !important;
          }
        }
      }
    }
  }
`
