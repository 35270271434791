import React, { useMemo, useRef, useCallback, useContext } from "react"
import { useTransformer } from "../../modules/transformer"
import "./article.scss"
import SlideProjector from "../slide-projector"
import { useInViewClass, useBodyClass } from "../../modules/hooks"
import {
  usePreloadedPopups,
  ExtraColumnWrapper,
  withAnnotations,
} from "gatsby-plugin-popup-preloader"
import ReadingProgressBar from "../reading-progress-bar"
import { ArticleWrapper, ArticleBody, ArticleBodyInner } from "./article.style"
import scrollIntoView from "scroll-into-view"
import { useDonEncPageView, ART_BODY_CLASS } from "../app/donation-encourager"
import MetaInfo from "../meta-info"
import withMapPoints from "../map/map-points"
import {
  LangContext,
  usePreview,
  withToc,
  PageContext,
  withConsent,
} from "gatsby-source-dek-wp"

const Article = ({
  pageContext = {},
  prev,
  next,
  deckRef,
  selected,
  metaData,
}) => {
  const { content, popups, postId, noDonationEncourager } = pageContext
  const { color, key } = selected.category || {}
  const { restApi, CustomLead } = metaData
  useBodyClass(`category-${key}`)
  usePreloadedPopups(popups)
  // console.log("POPUPS", popups)
  const contentState = usePreview(content, postId, restApi)
  const transformedContent = useTransformer(contentState)
  const ref = useInViewClass("on-article-body", { threshold: 0 })
  const slide = useSlide(pageContext)
  const articleRef = useRef()
  const articleBodyRef = useRef()
  const onSelect = useOnSelect(articleRef, articleBodyRef, deckRef)
  useDonEncPageView(transformedContent, key)
  const artBodyClass = !noDonationEncourager ? ART_BODY_CLASS : ""
  const lang = useContext(LangContext)
  const LeadComp = CustomLead || SlideProjector

  return (
    <PageContext.Provider value={pageContext}>
      <ArticleWrapper ref={articleRef} className="article-wrapper">
        {!!slide.image && (
          <LeadComp
            backArrow
            langId={lang.id}
            {...{ slide, prev, next, onSelect, metaData }}
          />
        )}
        <ReadingProgressBar refEl={articleBodyRef.current} color={color} />
        <ExtraColumnWrapper>
          <ArticleBody className="article-body" ref={articleBodyRef}>
            <ArticleBodyInner className={artBodyClass} ref={ref}>
              {!slide.image && (
                <h1 style={{ marginTop: "1em" }}>{slide.title}</h1>
              )}
              <MetaInfo data={pageContext} />
              {transformedContent}
            </ArticleBodyInner>
          </ArticleBody>
        </ExtraColumnWrapper>
      </ArticleWrapper>
    </PageContext.Provider>
  )
}

export default withConsent(withMapPoints(withToc(withAnnotations(Article))))

function useOnSelect(articleRef, articleBodyRef, deckRef) {
  return useCallback(() => {
    const ref = document.body.classList.contains("on-article-body")
      ? deckRef
      : document.body.classList.contains("on-deck")
      ? articleRef
      : articleBodyRef
    if (!ref.current) return
    scrollIntoView(ref.current)
  }, [articleRef, articleBodyRef, deckRef])
}

function useSlide(pageContext) {
  return useMemo(() => {
    const { title, teaserStartPage, titleVideoPoster } = pageContext
    return { image: titleVideoPoster, title, subtitle: teaserStartPage }
  }, [pageContext])
}
