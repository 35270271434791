import styled from "styled-components"
import * as defaultTheme from "../theme.default"

export const HeaderWrapper = styled.div`
  color: ${(p) => p.theme.headerFontColor};

  position: relative; // sticky; // absolute; // fixed; // sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(p) => p.theme.headerHeight};
  pointer-events: none;
  transition: 0.3s ease-in-out;
  overflow: hidden;

  z-index: 40;
  @media screen and (max-width: ${(p) => p.theme.breakpointTablet}) {
    z-index: 40;
  }

  &.expanded {
    height: 60vh;
    // height: auto;
    pointer-events: auto;
    overflow: scroll;
  }

  .header-navi {
    height: 100%; // ${(p) => p.theme.headerHeight};
    background-color: ${(p) =>
      p.theme.bgDark || "black"}; // black; // $menu-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .specials-preview {
    background-color: #2a2b30;
    padding-top: 2rem;
    // height: 100%;
  }

  @media print {
    .header-navi {
      background-color: white;
    }
  }

  .on-slide-projector & {
    background-color: transparent;
  }

  body.menu-open & {
    background-color: black;
  }

  body:not(.menu-open) &.hidden {
    top: -${(p) => p.theme.headerHeight};
  }

  /* body.on-deck & {
    // background-color: transparent;
    top: 0 !important;
  } */

  /* body.on-article-body & {
    // top: -$header-height !important;
  } */

  .back-arrow {
    position: absolute;
    top: 14px;
    left: 14px;
    opacity: 1;
    transition: opacity 0.5s ease;

    svg {
      cursor: pointer;
    }
  }
`

HeaderWrapper.defaultProps = {
  theme: {
    ...defaultTheme,
  },
}
