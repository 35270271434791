import React from "react"
import MapPoint from "./map-point"

function mapPointTransformer(node, i, { classList, children }) {
  if (
    classList.includes(
      "wp-block-dekoder-custom-blocks-container-with-coordinates"
    )
  ) {
    const { lat, lon, category, zoom } = node.attribs
    return <MapPoint {...{ lat, lon, category, zoom }}>{children}</MapPoint>
  }
}

export default mapPointTransformer
