import React, { useContext, useMemo } from "react"
import {
  LangContext,
  ContentsContext,
  SelectedContext,
  useMultiContents,
  SPECIAL_CATEGORIES,
} from "gatsby-source-dek-wp"
import { Linker } from "../linker/linker"
import { Wrapper } from "./special-relations.style"

const LABEL_TEXT = {
  de: "Teil des Specials",
  en: "Part of",
  ru: "Часть спецпроекта",
  uk: "Частина спецпроекту",
  be: "Частка спецпраекта",
}

interface SpecialRelation {
  title: string
  slug: string
}

export function SpecialRelations() {
  const lang = useContext(LangContext)
  const parentSpecials: SpecialRelation[] = useParentSpecials()
  if (!parentSpecials.length) return null
  return (
    <Wrapper className="special-relations">
      <span className="special-relations-label">{LABEL_TEXT[lang.id]}</span>
      <ul className="special-relations-list">
        {parentSpecials.map((s) => (
          <li key={s.slug}>
            <Linker to={`/${s.slug}`}>{s.title}</Linker>
          </li>
        ))}
      </ul>
    </Wrapper>
  )
}

export function useParentSpecials(): SpecialRelation[] {
  const contents = useContext(ContentsContext)
  const selected = useContext(SelectedContext)
  const multiContents = useMultiContents(selected, contents)
  const parentContents = useMemo(
    () =>
      multiContents
        .filter((c) =>
          c.categories.some(({ id }) => SPECIAL_CATEGORIES.includes(id))
        )
        .filter((c) => c.slug !== selected?.slug),
    [multiContents, selected]
  )
  return parentContents
}
