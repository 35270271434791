import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"

const TocButtonWrapper = styled.span`
  opacity: 0.1;
  margin-right: .5em;
  cursor: pointer;
  button:hover & {
    opacity: 0.7;
  }
`

const TocButton = () => (
  <TocButtonWrapper className="toc-button-wrapper">
    <FontAwesomeIcon className={`toc-button`} icon={faBars} />
  </TocButtonWrapper>
)

export default TocButton
