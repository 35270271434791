import styled from "styled-components"

export const Wrapper = styled.div`
  text-align: right;
  .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb {
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${(p) => p.themeColor} !important;
  }

  .MuiSwitch-colorSecondary .MuiSwitch-thumb {
    color: #fafafa;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${(p) => p.themeColor};
  }

  .switch-label .MuiFormControlLabel-label {
    font-family: "Roboto Condensed";
  }

  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 1px;
  }
`
