import React, { useContext } from "react"
import { Link } from "gatsby"
import { LangContext } from "gatsby-source-dek-wp"
import { LangSwitcherWrapper } from "./lang-switcher.style"
import { LangDef } from "../../hooks/langs"
import { Content } from "../../hooks/contents"

const sortByPropKey = (key: string | number) => (a: Record<any, any>, b: Record<any, any>) =>
  a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
const sortyByLangId = sortByPropKey("id")

interface Props {
  allLangs: LangDef[]
  selected: Content
}

export const LangSwitcher = ({ allLangs, selected = {} }: Props) => {
  const lang = useContext(LangContext)
  return (
    <LangSwitcherWrapper className="language-switcher">
      {allLangs.sort(sortyByLangId).map((l) => {
        const className = `language-option${
          l.id === lang.id ? " selected" : ""
        }`
        const translation = (selected.translations || []).find(
          (a) => a.langId === l.id
        )
        const destination = `/${translation ? translation.slug : l.id}`
        return (
          <Link key={l.id} className={className} to={destination}>
            {l.id}
          </Link>
        )
      })}
    </LangSwitcherWrapper>
  )
}
