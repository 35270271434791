import React, { useContext, useMemo } from "react"
import { WebpContext, NO_WEBP } from "../image/webp-check"
import { Linker } from "../linker/linker"
import { Banderole } from "./banderole"
import { TileOuter } from "./tile.style"
import parse from "html-react-parser"
import { AuthContext } from "../../hooks/auth"
import { Content } from "../../hooks/contents"

interface TileProps {
  content: Content
  title?: string
  kicker?: string
  localPrefix?: string
  fullscreen?: boolean
  bgImageUrl?: string
  bgImageUrlSmall?: string
  template: "default" | "fullscreen" | "flashcard" | "kicker"
  externalUrl?: string
  comingSoon?: boolean
}

export const Tile = ({
  content,
  title,
  kicker,
  localPrefix = "",
  fullscreen,
  bgImageUrl,
  bgImageUrlSmall,
  template = "default",
  externalUrl,
  comingSoon: _comingSoon,
}: TileProps) => {
  const isAuthed = useContext(AuthContext)

  const { slug = "" } = content || {}
  const isInternal = !slug.match(/\./) && slug !== ""
  const comingSoon = _comingSoon || (!!content && !content.slug)
  const href = externalUrl
    ? externalUrl
    : isInternal
      ? `${localPrefix}/${content?.slug}`
      : content?.slug
        ? `https://${content.slug}`
        : "#"
  const { description = "" } = content || {}
  const transformedTeaser = useMemo(() => parse(description), [description])
  const tileImage = fullscreen
    ? bgImageUrl || content?.titleVideoPoster
    : bgImageUrlSmall || bgImageUrl || content?.tileImage
  const noWebpSupport = useContext(WebpContext)
  const imgUrl = !noWebpSupport
    ? tileImage // titleVideoPoster || image.src
    : `${tileImage}?${NO_WEBP}`

  const showTeaser = ["fullscreen", "template", "kicker"].includes(template)
  const showAuthor = template === "kicker"
  const author = (content?.authors || []).map((a) => a.name).join(", ")
  const bgColor = content?.themeColor
  const isLeft = false // template === "kicker"

  if (!content?.published && !isAuthed && !externalUrl && !comingSoon)
    return null

  return (
    <TileOuter
      className={`tile-outer ${
        fullscreen ? "fullscreen" : ""
      } template-${template}`}
      template={template}
      bgColor={bgColor}
    >
      <Linker
        // isInternal={isInternal && !localPrefix}
        to={comingSoon && !isAuthed ? "#" : href}
        target={isInternal ? "" : "_blank"}
        rel="noopener noreferrer"
        onClick={(e) => (comingSoon && !isAuthed ? e.preventDefault() : "")}
      >
        <div className="tile-img-wrapper">
          {!!imgUrl && <img src={imgUrl} alt={title} />}
          {!isAuthed && comingSoon && (
            <Banderole className={isLeft ? "left" : "right"}>
              coming soon ...
            </Banderole>
          )}
          {isAuthed && !!content && !content.published && (
            <Banderole className={isLeft ? "left" : "right"}>
              unpublished
            </Banderole>
          )}
        </div>
        <div className="tile-inner">
          <div className="tile-title-wrapper">
            {!!kicker && <div className="tile-kicker">{kicker}</div>}
            {!!author && showAuthor && (
              <div className="tile-author">{author}</div>
            )}
            <div className="tile-title">{title}</div>
            {showTeaser && (
              <div className="tile-teaser">{transformedTeaser}</div>
            )}
          </div>
        </div>
      </Linker>
    </TileOuter>
  )
}
