import React from "react"
import styled from "styled-components"
import { fontSizeSuperSmall, articleRedRgb } from "../app/globals.module.scss"

const Banderole = styled.div`
  z-index: 5;
  position: absolute;
  padding: 5px 3em;
  font-size: ${fontSizeSuperSmall};
  top: 0;
  left: 0px;
  transform: rotate(-45deg) translateX(-30%);
  background-color: ${articleRedRgb};
  text-align: center;
  text-transform: uppercase;
  opacity: 0.3;
`

export default Banderole
