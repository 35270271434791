import styled from "styled-components"

interface ArrowButtonProps {
  dir: "left" | "right"
  inactive?: boolean
  topOffset?: number
}

export const ArrowButton = styled.button<ArrowButtonProps>`
  display: ${(p) => (p.inactive ? "none" : "block")};
  position: absolute;
  top: ${p => p.topOffset ? p.topOffset + "px" : "50%"};
  transform: translateY(-50%); // translateY(calc(-50% - 0.75rem));
  // border: 1px dashed red;
  font-size: 1.5rem;
  padding: 0 1rem;
  line-height: 1;
  color: white;
  left: ${(p) => (p.dir === "left" ? 0 : "auto")};
  right: ${(p) => (p.dir === "right" ? 0 : "auto")};
  z-index: 3;
  opacity: 0.8;
  transition: opacity 0.3s ease-in;
  user-select: none;
  &:hover {
    opacity: 1;
  }

  .carousel-wrapper.size-full & {
    font-size: 2rem;
    @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
      position: fixed;
    }
  }
  .carousel-wrapper.size-full:not(.in-view) & {
    @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
      position: absolute;
    }
  }
  .carousel-wrapper.show-overflow & {
    @media screen and (min-width: ${p => p.theme.breakpointTablet}) {
      opacity: 0;
    }
  }
  .carousel-wrapper.show-overflow:hover & {
    opacity: 1;
  }

  .carousel-wrapper:not(.size-full) & {
    svg {
      filter: drop-shadow(2px 2px 6px #888);
    }
  }

  @media screen and (max-width: ${p => p.theme.breakpointTablet}) {
    padding: 1rem;
  }

  @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
    padding: 15px;
  }
`
