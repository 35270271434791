import { useState, useEffect, useCallback } from "react"
import soundFile from "./projector-sound.mp3"

const NO_VISIBILITY = { shown: false, focused: false }

function playSoundEffect() {
  const projectorSound = new Audio(soundFile)
  projectorSound.play().catch(console.log)
}

export default function useSlideChange(newSlide, noSound) {
  const [currSlide, setCurrSlide] = useState(newSlide)
  const [visibility, setVisibility] = useState(NO_VISIBILITY)

  useEffect(() => {
    if (!noSound) playSoundEffect()
    setVisibility(NO_VISIBILITY)
    setTimeout(() => {
      setCurrSlide(newSlide)
    }, 450)
    setTimeout(() => {
      setVisibility({ shown: true })
    }, 550)
    setTimeout(() => setVisibility((v) => ({ ...v, focussed: true })), 900)
  }, [newSlide, noSound])

  const onLeave = useCallback(() => {
    if (!noSound) playSoundEffect()
    setVisibility(NO_VISIBILITY)
  }, [noSound])

  return [currSlide, visibility, onLeave]
}
