import styled from "styled-components"

export const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  .embed-content-wrapper {
    display: ${(p) => (p.active ? "block" : "none")};
  }
  .embed-content-wrapper > iframe.instagram-media {
    width: 100%;
    margin: 1px auto !important;
    max-width: 540px;
    min-width: 326px;
  }
  .embed-content-wrapper > iframe:not(.instagram-media) {
    max-width: 100%;
    display: block;
    border: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .embed-content-wrapper .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }
  .embed-content-wrapper > blockquote.instagram-media {
    background: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow:
      0 0 1px 0 rgba(0, 0, 0, 0.5),
      0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 1px auto;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
    width: -webkit-calc(100% - 2px);
    width: calc(100% - 2px);
  }
  .embed-content-wrapper > blockquote.tiktok-embed {
    max-width: 605px;
    min-width: 325px;
  }

  .embed-consent-wrapper {
    border: ${(p) => (p.active ? "none" : "1px solid gray")};
    .embed-text {
      font-style: italic;
    }
    padding: 1em;
    a {
      color: inherit !important;
    }
    > div {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
      > *:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
  figcaption {
    margin-top: 1em !important;
  }
`

interface BtnProps {
  active: boolean
}

export const ConsentBtnWrapper = styled.div<BtnProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${(p) => (p.active ? "auto" : "none")};
  border: 1px solid rgba(128, 128, 128, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background-color: ${(p) => p.theme.articleRed};
    padding: 0.5em 1em;
    color: white;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(0.9);
    }
  }
`
