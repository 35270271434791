import styled from "styled-components"

const seriesPaddingTop = 0

export const ExtdTooltipWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const CtxBtn = styled.button`
  font-size: ${p => p.theme.fontSizeSuperSmall};
  margin-top: .5em;
  width: 100%;
  border: 1px solid black;
  padding: .5em;
`

export const ChartTooltip = styled.div`
  background-color: transparent; // rgba(255, 255, 255, 0.9);
  // color: black;
  padding: 0; // .5em .5em;
  width: auto;
  max-width: 300px;
  // line-height: 1.2em;
  box-shadow: 0 1px 10px rgba(0,0,0,0.2);

  /*
  .mouse-top-left & {
    transform: translateX(calc(50% + 10px)) translateY(calc(100% + 25px));
  }
  .mouse-bottom-left & {
    transform: translateX(calc(50% + 10px));
  }
  .mouse-top-right & {
    transform: translateX(calc(-50% - 10px)) translateY(calc(100% + 25px));
  }
  .mouse-bottom-right & {
    transform: translateX(calc(-50% - 10px));
  }*/

  small {
    font-size: ${p => p.theme.fontSizeSuperSmall};
  }

  .year {
    letter-spacing: 3px;
    font-size: ${p => p.theme.fontSizeSuperSmall};
    padding: .5em;
    background-color: ${p => p.theme.bgDark};
    color: white;
    text-align: center;
  }
  .table {
    display: table;
    .series {
      font-size: ${p => p.theme.fontSizeSmall};
      display: table-row;
      .color {
        display: table-cell;
        // margin-right: .5em;
        padding: ${seriesPaddingTop} .5em 0 0;
        vertical-align: middle;
        div {
          width: 1em;
          height: 1em;
        }
      }
      .name {
        padding: ${seriesPaddingTop} 0 0;
        vertical-align: middle;
        font-weight: 700;
        display: table-cell;
        text-transform: uppercase;
      }
    }
    .count, .freq {
      font-size: ${p => p.theme.fontSizeSuperSmall};
      display: table-row;
      > div {
        display: table-cell;
        .values {
          display: flex;
          span {
            padding: .2em .5em;
            &:nth-child(1) {
              text-align: right;
              font-weight: 700;
              width: 50px;
            }
          }
        }
      }
    }
  }
`