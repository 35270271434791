import { useMemo, useContext, createContext } from "react"
import { AuthContext } from "./auth"
import { useDeepCompareMemo } from "use-deep-compare"
import { queryRes2Context } from "../query-res-2-content"

export interface Content {
  postId?: number
  langId?: string
  orderId?: number
  categories?: {
    id: number
  }[]
  sources?: Source[]
  translations?: Content[]
  [_: string]: any
}

interface Source {
  url?: string
  logo?: string
  name: string
}

interface WpNode {
  title: string
  categories: {
    nodes: {
      databaseId: number
    }[]
  }
  [_: string]: any
}

export const ContentsContext = createContext<Content[]>([])
export const AllContentsContext = createContext<Content[]>([]) // all langs
export const SelectedContext = createContext<Content | undefined>(undefined)
export const PageContext = createContext<Content>({}) // TODO <-> SelectedContext

export function isInCategoryIds(node: WpNode, categoryIds: number[]) {
  const nodeCatIds = node.categories.nodes.map((n) => n.databaseId)
  return nodeCatIds.some((id) => categoryIds.includes(id))
}

export interface DataProp {
  allWpPost: {
    nodes: WpNode[]
  }
  site: {
    siteMetadata: {
      [_: string]: any
    }
  }
}

export function useAllContents(
  data: DataProp = { allWpPost: { nodes: [] }, site: { siteMetadata: {} } },
  filterUnpublished = true,
  categoryIds: number[] = [],
) {
  const isAuthed = useContext(AuthContext)

  const nodes: WpNode[] = data?.allWpPost?.nodes || []
  const allContents = useDeepCompareMemo(() => {
    // console.log("data change -> new allContents", data)
    return (
      (nodes || [])
        .filter((n) => isInCategoryIds(n, categoryIds))
        .map((n) => queryRes2Context(n))
        // .filter((n) => !!langId ? n.langId === langId : n)
        .filter((n) => (isAuthed || !filterUnpublished ? n : n.published))
        .sort((a, b) => b.orderId - a.orderId)
        .map((c, i, arr) => ({
          ...c,
          // enrich translations with full content objects (matched by postId)
          translations: c.translations
            .map((t: { postId: number }) => arr.find((c: { postId: number }) => c.postId === t.postId))
            .filter((t: Content ) => !!t),
        }))
    )
  }, [nodes, isAuthed])
  return allContents
}

interface Category {
  key: string // ??? TODO
}

export function useContents(
  allContents: Content[] = [],
  langId: string,
  slug: string,
  categories: Category[] = [],
  filterUnpublished = true,
) {
  const isAuthed = useContext(AuthContext)
  const contents = useDeepCompareMemo(() => {
    return allContents
      .filter((c) => c.langId === langId)
      .filter((c) => (isAuthed || !filterUnpublished ? true : c.published))
      .map((c) => ({
        ...c,
        category: getCategoryByKey(c.pageType, categories),
      }))
      .sort((a, b) => (a.orderId || 0) - (b.orderId || 0))
  }, [allContents, langId, isAuthed, categories, filterUnpublished])
  const selectedContent = allContents.find((c) => c.slug === slug)
  return [contents, selectedContent] as const
}

export function usePrevNext(
  contents: Content[],
  selected: Content | undefined,
  sameCategoryOnly: boolean,
  filterCats: number[] = [],
) {
  return useMemo(() => {
    if (!selected) return [null, null]
    const selectedCatIds = (selected.categories || [])
      .map(({ id }) => id)
      .filter((id) => !filterCats.includes(id))
    const cntnts = sameCategoryOnly
      ? contents.filter((c) => {
          const catIds = (c.categories || [])
            .map(({ id }) => id)
            .filter((id) => !filterCats.includes(id))
          return (
            catIds.length && // only SubPages: c.categoryIds.length === 1 !
            selectedCatIds.length === 1 &&
            catIds[0] === selectedCatIds[0]
          )
        })
      : contents
    // console.log("CNTNTS", contents, cntnts, selectedCatIds, filterCats)
    const slctd = cntnts.find((c) => c.postId === selected?.postId)
    const selectedIndex = !!slctd && cntnts.indexOf(slctd) || 0
    const prevIndex = selectedIndex - 1
    const nextIndex = selectedIndex + 1
    const prev = cntnts[prevIndex] || cntnts[cntnts.length - 1]
    const next = cntnts[nextIndex] || cntnts[0]
    // console.log("PREV, NEXT", prev, next, "SELECTED", selected, selectedIndex, "CONTENTS", contents)
    return [prev, next] as const
  }, [contents, selected, sameCategoryOnly, filterCats])
}

export function getCategoryByKey(key = "", categories: Category[] = []) {
  return categories.find((c) => c.key.toLowerCase() === key.toLowerCase()) || {}
}
