import React from "react"
import { FeedbackWrapper } from "./quiz-feedback.style"

const QuizFeedback = ({ total, correct, feedback, img }) => {
  return (
    <FeedbackWrapper img={img}>
      <small>
        Du hast {correct} von {total} Fragen richtig beantwortet.
      </small>
      <h3>{feedback}</h3>
    </FeedbackWrapper>
  )
}

export default QuizFeedback

export const QuizFeedbackTemplate = () => {
  return null
}
