import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react"
import { transformString } from "../../modules/transformer"
import { ExtraColumnPortal } from "gatsby-plugin-popup-preloader"
import { getFormatedDate } from "../../modules/utils"

import "./meta-info.scss"
import { LangContext } from "gatsby-source-dek-wp"

const authorPrefix = { de: "Text", en: "Text", ru: "Текст", ua: "Текст" }
/* const videoSourcePrefix = { 
  de: "Video", 
  en: "Video", 
  ru: "Видео", 
  ua: "Відео" 
}*/
const videoSourcePrefix = {
  de: "Titelbild",
  ru: "Фотография",
}
const videoCutPrefix = {
  de: "Schnitt",
  en: "Video Editing",
  ru: "Монтаж",
  ua: "Монтаж",
}
const photoEditorPrefix = {
  de: "Bildredaktion",
  en: "Photo Editing",
  ru: "Photo Editing",
  ua: "Photo Editing",
}
const translatorPrefix = ({ langId, isShortened }) => {
  const transl = {
    de: "Übersetzung",
    en: "Translation",
    ru: "Перевод",
    ua: "Переклад",
  }
  const shortened = {
    de: "gekürzt",
    en: "shortened",
    ru: "сокращенная версия",
    ua: "скорочена версія",
  }
  return `${transl[langId]}${isShortened ? ` (${shortened[langId]})` : ""}: `
}
const dataVisualizationPrefix = {
  de: "Datenvisualisierung",
  en: "Data visualisation",
  ru: "Визуализация данных",
  ua: "візуалізація даних",
}

const metaFields = [
  // { propName: "author", prefix: authorPrefix,  },
  { propName: "photoEditor", prefix: photoEditorPrefix },
  { propName: "translator", prefix: translatorPrefix },
  { propName: "titleVideoSource", prefix: videoSourcePrefix },
  { propName: "titleVideoCut", prefix: videoCutPrefix },
  { propName: "dataVisualization", prefix: dataVisualizationPrefix },
  { propName: "date", transform: getFormatedDate },
]

const MetaInfo = ({ data, type }) => {
  const { authorImage, author, authorDescription, date, shortened } = data
  const isShortened = shortened === "true"
  const metaRef = useRef()
  const [hover, setHover] = useState(false)
  const [authorExpanded, setAuthorExpanded] = useState(false)
  const langId = useContext(LangContext).id
  const metaItems = metaFields
    .filter((f) => !!data[f.propName])
    .map((f, i) => {
      const prefix =
        typeof f.prefix === "function"
          ? f.prefix({ isShortened, langId })
          : typeof f.prefix === "object"
          ? `${f.prefix[langId]}: `
          : f.prefix || ""
      return (
        <span className="meta-item" key={i}>
          {prefix}
          {typeof f.transform === "function"
            ? f.transform(data[f.propName])
            : transformString(data[f.propName])}
        </span>
      )
    })

  const description = useMemo(
    () => (
      <div className="author-description">
        {transformString(authorDescription)}
      </div>
    ),
    [authorDescription]
  )

  const setActive = useCallback(() => setHover(true), [])
  const setInactive = useCallback(() => setHover(false), [])

  const collapse = useCallback(() => setAuthorExpanded(false), [])

  const clickable = !!authorDescription
  const binds = clickable
    ? {
        onClick: () => setAuthorExpanded((e) => !e),
        onMouseEnter: () => setHover(true),
        onFocus: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onBlur: () => setHover(false),
      }
    : {}

  return (
    <>
      <div
        className={`meta-info ${type || ""}${
          !author && !date && !metaItems.length ? " no-items" : ""
        }`}
        ref={metaRef}
      >
        {authorImage && (
          <span
            {...binds}
            className={`author-image${authorExpanded ? " active" : ""}${
              hover ? " hover" : ""
            }${clickable ? " clickable" : ""}`}
          >
            <img src={authorImage} alt={author} />
          </span>
        )}
        <div className={`meta-items`}>
          {!!author && (
            <span className="meta-item">
              {authorPrefix[langId]}:{" "}
              <span {...binds} className={clickable ? "clickable" : ""}>
                {transformString(author)}
              </span>
            </span>
          )}
          {metaItems}
        </div>
      </div>
      {authorExpanded && (
        <ExtraColumnPortal
          refEl={metaRef}
          type={`popup`}
          expanded={authorExpanded}
          hover={hover}
          collapse={collapse}
          onMouseOver={setActive}
          onFocus={setActive}
          onMouseOut={setInactive}
          onBlur={setInactive}
          options={{
            noTopShift: true,
          }}
        >
          {description}
        </ExtraColumnPortal>
      )}
    </>
  )
}

export default MetaInfo
