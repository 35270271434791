import styled from "styled-components"

export const FlexWrapper = styled.div`
  position: relative;
`

export const FlexItemWrapper = styled.div`
  position: absolute;
  transition: 0.7s ease-in-out;
`
