import styled from "styled-components"
import {
  // fontSizeH0,
  fontSizeH1,
  fontSizeH2,
  fontSizeH3,
  fontSizeH4,
  fontSizeSmall,
  breakpointMobile,
} from "../app/globals.module.scss"

export const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  .menu-open & {
    opacity: 0;
    pointer-events: none;
  }
  .menu-open .menu-site-title > & {
    opacity: 1;
    pointer-events: auto;
  }
  padding: 2rem 0 0 2rem;
  max-width: 550px;
  &.center {
    margin: 0 auto;
    > div {
      text-align: center;
    }
  }
  @media screen and (max-width: ${breakpointMobile}) {
    max-width: 100%;
    padding: 1rem 0 0;
    > div {
      text-align: center;
    }
  }
`

export const Title = styled.div`
  font-size: ${(p) => (p.font === "Molot" ? fontSizeH1 : fontSizeH3)};
  font-family: ${(p) => p.font || "Roboto Condensed"};
  font-weight: 700;
  letter-spacing: 8px;
  text-transform: uppercase;
  // text-align: center;
  line-height: 1.2em;

  @media screen and (max-width: ${breakpointMobile}) {
    font-size: ${(p) => (p.font === "Molot" ? fontSizeH2 : fontSizeH4)};
    letter-spacing: 2.5px;
    margin-top: 1em;
  }
`

export const Subtitle = styled.div`
  font-size: ${fontSizeSmall};
  text-transform: uppercase;
  margin-top: 0.5em;
  letter-spacing: 7px;
  line-height: 1.5em;
  // text-align: center;
  @media screen and (max-width: ${breakpointMobile}) {
    letter-spacing: 2px;
    // margin-top: 2em;
  }
`
