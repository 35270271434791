import styled from "styled-components"

export const LoadingSpinner = styled.div<{ active: boolean}>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: ${(p) => (p.active ? "block" : "none")};
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 55%;
    border: 4px solid gray;
    background: transparent;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 37px;
    height: 6px;
    margin-top: 4px;
    margin-left: 6px;
    /* background: white; // Old browsers */
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 55%,
      gray 55%,
      gray 100%
    );
    transform-origin: 0 2px;
    animation: spinner 1.3s linear infinite;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`
