import React, { useContext } from "react"
import { PageContext } from "../../hooks/contents"
import { LangContext } from "../../hooks/langs"
import { Wrapper } from "./source.style"

const LABEL: { [_: string]: string } = {
  de: "Quelle",
}

export const Source = () => {
  const content = useContext(PageContext)
  const lang = useContext(LangContext)
  const { sources } = content
  const filteredSources = (sources || []).filter((s) => !!s.logo)
  if (!filteredSources.length) return null
  return (
    <Wrapper>
      <div>
        <span className="source-logo-label">{LABEL[lang.id]}</span>
        {filteredSources.map((s, i) => (
          <a key={i} href={s.url} target="_blank">
            <img src={s.logo} alt={s.name} />
          </a>
        ))}
      </div>
    </Wrapper>
  )
}
