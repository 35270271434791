import React, { useContext, useRef, useEffect, useState, useMemo } from "react"
import { LangContext } from "gatsby-source-dek-wp"
import { ResponsiveLine } from "@nivo/line"
import chartPropsShared from "./chart-props-shared"
import Tooltip from "../tooltip/tooltip"
import { ChartWrapper } from "./chart.style"
import { ThemeContext, useTheme } from "styled-components"
import { useIsMobile } from "../utils"

const markerCommonProps = {
  axis: "x",
  lineStyle: {
    stroke: "hsl(0,0%,80%)",
  },
  textStyle: {
    fill: "gray",
    letterSpacing: "2px",
    textTransform: "uppercase",
    fontSize: "0.64em",
  },
  legendOrientation: "vertical",
}

function getYear(dateStr) {
  return `${dateStr}`.split("-")[0] * 1
}

function withDateStr(markers) {
  // for markers with year only
  return markers.map((m) => ({
    ...m,
    value: typeof m.value === "number" ? new Date(`${m.value}-01-01`) : m.value,
  }))
}

const Chart = ({
  data,
  customMarkers,
  children,
  resizeTrigger,
  yearRange = [2000, 2020],
  defaultMarkers: _defaultMarkers,
  // onChartClick
}) => {
  const { toolSidePadding, toolSidePaddingMobile } = useContext(ThemeContext)

  const isMobile = useIsMobile()
  const isDarkMode = useTheme()?.darkMode

  const toolSidePd = (toolSidePadding || "").replace("px", "") * 1
  const toolSidePdMobile = (toolSidePaddingMobile || "").replace("px", "") * 1

  const lang = useContext(LangContext)
  const defaultMarkers =
    typeof _defaultMarkers === "function"
      ? _defaultMarkers(lang)
      : _defaultMarkers
  const _markers =
    customMarkers && customMarkers.length
      ? [
          /*...defaultMarkers(lang), */ ...customMarkers.map((m) => ({
            ...markerCommonProps,
            value: m.year,
            legend: m.label,
            legendOrientation: "horizontal",
          })),
        ]
      : defaultMarkers.map((m) => ({ ...markerCommonProps, ...m }))
  const markers = withDateStr(_markers)
  const ref = useRef()
  const { width, height } = useCustomResponsiveness(ref, resizeTrigger)
  // const [mousePos, onMouseMove] = useMousePos(ref)

  const [xMin, xMax] = yearRange

  // TODO: better use datetime x values ...
  const hasMonths = data[0]?.data[0]?.interval === "month"

  const filteredData = useMemo(
    () =>
      (data || []).map((ds) => ({
        ...ds,
        data: ds.data.filter(
          (d) => getYear(d.x) >= xMin && getYear(d.x) <= xMax
        ),
      })),
    [data, xMin, xMax]
  )

  const [tooltipExtended] = useState(false)
  // const onChartClick = null // useCallback(() => setTooltipExtended((e) => !e))

  // console.log({ xMin, xMax, data, filteredData, hasMonths })

  return (
    <ChartWrapper className={`chart`} ref={ref}>
      <div className="chart-inner"
        // style={{ paddingTop: "3em"}}
      >
        <ResponsiveLine
          {...chartPropsShared}
          xScale={{
            type: "time", // hasMonths ? "time" : "linear",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: hasMonths ? "month" : "year",
            min: `${xMin}-01-01`, // hasMonths ? `${xMin}-01-01` : xMin,
            max: `${xMax}-12-31`, // hasMonths ? `${xMax}-01-01` : xMax,
          }}
          xFormat="time:%Y-%m-%d"
          data={hasMonths ? data : filteredData}
          width={width}
          height={height}
          margin={
            isMobile
              ? { top: 5, right: 30, bottom: 20, left: toolSidePdMobile + 2 }
              : {
                  top: 5,
                  right: toolSidePd + 2,
                  bottom: 20,
                  left: toolSidePd + 2,
                }
          }
          enableArea={true}
          // areaBlendMode: "darken",
          areaOpacity={0.1}
          axisTop={null}
          axisLeft={null}
          axisBottom={{
            orient: "bottom",
            legend: "",
            legendOffset: 36,
            legendPosition: "middle",
            format: hasMonths ? (xMax - xMin < 5 ? "%b %Y" : "%Y") : "%Y",
            tickRotation: 0.00001,
            tickPadding: 10,
            // tickValues: 0, //
          }}
          axisRight={{
            orient: "left",
            tickValues: 5,
            // tickValues: 0,
            legendOffset: 40,
            legendPosition: "middle",
          }}
          lineWidth={4}
          // useMesh={true}
          enableSlices={"x"}
          sliceTooltip={({ slice }) => (
            <Tooltip
              points={slice.points}
              extended={tooltipExtended}
              hasClick={typeof onChartClick === "function"}
            />
          )}
          crosshairType={"bottom-right"}
          markers={markers}
          theme={{
            axis: {
              fontFamily: "Roboto Condensed",
              ticks: {
                text: {
                  fontFamily: "Roboto Condensed",
                  fontSize: "0.64em",
                  fill: "#666666", // "transparent", //
                },
              },
            },
            grid: {
              line: {
                stroke: isDarkMode ? "#333" : "#eee", // "transparent", //
                strokeDasharray: "4 4",
              },
            },
          }}
        />
      </div>
      {children}
    </ChartWrapper>
  )
}

export default Chart

/*




function useMousePos(ref) {
  const [mousePos, setMousePos] = useState("")
  const onMouseMove = e => {
    const refEl = ref.current
    if (!refEl) return
    const rect = refEl.getBoundingClientRect()
    const horizontal = (e.screenX - rect.left) <= (rect.right - e.screenX) ? "left" : "right"
    const vertical = (e.screenY - rect.top) <= (rect.bottom - e.screenY) ? "top" : "bottom"
    setMousePos(`${vertical}-${horizontal}`)
  }
  return [mousePos, onMouseMove]
}
*/

function useCustomResponsiveness(ref, updateTrigger) {
  const [dimensions, setDimensions] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    if (!ref.current) return
    ;[].push(updateTrigger)
    const onResize = () => {
      if (!ref.current) return
      const { offsetWidth, offsetHeight } = ref.current
      setDimensions({ width: offsetWidth, height: offsetHeight })
    }
    onResize()
    window.addEventListener("resize", onResize)
    window.addEventListener("orientationchange", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
      window.addEventListener("orientationchange", onResize)
    }
  }, [ref, updateTrigger])
  return dimensions
}
