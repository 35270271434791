import React, { useState, createContext, useContext, useRef } from "react"
import { QuizWrapper, NextButton } from "./quiz.style"
import useEnrichedItems from "./enriched-items"
import useUserSelections from "./user-selections"
import QuizFeedback from "./quiz-feedback"
import { LangContext } from "gatsby-source-dek-wp"
import scrollIntoView from "scroll-into-view"

export const QuizContext = createContext()

const next = {
  de: "Weiter",
  ru: "Дальше",
}

const share = {
  de: "Ergebnis teilen",
  ru: "Поделиться результатом",
}

const Quiz = ({ items = [], feedbacks = [] }) => {
  const lang = useContext(LangContext)
  const ref = useRef()
  const [userSelections, onOptionClick] = useUserSelections()
  const enrichedItems = useEnrichedItems(items, userSelections)
  const [currItemId, gotoNext] = useNav()

  const currItem = enrichedItems[currItemId]
  const currSelection = (currItem || { props: {} }).props.selection
  const prevItems = enrichedItems.slice(0, currItemId)

  const feedbackComp = useFeedback(userSelections, feedbacks)

  const onNextClick = () => {
    gotoNext()
    scrollIntoView(ref.current, { time: 0, align: { topOffset: 15 } })
  }

  const displayUrl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <QuizContext.Provider value={onOptionClick}>
      <QuizWrapper>
        {prevItems}
        <div ref={ref}>{currItem || feedbackComp}</div>
        {!!currItem ? (
          <NextButton onClick={onNextClick} inactive={!currSelection}>
            {next[lang.id]}
          </NextButton>
        ) : (
          <a
            href={`http://www.facebook.com/sharer.php?u=${displayUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <NextButton>{share[lang.id]}</NextButton>
          </a>
        )}
      </QuizWrapper>
    </QuizContext.Provider>
  )
}

export default Quiz

function useNav() {
  const [currItemId, setCurrItemId] = useState(0)
  const gotoNext = () => setCurrItemId((i) => i + 1)
  const restart = () => setCurrItemId(0)
  return [currItemId, gotoNext, restart]
}

function useFeedback(userSelections, feedbacks) {
  const total = userSelections.length
  const correct = userSelections.filter((s) => s.isCorrect).length
  const matchingFeedback = feedbacks
    .sort((a, b) => a.props.minCorrect - b.props.minCorrect)
    .reduce((acc, curr) => {
      const { feedback, img } = curr.props
      return correct >= curr.props.minCorrect ? { feedback, img } : acc
    }, {})
  // console.log(correct, total, feedback)
  const { feedback, img } = matchingFeedback
  return <QuizFeedback {...{ total, correct, feedback, img }} />
}
