import React from "react"
import { createGlobalStyle } from "styled-components"
import chroma from "chroma-js"

const GlobalStyles = createGlobalStyle<{
  themeColor?: string
  themeColorRgb?: string
}>`
  html {
    --theme-color: ${(p) => p.themeColor};
    --theme-color-rgb: ${(p) => p.themeColorRgb};
  }
`

export function useThemeColor(_themeColor: string, theme: Record<string, any>) {
  // const theme = useTheme()
  const themeColor = _themeColor || theme?.titleColorRgb
  const themeColorRgb = themeColor ? chroma(themeColor).rgb().join(", ") : ""
  return <GlobalStyles themeColor={themeColor} themeColorRgb={themeColorRgb} />
}
