import React from "react"
import styled from "styled-components"
import { Source } from "../source/source"
import { SpecialRelations } from "../special-relations/special-relations"
import { SocialBar } from "./social-bar"

export const IntroWrapper = styled.div`
  margin-top: 2.5rem !important;
  p {
    font-style: italic;
    em,
    figcaption {
      font-style: normal;
    }
  }
`
export const Intro = ({ children }) => (
  <>
    <IntroWrapper className="intro-wrapper">{children}</IntroWrapper>
    <SocialBar />
    <Source />
    <SpecialRelations />
  </>
)
