import React, { useContext } from "react"
import { components } from "react-select"
import { LangContext } from "gatsby-source-dek-wp"

import { useTranscribe } from "../transcribe"
import { TranslateContext } from "../contexts/translate"
import { CustomOptionWrapper } from "./custom-option.style"

const CustomOption = (props) => {
  const lang = useContext(LangContext)
  const translateEnabled = useContext(TranslateContext)
  const transcribe = useTranscribe()
  const { value, totalCount, type, translationDe } = props.data

  const showValue = translateEnabled
    ? translationDe || transcribe(value)
    : value

  return (
    <components.Option {...props}>
      <CustomOptionWrapper className={`custom-option${type === "error" ? " error" : ""}`}>
        <span className="term">
          {showValue}
          {translateEnabled && <span className="term-russian">{value}</span>}
        </span>
        <span className="total-count">
          {totalCount.toLocaleString(lang.locale)}
        </span>
      </CustomOptionWrapper>
    </components.Option>
  )
}

export default CustomOption
