import styled from "styled-components"
import * as defaultTheme from "../theme.default"

export const Wrapper = styled.div`
  margin: 2rem 0 !important;
  max-width: none !important;
  padding: 2rem 5rem;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  &.has-media-on-the-right {
    flex-direction: row-reverse;
  }

  > * {
    margin: 0 2rem;
  }

  figure {
    img {
      max-height: 80vh;
      max-width: 40vw;
      width: auto;
      height: auto;
    }
  }

  .wp-block-media-text__content {
    max-width: 45vw;
    p {
      font-family: "Roboto Condensed";
      @media screen and (max-width: ${p => p.theme.breakpointDesktopBig }) {
        font-size: ${p => p.theme.fontSizeSmall};
      }
      &.has-large-font-size {
        font-size: ${p => p.theme.fontSizeH4};
        text-transform: uppercase;
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 0;
      }
      &.has-medium-font-size {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 800;
        margin-top: 1em;
      }
    }
  }

  &.bg-dark {
    color: white;
    a, a:visited, a:active, a:hover {
      color: white !important;
      svg {
        color: white !important;
      }
    }
    background-color: ${p => p.theme.bgDark};
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 1), 
      5px 5px 30px 0px rgba(0, 0, 0, 0.6); // 20px
    figcaption {
      color: rgb(240, 240, 240);
    }
    figure {
      img {
        box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.8);
      }
    }
  }
  &.bg-light {
    background-color: ${p => p.theme.bgLight};
  }

  @media screen and (max-width: ${p => p.theme.breakpointTablet}) and (min-width: ${p => p.theme.breakpointMobile}) {
    padding: 2rem 3rem;
    // align-items: flex-start;
    // display: block;
    > * {
      margin: 0 1rem;
    }
    .wp-block-media-text__content {
      max-width: none;
    }
    figure {
      // float: right;
      // margin-top: calc(1.5 * ${p => p.theme.fontSizeH3} + 1 * ${p => p.theme.fontSizeH5});
      // margin-left: 1rem;
      img {
        max-width: 25vw;
      }
    }
  }

  @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
    padding: 0;
    flex-direction: column !important;
    > * {
      margin: 1rem 0;
      overflow: hidden;
    }
    > figure img, > .wp-block-media-text__content {
      max-width: none;
      margin-top: 0;
    }
    > figure {
      width: 100%;
      margin: 1rem -2px;
      overflow: hidden;
      img {
        width: calc(100% + 4px);
        max-height: none;
        height: auto;
      }
    }
    > .wp-block-media-text__content {
      padding: 0 1rem;
    }
  }
`

Wrapper.defaultProps = {
  theme: {
    ...defaultTheme
  }
}