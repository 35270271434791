import styled from "styled-components"
import { fontSizeSmall, breakpointMobile } from "../app/globals.module.scss"

export const CatFiltWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  padding: 3em 0 1em;
  justify-content: center;
  @media screen and (max-width: ${breakpointMobile}) {
    padding: 2em 0 0;
    flex-wrap: wrap;
    margin-bottom: -2em;
  }
  body.menu-open & {
    opacity: 0;
    pointer-events: none;
  }
  display: none;
`

export const CatFiltButton = styled.button`
  flex: 1 1 0px;
  margin: 0 1em;
  padding: 0 1em;
  max-width: 180px;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(p) => (p.active ? 1 : 0.4)};
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px black;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: ${fontSizeSmall};
  /* border: 1px solid red; */
  div:first-child {
    margin-right: 0.5em;
  }
  @media screen and (max-width: ${breakpointMobile}) {
    margin: 0 0.5em;
    padding: 0.5em 0.1em;
    letter-spacing: 2.5px;
  }
`
