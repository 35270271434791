import styled from "styled-components"
import * as defaultTheme from "../theme.default"

export const TileOuter = styled.div`
  width: 400px;
  height: 300px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  margin: 0 1em 2em;
  &.template-default {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      // margin: 0 auto 2em;
      width: 300px;
      height: 225px;
    }
  }
  &.template-flashcard {
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      width: 320px;
      height: 240px;
    }
  }
  &.template-ria {
    width: 400px;
    height: 400px;
  }
  background-image: url(${(p) => p.imgUrl});
  position: relative;
  background-size: cover;
  text-align: left;
  // width: 100%;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;

  overflow: hidden;

  &:hover {
    transform: scale(1.1);
  }
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none !important;
    color: ${(p) =>
      p.template === "flashcard" ? "black" : "white"} !important;
  }
  a:hover {
    border: none !important;
  }

  font-family: "Roboto Condensed";

  .tile-inner {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // padding: 2em 1.5em;
    // border: 1px dashed blue;
    padding-bottom: 15%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;

    .tile-title-wrapper {
      // background-color: $dossier-yellow;
      // color: black;
      // border: 1px dashed green;
      z-index: 2;
      // position: absolute;
      // argin-bottom: 2em;
      .tile-kicker {
        font-size: ${(p) => p.theme.fontSizeSuperSmall};
        text-transform: uppercase;
        margin-bottom: .5em;
      }
      .tile-title {
        font-size: ${(p) => p.theme.fontSizeH5};
        font-weight: 700;
      }
    }
  }

  &.template-flashcard {
    font-family: "Courier", "Courier New";
    .tile-inner {
      padding: 10% 6% 0;
      align-items: flex-start;
      line-height: 1.4;
      @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
        line-height: 1.2;
      }
      .tile-kicker {
        margin-bottom: 0.7em;
        font-size: 0.65rem;
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          margin-bottom: 0.4em;
        }
      }
      .tile-title {
        font-weight: 700;
        font-size: 0.65rem;
        margin-bottom: 1.2em;
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          margin-bottom: 0.4em;
        }
      }
      .tile-teaser p {
        font-family: "Courier", "Courier New";
        font-size: 0.65rem;
        line-height: 1.4;
        margin: 0;
        @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
          line-height: 1.2;
        }
      }
    }
  }

  &.template-kicker {
    box-shadow: 10px 10px 0 black;
    width: 320px;
    height: 400px;
    background-image: none;
    background-color: ${(p) => p.bgColor};
    .tile-inner {
      padding: 1em;
      color: black;
      align-items: flex-start;
      .tile-title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        .tile-kicker,
        .tile-author,
        .tile-teaser p {
          font-size: ${(p) => p.theme.fontSizeSmall};
        }
        .tile-kicker {
          font-weight: 800;
          text-align: left;
          flex-grow: 1;
          order: 0;
        }
        .tile-author {
          text-transform: uppercase;
          margin: 1em 0;
          font-weight: 300;
          order: 2;
        }
        .tile-title {
          text-transform: uppercase;
          font-weight: 300;
          font-size: ${(p) => p.theme.fontSizeH4};
          order: 1;
          // word-break: break-all;
        }
        .tile-teaser {
          order: 3;
          flex-grow: 1;
          p {
          font-family: "Roboto Condensed";
          line-height: 1.2;
          }
        }
      }
    }
  }

  &:not(.fullscreen) .tile-img-wrapper,
  &:not(.template-fullscreen) .tile-img-wrapper {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    position: relative;
    z-index: 0;
  }
  &.template-default,
  &.template-fullscreen {
    .tile-img-wrapper::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
      transition: 0.3s ease-in-out;
    }
    .tile-img-wrapper::after {
      content: "";
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: inset 0 0 100px #000;
      background-image: radial-gradient(transparent 80%, rgba(0, 0, 0, 0.2));
    }
  }

  &.fullscreen,
  &.template-fullscreen {
    background-color: black;
    width: 100%;
    // height: 100vh;
    /* @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      max-height: 675px;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpointTablet}) {
      max-height: 675px;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpointDesktopBig}) {
      max-height: 920px;
    } */ 
    padding: 0;

    height: auto; // let image dictate height

    margin: 0 0 3rem !important;
    max-width: none !important;
    &:hover {
      transform: none;
    }
    .tile-img-wrapper::after {
      display: none;
    }
    .tile-inner {
      padding-bottom: 5% !important;
      .tile-title-wrapper {
        .tile-kicker {
          font-size: ${(p) => p.theme.fontSizeH5};
          // font-weight: 800;
          line-height: 3;
        }
        .tile-title {
          font-size: ${(p) => p.theme.fontSizeH0};
        }
        .tile-teaser p {
          max-width: 700px;
          font-family: "Roboto Condensed";
          font-size: ${(p) => p.theme.fontSizeH5};
        }
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      background-color: #1e1e22; // ${(p) => p.theme.bgDark};
      height: auto;
      // min-height: 100vh;
      img {
        height: auto;
        object-fit: contain;
      }
      .tile-img-wrapper::before,
      .tile-img-wrapper::after {
        display: none;
      }
      .tile-inner {
        position: relative;
        height: auto;
        padding-left: 5%;
        padding-right: 5%;
        .tile-title-wrapper {
          // margin-top: -1em;
          // background-color: ${(p) => p.theme.bgDark};
          padding: 1em;
          .tile-kicker {
            font-size: 1em;
          }
          .tile-title {
            font-size: ${(p) => p.theme.fontSizeH4};
          }
          .tile-teaser p {
            font-size: 1em;
          }
        }
      }
    }
  }
`

TileOuter.defaultProps = {
  theme: {
    ...defaultTheme,
  },
}
