import React, { useContext } from "react"
import { Linker } from "gatsby-source-dek-wp"
import { TileWrapper, TitleWrapper, TileBackside } from "./photo-tile.style"
import Banderole from "../../../../shared/src/components/slide/banderole"
import { MovingContext } from "../../../../shared/src/components/card-deck/card"

const PhotoTile = ({
  imageSrc,
  title,
  teaser,
  flip,
  href,
  category = {},
  flipped,
  unpublished,
  small
}) => {
  const moving = useContext(MovingContext)
  const onClick = (e) => {
    // console.log("CLICKED. moving?", moving)
    if (moving) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  return (
    <TileWrapper image={imageSrc} className={small ? "small" : ""}>
      {unpublished && <Banderole>unpublished</Banderole>}
      <TitleWrapper>
        <Linker to={href} onClick={onClick}>
          {title}
        </Linker>
      </TitleWrapper>
      <TileBackside></TileBackside>
    </TileWrapper>
  )
}

export default PhotoTile
