import styled from "styled-components"
import { animated } from "@react-spring/web"
import { breakpointMobile } from "../app/globals.module.scss"

export const CardDiv = styled(animated.div)`
  will-change: transform;
  pointer-events: auto;
  @media screen and (min-width: ${breakpointMobile}) {
    touch-action: none;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  transform-style: preserve-3d;
  /*.category-filter-active & {
    transform: none !important;
  }*/
`
