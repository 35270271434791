import { useState, useContext, useEffect } from "react"
import { AuthContext } from "./auth"
import { queryRes2Context } from "../query-res-2-content"
import { Content } from "./contents"

// auto fetch fresh content if authenticated

export function usePreviewGraphql(staticPageContext: Content, postId: number, graphqlApi: string) {
  const [pageContext, setPageContext] = useState(staticPageContext)
  useEffect(() => setPageContext(staticPageContext), [staticPageContext])
  const isAuthenticated = useContext(AuthContext)
  useEffect(() => {
    if (!isAuthenticated || !postId || !graphqlApi ) return
    async function update() {
      // console.log("GRAPHQL UPDATE", { graphqlApi, postId })
      // TODO: sync query with gatsby-node.js query !!!
      const result = await fetch(graphqlApi, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query NewQuery {
              post(id: ${postId}, idType: DATABASE_ID) {
                title
                databaseId
                excerpt
                date
                content
                categories {
                  nodes {
                    databaseId
                    name
                  }
                }
                tags {
                  nodes {
                    databaseId
                  }
                }
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                autoren {
                  nodes {
                    name
                    description
                    person {
                      personUrl
                      image {
                        mediaItemUrl
                      }
                    }
                  }
                }
                translators {
                  nodes {
                    name
                    description
                    person {
                      personUrl
                      image {
                        mediaItemUrl
                      }
                    }
                  }
                }
                sources {
                  nodes {
                    name
                    source {
                      url
                      logo {
                        mediaItemUrl
                      }
                      color1
                      color2
                    }
                  }
                }
                acfMeta {
                  author
                  authorDescription
                  authorImage {
                    sourceUrl
                  }
                  menuTitle
                  menuCategory
                  orderId
                  originalUrl
                  originalDate
                  shortened
                  photoEditor
                  slug
                  translator
                  pageType
                  hasDiscussion
                  darkBackground
                  dataVisualization
                  titleVideo {
                    mediaItemUrl
                  }
                  tileImage {
                    mediaDetails {
                      sizes {
                        name
                        sourceUrl
                      }
                    }
                    sourceUrl
                  }
                  titleVideoPoster {
                    mediaDetails {
                      sizes {
                        name
                        sourceUrl
                      }
                    }
                    sourceUrl
                  }
                  titleVideoSource
                  beginWithBody
                  published
                  themeColor
                  bodyClass
                } 
                socialImgOptions {
                  showSubtitle
                  subtitle
                  title
                }
                language {
                  slug
                }
                translations {
                  databaseId
                }
              }
            }
          `
        })
      }).then(r => r.json()).catch(console.error)
      const { data } = result
      if (!data?.post) return
      const postContext = queryRes2Context(data.post, "-", isAuthenticated)
      // console.log("graphql: SUCCESS", { postContext })
      setPageContext(postContext) 
    }
    update()
  }, [isAuthenticated, postId, graphqlApi])
  return isAuthenticated ? pageContext : staticPageContext
}

export function usePreview(staticContent: Content, postId: number, restApi: string) {
  const [contentState, setContent] = useState(staticContent)
  useEffect(() => setContent(staticContent), [staticContent])

  const isAuthenticated = useContext(AuthContext)
  useEffect(() => {
    if (!isAuthenticated || !postId) return
    async function update() {
      const apiContent = await fetchContentFromRestApi(postId, restApi)
      setContent(apiContent.content.rendered)
    }
    update()
  }, [postId, setContent, isAuthenticated, restApi])

  return isAuthenticated ? contentState : staticContent
}

async function fetchContentFromRestApi(postId: number, restApi: string) {
  if (!postId) {
    console.error("No postId provided")
    return
  }
  if (!restApi) {
    console.error("No restApi provided")
    return
  }
  const ts = (new Date()).getTime()
  const url = `${restApi}/${postId}/?_ts=${ts}`
  // console.log(url)
  return await fetch(url, { cache: "no-store" })
    .then((r) => r.json())
    .catch((error) => console.log(error))
}
