import { TermObj } from "./term-setter/term-obj"

export const TERM_COLORS = [
  "#006681",
  "#00d19a",
  "#00b68b",
  "#008f92",
  "#6fda71",
  "#94dd5c",
  "#003f5c",
  "#578ad7",
  "#129fc9",
  "#00bbc4",
  "#7c387e",
  "#b3205e",
  "#a566bf",
  "#d1397c",
  "#ffdb29",
  "#ffa600",
  "#ff6361",
  "#c93324",
]

// ["#003f5c", "#bc5090", "#ff6361", "#58508d", "#ffa600"]
// ["#003f5c", "#bc5090", "#ff6361", "#58508d", "#ffa600"]
// ["#bc5090", "#ffa600", "#ff6361", "#58508d"]

// ["#c93324", "#ffdb29", "#129fc9", "#6fda71"]

// ["#129fc9", "#00c3b9", "#6fda71", "#ffdb29"]
// ["#bc5090", "#ffa600", "#ff6361", "#58508d"] // "#003f5c",
// https://learnui.design/tools/data-color-picker.html

let colorCache = {}

export function getColor(usedColors: string[], excludeColors: string[] = [], term: string) {
  // console.log(colorCache)
  const j = Math.round(Math.random() * 1000)
  const possibleColors = TERM_COLORS.filter((c) => !excludeColors.includes(c))
  const unusedColors = possibleColors.filter((c) => !usedColors.includes(c))
  if (term && colorCache[term]) return colorCache[term]
  const newColor = !possibleColors.length
    ? TERM_COLORS[j % TERM_COLORS.length]
    : unusedColors.length
    ? unusedColors[j % unusedColors.length]
    : possibleColors[j % possibleColors.length]
  if (term) colorCache[term] = newColor
  return newColor
}

const isString = (v: any): v is string => typeof v === "string"

export function enrichTermBundleWithColors(termBundles: TermObj[][]): TermObj[][] {
  return termBundles.reduce((acc: TermObj[][], currTb, i) => {
    const usedColorsOuter = acc.reduce((colorAcc, tb: TermObj[]) => {
      const currColors = tb.map((trm) => trm.color).filter(isString)
      return [...(colorAcc || []), ...currColors] as string[]
    }, [] as string[])
    const tbWithColors = currTb.reduce((termAcc, t, i) => {
      const usedColorsInner = termAcc.map((trm) => trm.color).filter(isString)
      const termWithColor = {
        ...t,
        color: getColor(usedColorsOuter, usedColorsInner, t.term),
      }
      return [...termAcc, termWithColor]
    }, [] as TermObj[])
    return [...acc, tbWithColors]
  }, [] as TermObj[][])
}
