import { useMemo } from "react"
import { SPECIAL_CATEGORIES } from "gatsby-source-dek-wp"
import { Content } from "./contents"

export function useMultiContents(selected: Content | undefined, contents: Content[] = []) {
  const multiCategoryId = (selected?.categories || []).find(
    ({ id }) => !SPECIAL_CATEGORIES.includes(id)
  )?.id
  const filteredContents = useMemo(() => {
    if (!multiCategoryId) return []
    return contents.filter((c) =>
      c.categories.some(({ id }) => multiCategoryId === id)
    )
  }, [contents, multiCategoryId])
  return filteredContents
}
