import styled from "styled-components"

export const DndLabelWrapper = styled.div`
  &.drop-hover {
    border: 2px dashed rgb(120, 120, 120);
  }
  .dnd-label-inner {
    margin: 2px;
    text-transform: uppercase;
  }
  .label-total-count {
    display: none;
  }
`