import { useState } from "react"
import styled from "styled-components"

export function useZIndexes(children) {
  const [zIndexes, setZIndexes] = useState(
    children.map(() => Math.round(Math.random() * children.length))
  )
  const setTopIndex = (topIx) => {
    setZIndexes((ixs) => [...ixs.filter((i) => i !== topIx), topIx])
  }
  return [zIndexes, setTopIndex]
}

export const ZWrapper = styled.div`
  z-index: ${(p) => p.zIndex};
  position: absolute;
`
