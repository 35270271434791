import styled from "styled-components"

export const MethodsTextWrapper = styled.div`
  display: none;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  margin: -2em 0 2em !important;
  padding: 0 !important;
  width: 100%;
  max-width: none !important;
  .methods-text-inner {
    overflow: scroll;
    h3 {
      margin-top: 0;
    }
  }
  .methods-header {
    display: flex;
    justify-content: flex-end;
    // border: 1px dashed green;
    .close-button {
      padding: 0.5em;
    }
  }
  body.show-methods-text & {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 20;
    background-color: rgba(255, 255, 255, 1) !important;
    color: black !important;
    // backdrop-filter: blur(8px);
    .methods-text-inner {
      h3 {
        margin-top: 0 !important;
        font-size: ${(p) => p.theme.fontSizeH5};
      }
      h4 {
        font-size: ${(p) => p.theme.fontSizeSuperSmall};
        break-after: avoid-column;
      }
      p {
        margin-top: 0;
        font-size: ${(p) => p.theme.fontSizeSuperSmall};
      }
    }
    @media screen and (min-width: ${(p) => p.theme.breakpointMobile}) {
      top: calc(50% + 40px);
      left: 50%;
      width: calc(1000px);
      transform: translate3d(-50%, -50%, 0);
      max-height: 98vh;
      .methods-text-inner {
        padding: 0 2em 1em;
        div {
          column-count: 3;
          column-gap: 2em;
        }
      }
    }
    @media screen and (max-width: ${(p) => p.theme.breakpointMobile}) {
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 80vh;
      .methods-text-inner {
        padding: 0 10px 1em; // $side-padding-mobile
      }
    }
  }
`

export const MethodsBodyOverlay = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  max-width: none !important;
  margin: 0 !important;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 8;
  opacity: 0;
  transition: 0.3s ease-in-out;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
`
