import React, { useMemo, useState, useEffect, cloneElement } from "react"
import CategoryFilter from "./category-filter"
import { useBodyClass } from "../../modules/hooks"

export function useCategories(children) {
  const initialState = useMemo(() => {
    return [
      ...new Set(
        children
          .map((c) => c.props.category)
          .filter((c) => typeof c === "object")
          .sort((a, b) => a.id - b.id)
      ),
    ]
  }, [children])
  const [catState, setCatState] = useState(initialState)
  useEffect(() => setCatState(initialState), [initialState])
  const filterActive = catState.reduce((acc, c) => !c.selected || acc, false)
  useBodyClass(filterActive ? "category-filter-active" : "")
  const onCategoryClick = (key) => {
    setCatState((s) => {
      const slctd = s.filter((c) => c.selected)
      const isSingleSelectedClicked = slctd.length === 1 && slctd[0].key === key
      return s.map((c) => {
        const isClicked = c.key === key
        return {
          ...c,
          selected: isSingleSelectedClicked ? true : isClicked ? true : false,
        }
      })
    })
  }
  const comp = <CategoryFilter {...{ categories: catState, onCategoryClick }} />
  const filteredChildren = useMemo(() => {
    const isFiltered = (catKey) => {
      return !(catState.find((c) => c.key === catKey) || {}).selected
    }
    const tmp = children.map((c) => {
      if (!c.props.category) return c
      return cloneElement(c, {
        ...c.props,
        filtered: isFiltered(c.props.category.key),
      })
    })
    const sortedKeys = [...tmp]
      .sort((a, b) => a.props.filtered - b.props.filtered)
      .map((c) => c.props.id)
    return tmp.map((c) => {
      const pos = sortedKeys.indexOf(c.props.id)
      return cloneElement(c, {
        ...c.props,
        pos,
      })
    })
  }, [children, catState])
  return [comp, filteredChildren]
}
