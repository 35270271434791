import React, { useRef, useEffect, useState, useContext, useMemo, useCallback } from "react"
import innerText from "react-innertext"
import { ExtraColumnPortal } from "../../../gatsby-plugin-popup-preloader/components/extra-column/extra-column-portal"
import { v4 as uuidv4 } from "uuid"

import { Toc } from "./toc"
import {
  addTocItem,
  removeTocItem,
  setCurrChapter,
  setPrevChapter,
  TocContext,
} from "./toc-reducer"
import TocButton from "./toc-button"
import { SubheadingWrapper } from "./subheading.style"
import { useIntersectionObserver } from "../../hooks/intersection-observer"

export function useInToc(textComp, isTitle = false, expand) {
  const ref = useRef()
  const uid = useUid()
  const dispatch = useContext(TocContext)[1]
  const text = textComp ? innerText(textComp) : ""

  useEffect(() => {
    if (!uid || !text) return
    const tocItem = {
      uid: uid,
      refEl: ref,
      text: text,
      expand,
      isTitle,
    }
    dispatch(addTocItem(uid, tocItem))
    return () => dispatch(removeTocItem(uid))
  }, [dispatch, text, uid, expand, isTitle])


  const [, inView, direction] = useIntersectionObserver(
    { rootMargin: "0px 0px -50% 0px" },
    ref
  )
  useEffect(() => {
    if (inView && direction === "down") dispatch(setCurrChapter(uid))
    if (!inView && direction === "up") dispatch(setPrevChapter(uid))
  }, [inView, uid, dispatch, direction ])

  return [ref, uid]
}

export const Subheading = ({ children, type, id, classList = [] }) => {
  const [tocExpanded, setTocExpanded] = useState(false)


  const [hover, setHover] = useState(false)
  const expand = useCallback(() => setTocExpanded(true), [])
  const collapse = () => {
    setTocExpanded(false)
    setHover(false)
  }
  const onMouseOver = () => setHover(true)
  const onMouseOut = () => setHover(false)
  const onClick = () => setTocExpanded(!tocExpanded)

  const [ref] = useInToc(children, false, expand)

  const portalComp = (
    <ExtraColumnPortal
      type="toc"
      expanded={tocExpanded}
      collapse={collapse}
      refEl={ref}
      hover={hover}
      // extraClass="no-shift-up"
      color={null} // TODO
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onMouseOver}
      onBlur={onMouseOut}
      options={{
        noTopShift: true,
        showInline: false,
      }}
    >
      <Toc
        // marked={uid}
        listOnly={true}
        onClickHook={() => setTocExpanded(false)}
      />
    </ExtraColumnPortal>
  )
  return (
    <SubheadingWrapper
      className={`subheading ${hover ? " hover" : ""} ${type} ${classList.join(
        " "
      )}`}
      id={id}
    >
      <button
        onClick={onClick}
        onKeyDown={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onFocus={onMouseOver}
        onBlur={onMouseOut}
      >
        <TocButton expanded={tocExpanded} />
        <span ref={ref}>{children}</span>
      </button>
      {portalComp}
    </SubheadingWrapper>
  )
}

function useUid() {
  return useMemo(uuidv4, [])
}
