import React from "react"
import PlusMinusButton from "../link/plus-minus-button"
import XButton from "./x-button"
import chroma from "chroma-js"
import { alphaUltraLight, alphaMinimal } from "./extra-column.module.scss"
import "./extra-column-item.scss"
import { useBodyClass } from "./extra-column"

const ExtraColumnItem = (props) => {
  const {
    hover,
    color,
    onMouseOver,
    onMouseOut,
    collapse,
    type,
    children,
    extraClasses,
    options = {},
    minMax,
    collapsed,
  } = props
  const {
    noTopShift,
    showInline,
    noVerticalPadding,
    fromMethodsText,
    fromPullquote,
    fromSplitview
  } = options
  useBodyClass(`${!collapsed && fromPullquote ? "has-pullquote-popup" : ""}`)
  const style = color
    ? {
        borderColor: color,
        backgroundColor: `rgba(${chroma(color)
          .rgb()
          .join(", ")}, ${alphaMinimal})`,
        "--custom-hover-color": `rgba(${chroma(color)
          .rgb()
          .join(", ")}, ${alphaUltraLight})`,
      }
    : {}
  return (
    // eslint-disable-next-line
    <div
      className={`extra-column-item 
        ${type || ""}
        ${collapsed ? " collapsed" : ""}
        ${hover ? " hover" : ""}
        ${noTopShift || fromSplitview ? " no-top-shift" : ""}
        ${showInline ? " show-inline" : ""}
        ${noVerticalPadding ? " no-vertical-padding" : ""}
        ${fromMethodsText ? " from-methods-text" : ""}
        ${extraClasses || ""}
      `}
      style={style}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOut}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
      {typeof collapse === "function" && (
        <XButton className="x-button" onClick={collapse} />
      )}
      {typeof minMax === "function" && (
        <PlusMinusButton
          expanded={!collapsed}
          extraClasses="top-right"
          onClick={minMax}
        />
      )}
    </div>
  )
}

export default ExtraColumnItem
