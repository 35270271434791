import React, { createContext, useReducer } from "react"
export const AnnotationsContext = createContext()

export function withAnnotations(Comp) {
  return (props) => {
    const annRed = useReducer(annotationsReducer, [])
    return (
      <AnnotationsContext.Provider value={annRed}>
        <Comp {...props} />
      </AnnotationsContext.Provider>
    )
  }
}

function annotationsReducer(state, action) {
  const { type, id, content } = action
  switch (type) {
    case "add":
      return [...state, { id, content }]
    case "remove":
      return [...state.filter((a) => a.id !== id)]
    default:
      throw new Error()
  }
}

export function add(id, content) {
  return { type: "add", id, content }
}

export function remove(id) {
  return { type: "remove", id }
}
