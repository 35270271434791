import styled from "styled-components"

export const FeedbackWrapper = styled.div`
  padding: 1em;
  background: ${(p) => (p.img ? `url(${p.img})` : "rgb(230, 230, 230)")};
  background-size: cover;
  background-position: 50% 50%;
  color: ${(p) => (p.img ? "white" : "inherit")};
  text-shadow: ${(p) => (p.img ? "1px 1px black" : "none")};
  h3 {
    text-align: center;
    margin: 5em 0 !important;
  }
`
