import React, { Suspense } from "react"
const Map = React.lazy(() => import("./map"))

function mapTransformer(node, i, { style, children }) {
  if (node.name === "map") {
    return (
      <Suspense fallback={<div>Loading ...</div>}>
        <Map>{children}</Map>
      </Suspense>
    )
  }
}

export default mapTransformer
