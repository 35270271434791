import React, { useContext } from "react"
import Helmet from "react-helmet"
import { LangContext } from "../../hooks/langs"
import { NO_WEBP } from "../image/webp-check"

export const Seo = ({ selected, metaData }) => {
  const { siteMetaInfo, siteMetadata, socialImgDefault } = metaData
  const lang = useContext(LangContext)
  const baseUrl = siteMetadata.siteUrl
  const siteTitle = siteMetaInfo[lang.id].title
  const siteDescription = siteMetaInfo[lang.id].description
  const socialTitle = selected.title
    ? `${selected.title} | ${siteTitle}`
    : siteTitle
  const pageTitle = selected.title
    ? `${socialTitle} | дekoder`
    : `${siteTitle} | дekoder`
  const _description = selected.description
    ? selected.description
    : siteDescription
  const description = stripTags(_description)

  const imgProps = selected.image || { src: socialImgDefault[lang.id] }
  const { src, width, height } = imgProps
  const _imgUrl = src.match(/^http/)
    ? src
    : `${baseUrl}${src}`
  const imgUrl = `${_imgUrl}?${NO_WEBP}`

  const url = selected.slug
    ? `${baseUrl}/${selected.slug}`
    : `${baseUrl}/${lang.id}`
  const type = "article"
  return (
    <Helmet>
      <html lang={lang.id} />
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="abstract" content={description} />
      <link rel="image_src" href={imgUrl} />
      <link rel="canonical" href={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={socialTitle} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
      {!!width && <meta property="og:image:width" content={width} />}
      {!!height && <meta property="og:image:height" content={height} />}
      <meta name="twitter:title" content={socialTitle} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="white" />
    </Helmet>
  )
}

function stripTags(str = "") {
  return str.replace(/<\/?[^>]+(>|$)/g, "")
}
