import styled from "styled-components"
import { breakpointMobile } from "../app/globals.module.scss"

export const RemoteWrapper = styled.div`
  position: fixed; /* absolute */
  right: 60px;
  bottom: 0;
  background: rgba(60, 60, 60, 1);
  width: 140px;
  height: 230px;
  border-radius: 12px;
  box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  z-index: 25;
  transform: ${(p) => (p.hide ? "translateY(170px)" : "translateY(-10px)")};
  @media screen and (max-width: ${breakpointMobile}) {
    right: 30px;
    height: 230px;
    transform: ${(p) => (p.hide ? "translateY(170px)" : "translateY(0px)")};
  }
  body.on-article-body & {
    box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.4);
    /* transform: translateY(-10px) !important; */
  }
  body.menu-open & {
    display: none;
  }
  body.with-map:not(.on-slide-projector) & {
    display: none;
  }
  @media print {
    display: none;
  }
`

export const InfraRed = styled.div`
  position: absolute;
  top: -10px;
  width: calc(100% - 30px);
  left: 15px;
  height: 30px;
  border-radius: 6px;
  background: rgb(30, 10, 10);
`

export const SlideBtn = styled.button`
  position: absolute;
  color: white !important;
  top: 50px;
  left: ${(p) => (p.left ? "20px" : "auto")};
  right: ${(p) => (p.right ? "20px" : "auto")};
  width: 45px;
  height: 30px;
  background: ${(p) => (p.left ? "#af504c" : "#4CAF50")};
  border-radius: 6px;
  transition: 0.1s ease-in-out;
  box-shadow: 1px 2px #222;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    transform: translateY(2px);
  }
`

export const SelectBtn = styled.button`
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(50, 50, 50);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  &::after {
    display: block;
    content: "";
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgb(60, 60, 60);
  }
  transition: 0.1s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    transform: translateX(-50%) translateY(2px);
  }
`

export const BrandLogo = styled.div`
  position: absolute;
  width: 70px;
  opacity: 0.3;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
  }
`
