const chartPropsShared = {
  xScale: {
    type: "linear",
    min: 2000,
    max: 2020,
  },
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  curve: "monotoneX",
  colors: { datum: "color" },
  lineWidth: 4,
  enablePoints: false,
}

module.exports = chartPropsShared
