import styled from "styled-components"

export const TermLabelWrapper = styled.span`
  margin: 0 0.25em;
  text-transform: uppercase;
  font-weight: 400;
  text-align: right;

  color: white;

  .term-label-label {
    display: inline;
    font-size: ${(p) => p.theme.fontSizeSmall};
    font-family: "Roboto Condensed";
    padding: 3px 0;
    text-align: right;
    box-decoration-break: clone;
  }
  &.clickable {
    .term-label-label {
      cursor: pointer;
    }
  }

  .term-setter-wrapper:not(.bookmarks) & {
    background-color: transparent !important;
    line-height: 1.3em;
  }

  .inline & {
    margin: 0 0.3em !important;
  }

  .trigger &,
  .bookmarks & {
    // opacity: 0.8;
    // filter: grayscale(1);
    margin: 0.2em 0.8em 0 0;
    &:not(.selected) {
      .term-label-label {
        background-color: black !important; // better contrast
        box-shadow: 5px 0 0 black, -5px 0 0 black !important;
        .dark-mode & {
          background-color: #333 !important; // better contrast
          box-shadow: 5px 0 0 #333, -5px 0 0 #333 !important;
        }
      }
    }
  }

  .bookmarks & {
    display: flex;
    flex-wrap: nowrap;
    // margin: 0.2em 0.4em 0.2em 0;
    color: white;
    /* &.selected {
     // font-weight: 700;
      background-color: rgba(210, 210, 210, 1) !important;
      color: black;
    } */
    .term-label-label {
      padding: 5px 6px; // 3 6
      background-color: transparent !important;
      display: flex;
      // box-shadow: none !important;
    }
    .term-label-remove {
      padding: 0 4px;
      display: flex;
      align-self: center;
      align-items: center;
      height: 100%;
      // border: 1px dashed red;
      &:hover {
        background-color: rgba(
          255,
          255,
          255,
          0.3
        ) !important; // $title-color-solid;
      }
    }
    &:hover {
      // opacity: 0.7;
      // background-color: rgba(210, 210, 210, 1) !important;
      // color: black;
    }
    &:not(.selected) {
      background-color: black !important; // better contrast
      .term-label-label {
        box-shadow: 5px 0 0 black, -5px 0 0 black !important;
      }
      .term-label-remove {
        background-color: black !important; // better contrast
        color: white !important;
        &:hover {
          background-color: rgba(
            255,
            255,
            255,
            0.3
          ) !important; // $title-color-solid;
        }
      }
    }
  }

  // transition: .25s ease;
  .trigger &,
  .inline &,
  .bookmarks & {
    &.selected {
      opacity: 1;
      filter: grayscale(0);
    }
  }
`
