import React from "react"
import { components } from "react-select"
import { CustomInputWrapper } from "./custom-input.style"

const CustomInput = (props) => {
  return (
    <CustomInputWrapper className="custom-input">
      <components.Input {...props} />
    </CustomInputWrapper>
  )
}

export default CustomInput
