import React, { useState, useEffect } from "react"
import debounce from "debounce"
import styled from "styled-components"
import { dossierYellow } from "../app/globals.module.scss"

const ProgressBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 0%;
  background-color: ${dossierYellow}; /* fallback */
  height: 5px;
  z-index: 15;
  transform: translate3d(0, 0, 0);
`

function ReadingProgressBar({ refEl, color }) {
  const percent = useScrollPercent(refEl)
  return (
    <ProgressBar style={{ width: `${percent}%`, backgroundColor: color }} />
  )
}

export default ReadingProgressBar

function useScrollPercent(refEl) {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    if (!refEl) return
    const debouncedOnScroll = debounce(
      () => {
        const scrollPos = document.scrollingElement.scrollTop - refEl.offsetTop
        const max = refEl.scrollHeight - window.innerHeight
        const newPercent = Math.round((scrollPos / max) * 1000) / 10
        setPercent(Math.max(Math.min(newPercent, 100), 0))
      },
      5,
      true
    )
    document.addEventListener("scroll", debouncedOnScroll) // scrollWrapper
    return () => document.removeEventListener("scroll", debouncedOnScroll)
  }, [refEl])
  return percent
}
