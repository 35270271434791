import styled from "styled-components"

export const BookmarkButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  &.lang-de span {
    @media screen and (max-width: ${p => p.theme.breakpointMobile}) {
      display: none;
    }
  }

  .bookmark-button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .term-frequency-tool-upper & {
    .bookmark-button-inner {
      width: 2em;
      height: 2em;
      svg {
        color: inherit;
        transition: 0.3s ease;
      }
      &:hover {
        svg {
          color: ${p => p.theme.themeColor}; 
        }
      }
    }
  }

  .term-bookmarks & {
    margin-top: .2em;
    margin-right: .5em;
    padding: 2px 0;

    &:hover {
      color: ${p => p.theme.titleColorSolid};
    }
  }
`