import { useReducer, useEffect, useContext } from "react"
import { getColor } from "./term-colors"
import { LangContext } from "gatsby-source-dek-wp"

/* const DEFAULT_TERMS_EN = [
  { term: "modernisation", color: TERM_COLORS[0] },
  { term: "terrorism", color: TERM_COLORS[1] },
]
const DEFAULT_TERMS_RU = [
  { term: "модернизация", color: TERM_COLORS[0] },
  { term: "терроризм", color: TERM_COLORS[1] },
] */

const ADD_TERM = "addTerm"
const SET_TERMS_ARR = "setTermsArr"
const REMOVE_TERM = "removeTerm"
const MERGE_TERMS = "mergeTerms"
const REMOVE_ALL_TERMS = "removeAllTerms"
const SET_TERM_LANG = "setTermLang"
const SET_MARKERS = "setMarkers"
const SET_RANGE = "setRange"

export const addTerm = (term, color, translation) => ({
  type: ADD_TERM,
  term,
  color,
  translation,
})
export const setTermsArr = (termsArr, range) => ({
  type: SET_TERMS_ARR,
  termsArr,
  range
})
export const removeTerm = (term) => ({ type: REMOVE_TERM, term })
export const mergeTerms = (targetTerm, sourceTerm) => ({
  type: MERGE_TERMS,
  targetTerm,
  sourceTerm,
})
export const removeAllTerms = () => ({ type: REMOVE_ALL_TERMS })
export const setMarkers = (markers) => ({ type: SET_MARKERS, markers })
export const setRange = (range) => ({ type: SET_RANGE, range })

function defaultOptions(langId) {
  return { langId }
  /* return langId === "en"
    ? { langId, terms: DEFAULT_TERMS_EN }
    : { langId, terms: DEFAULT_TERMS_RU } */
}

function termReducer(state, action) {
  // console.log("REDUCER", action, state)
  switch (action.type) {
    case ADD_TERM:
      if (state.terms.find((t) => t.term === action.term)) return state
      const usedColors = state.terms.map((t) => t.color)
      const newColor = getColor(usedColors, usedColors, action.term) // state.terms.length
      // console.log(action.term, action.color, newColor)
      return {
        ...state,
        terms: [
          ...state.terms,
          {
            term: action.term,
            color: action.color || newColor,
            translation: action.translation,
          },
        ],
      }
    case SET_TERMS_ARR:
      return {
        ...state,
        terms: (action.termsArr || []).map((t) => ({
          ...t,
          color: t.color || getColor([], [], t.term),
        })),
        range: action.range || []
      }
    case REMOVE_TERM:
      return {
        ...state,
        terms: state.terms.filter((t) => t.term !== action.term),
      }
    case MERGE_TERMS:
      return {
        ...state,
        terms: state.terms.reduce((acc, t) => {
          if (t.term === action.sourceTerm) return acc
          // sourceTerm will be gone
          else if (t.term === action.targetTerm) {
            const mergedTerms = [
              ...new Set([
                ...t.term.split("+"),
                ...action.sourceTerm.split("+"),
              ]),
            ]
            return [...acc, { ...t, term: mergedTerms.join("+") }]
          } else return [...acc, t]
        }, []),
      }
    case REMOVE_ALL_TERMS:
      return { ...state, terms: [] }
    case SET_TERM_LANG:
      return { ...state, ...defaultOptions(action.langId) }
    case SET_MARKERS:
      return {
        ...state,
        markers: action.markers || [],
      }
    case SET_RANGE:
      return {
        ...state,
        range: action.range || [],
      }
    default:
      throw new Error()
  }
}

export function useTermReducer(langFromArg) {
  const langFromContext = useContext(LangContext)
  const lang = langFromContext || langFromArg
  const initState = {
    ...defaultOptions(lang.id),
    terms: [],
    markers: [],
    range: undefined, // [],
  }
  const [termState, termDispatch] = useReducer(termReducer, initState)
  useEffect(() => {
    termDispatch({ type: SET_TERM_LANG, langId: lang.id })
  }, [lang.id])
  useEffect(() => {
    // set by hash
    if (!window.location.hash) return
    const hashQuery = window.location.hash
      .split("#")[1]
      .split("&")
      .find((h) => h.match(/^q=/))
    if (!hashQuery) return
    const terms = decodeURIComponent(hashQuery.replace("q=", "")).split(",")
    if (terms.length) termDispatch(removeAllTerms())
    terms.forEach((t) => {
      termDispatch(addTerm(t))
    })
  }, [])
  return [termState, termDispatch]
}
