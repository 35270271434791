import { useEffect, useRef } from "react"
import { useTheme } from "styled-components"

export function useIsMobile(): boolean {
  const theme = useTheme()
  const breakpointMobile = parseInt(theme.breakpointMobile.replace("px", ""))
  if (typeof window === "undefined") return true
  return window.innerWidth <= breakpointMobile
}

export function usePrevious(val: any): any {
  const ref = useRef()
  useEffect(() => {
    ref.current = val
  }, [val])
  return ref.current
}