import styled from "styled-components"
import {
  slideProjectorBorderRadius,
  slideProjectorBorderRadiusMobile,
  breakpointMobile,
} from "../app/globals.module.scss"

export const Wrapper = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${(p) =>
      p.mini
        ? `radial-gradient(rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0,  0.5))`
        : `radial-gradient(rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0,  0.3))`};
    box-shadow: inset 0 0 ${(p) => (p.mini ? 30 : 150)}px rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
`

export const SepiaFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
  background: linear-gradient(
      to bottom,
      rgba(255, 145, 0, 0.2) 0%,
      rgba(255, 230, 48, 0.2) 60%
    ),
    linear-gradient(20deg, rgba(255, 0, 0, 0.6) 0%, rgba(255, 0, 0, 0) 35%);
  ${(p) =>
    p.fuzzy &&
    `
    opacity: 1;
    border-radius: ${slideProjectorBorderRadius};
    border: 2px solid black;
    box-shadow: inset 0 0 15px 15px rgba(0, 0, 0, 1);
    @media screen and (max-width: ${breakpointMobile}) {
      border: 1px solid black;
      box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 1);
      border-radius: ${slideProjectorBorderRadiusMobile}
    }
  `}
`

export const Scratches = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  user-select: none;
`

export const SlideImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  opacity: ${(p) => (p.projected ? 0.7 : 0.6)};
  filter: sepia(20%) contrast(130%); /* brightness(10%); */
  ${(p) =>
    p.projected &&
    `
    border-radius: ${slideProjectorBorderRadius};
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    @media screen and (max-width: ${breakpointMobile}) {
      border-radius: ${slideProjectorBorderRadiusMobile}
    }
  `}
`
